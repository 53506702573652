import React from 'react';
import banner from '../../../png/Careers-page3-1200x501.png';

const CareersBanner = () => {
    return <div className="articleBanner small aos aos-init aos-animate" data-aos="fade-up">
        <img className="bg lazy" alt="career" src={banner}
             style={{display: 'block', visibility: 'visible', opacity: 1}}/>
    </div>;
};

export default CareersBanner;

