import React, {useContext, useEffect, useState} from 'react';
import ContactSVG from "../../icon/text_contact";
import LanguageContext from "../../../context/language-context";
import ValidLabelInput from "../../valid-label-input/valid-label-input";
import {Link} from "react-router-dom";
import {HttpServiceContext} from "../../../context";

const ContactForm = () => {
    const [showDropMenu, setShowDropMenu] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [typedEmail, setTypedEmail] = useState('');
    const [typedName, setTypedName] = useState('');
    const [typedMessage, setTypedMessage] = useState('');
    const [hasErrors, setHasErrors] = useState({});
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [agreeError, setAgreeError] = useState(false);
    const [successfullySent, setSuccessfullySent] = useState(false);

    const httpService = useContext(HttpServiceContext);
    const {contactPage, contactForm, errorMessages} = useContext(LanguageContext);
    const {email, yourName, enterMessage, terms1, terms2, termsLink, sendMessage} = contactForm;
    const {text1, text2, text3, text4, defaultSubjects} = contactPage;

    useEffect(() => {
        setSubjects(defaultSubjects);
    }, [defaultSubjects]);

    useEffect(() => {
        setHasErrors({});
    }, [contactForm]);

    const createLi = (item) => {
        const clazz = 'active-result' + (item.selected ? ' result-selected' : '');

        return <li key={item.title}
                   onClick={() => {
                       setShowDropMenu(false);
                       setSubjects((subjects) => {
                           const newSubjects = [...subjects];

                           for (let inner of newSubjects) {
                               inner['selected'] = inner['title'] === item.title;
                           }

                           return newSubjects;
                       });
                   }
                   }
                   className={clazz}>
            {item.title}
        </li>
    };

    return <div className="container">
        <div className="titleRow main bigTitle smallMargin alignStart">
            <div className="text moreWidth aos aos-init aos-animate" data-aos="fade-up" data-delay="0"
                 style={{transitionDelay: '0ms'}}>
                <h2>{text1}<b>{text2}</b>{text3}</h2>
                <p>{text4}</p>
            </div>
            <div className="rightColumn aos aos-init aos-animate" data-aos="fade-up" data-delay="100"
                 style={{transitionDelay: '100ms'}}>
                <div className="titleSVG noMargin">
                    <ContactSVG/>
                </div>
                <div className="contactForm">
                    <div role="form">
                        <div className="screen-reader-response"/>
                        <div>
                            <span>
                                <label className="with_line">
                                    <span>
                                        <div
                                            className="chosen-container chosen-container-single chosen-container-single-nosearch chosen-container-active chosen-with-drop"
                                            title="" style={{width: '152px'}}>
                                            <span className="chosen-single"
                                                  onClick={() => setShowDropMenu((showDropMenu) => {
                                                      return !showDropMenu;
                                                  })}>
                                                <span>
                                                    {
                                                        subjects
                                                            .filter(item => item.selected)
                                                            .map(item => {
                                                                return item.title
                                                            })
                                                    }
                                                </span>
                                                <div>
                                                    <b/>
                                                </div>
                                            </span>
                                            {showDropMenu &&
                                            <div className="chosen-drop">
                                                <div className="chosen-search">
                                                    <input className="chosen-search-input" type="text"
                                                           autoComplete="off" readOnly=""/>
                                                </div>
                                                <ul className="chosen-results">
                                                    {subjects.map(item => createLi(item))}
                                                </ul>
                                            </div>
                                            }

                                        </div>
                                    </span>
                                </label>
                                 <ValidLabelInput
                                     labelText={email}
                                     inputType={"email"}
                                     inputSize={"40"}
                                     value={typedEmail}
                                     setValue={setTypedEmail}
                                     addErrors={setHasErrors}
                                 />
                                 <ValidLabelInput
                                     labelText={yourName}
                                     inputType={"text"}
                                     inputSize={"40"}
                                     value={typedName}
                                     setValue={setTypedName}
                                     addErrors={setHasErrors}
                                 />
                                <label className="with_line textarea">
                                    <span className="hover_text">
                                        {enterMessage}
                                    </span>
                                    <span>
                                    <textarea onChange={(e) => setTypedMessage(e.target.value)}
                                              cols="40"
                                              rows="10"
                                              aria-invalid="false"/>
                                    </span>
                                </label>
                                <label>
                                    <span>
                                        <span>
                                            <span>
                                        <label>
                                            <input onChange={() => setAgreeTerms((agreeTerms) => {
                                                return !agreeTerms
                                            })}
                                                type="checkbox" value="1"
                                                   aria-invalid="false"/>
                                                   <span style={{marginLeft: "5px"}}>
                                                       {terms1}
                                                       <Link to={termsLink}>
                                                           {terms2}
                                                       </Link>
                                                   </span>

                                            {
                                                agreeError
                                                    ? <div className='terms-alert'>{errorMessages.terms}</div>
                                                    : null
                                            }
                                            {
                                                successfullySent
                                                    ? <div className='terms-alert'>{errorMessages.successfully}</div>
                                                    : null
                                            }
                                        </label>
                                        </span>
                                        </span>
                                    </span>
                                </label>
                            </span>
                            <div className="bottomRow">
                                <button onClick={() => {
                                    console.log(hasErrors);
                                    if (Object.values(hasErrors).includes(true)) {
                                        return;
                                    }

                                    if (!agreeTerms) {
                                        setAgreeError(true);
                                        return;
                                    }

                                    const formData = new FormData();
                                    formData.append('email', typedEmail);
                                    formData.append('name', typedName);
                                    formData.append('message', typedMessage);
                                    formData.append('subject', subjects.filter(item => item.selected)
                                        .map(item => {
                                            return item.title
                                        }));

                                    httpService.postRequest('/mail', formData)
                                        .then(() => {
                                            setSubjects(defaultSubjects);
                                            setTypedEmail("");
                                            setTypedName("");
                                            setSuccessfullySent(true);
                                            setHasErrors({
                                                [email.replace(/\s/g, '')]: true,
                                                [yourName.replace(/\s/g, '')]: true
                                            });
                                        })
                                        .catch(() => {
                                            setSuccessfullySent(false);
                                        });
                                    setAgreeError(false);
                                }}
                                    type="submit"
                                        className="line_hover type2 arrow">
                                    {sendMessage}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default ContactForm;

