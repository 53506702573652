import React, {useContext} from "react";
import LanguageContext from "../../context/language-context";
import AboutInfo from "../about/about-info/about-info";
import AboutBanner from "../about/about-banner/about-banner";
import esgDrawBackground from "../../jpg/esgDrawBackground.jpg";
import CoreValueBlock from "../about/core-value-block/core-value-block";
import EsgSvg from "./EsgSvg";
import CareersBlock from "../home/careers-block/careers-block";
import ContactBlock from "../home/contact-block/contact-block";
import withCookie from "../../hoc/withCookie";

const Esg = () => {
    const {language, esgPage} = useContext(LanguageContext);
    const {
        header: {title: {startText, colorfulText, endText}, subtitle},
        factors: {title: factorsTitle, subtitle: factorsSubtitle, factorsList}
    } = esgPage;

    return <div className="wrapper aboutPage">
        <AboutInfo
            language={language}
            text1={startText}
            text2={colorfulText}
            text3={endText}
            subtitle={subtitle}
            aboutSVG={<EsgSvg/>}
        />
        <AboutBanner aboutBanner={esgDrawBackground}/>
        <CoreValueBlock text1={factorsTitle} text2={factorsSubtitle} textList={factorsList}/>
        <CareersBlock/>
        <ContactBlock/>
    </div>
};

export default withCookie(Esg);