import React from 'react';
import ReactTypingEffect from 'react-typing-effect';

const TypingText = ({language}) => {
    return <ReactTypingEffect
        text={language === 'EN' ? ['Computational', 'Smart', 'Exceptional'] : ['Computationnelle', 'Exceptionnelle', 'Innovative']}
        speed={200}
        typingDelay={1000}
        eraseSpeed={100}
        eraseDelay={4000}
        className={'color-text'}
        cursorClassName={'white-text'}
    />;
};

export default React.memo(TypingText);