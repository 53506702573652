import React, {useCallback, useEffect, useRef, useState} from 'react';
import * as animationData from "../../json/contact-animation";
import Lottie from "react-lottie";

const AnimateFigure = ({height, divider = 2.2}) => {
    const [showBlock, setSowBlock] = useState(false);
    const ref = useRef(null);

    const handleClickOutside = useCallback(() => {
        if (ref.current && !showBlock && (window.pageYOffset / divider) >= ref.current.getBoundingClientRect().top) {
            setSowBlock(true);
        }

        }, [showBlock, divider]);

    useEffect(() => {
        document.addEventListener("scroll", handleClickOutside);
        return () => {
            document.removeEventListener("scroll", handleClickOutside);
        }
    }, [handleClickOutside]);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
    };

    return <div ref={ref}>
        {showBlock
        ? <Lottie options={defaultOptions}
                  height={height}
                  isStopped={false}
                  isPaused={false}/>
        : null}

    </div>
};

export default AnimateFigure;

