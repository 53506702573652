import React from 'react';
import ProjectSlider from "../project-slider/project-slider";
import CareersBlock from "../home/careers-block/careers-block";
import ContactBlock from "../home/contact-block/contact-block";
import FoundationInfo from "./foundation-info/foundation-info";
import StartUps from "./start-ups/start-ups";
import withCookie from "../../hoc/withCookie";

const Foundation = () => {
    return <div className='wrapper projectsPage directions'>
        <FoundationInfo/>
        <StartUps/>
        <div className="projectsBlock">
            <div className="container">
                <ProjectSlider
                    slider={'foundationSliderText'}
                />
            </div>
        </div>
        <CareersBlock/>
        <ContactBlock/>
    </div>
};

export default withCookie(Foundation);

