import React from 'react';
import './about-info.css';

const AboutInfo = ({language, text1, text2, text3, subtitle, aboutSVG, aboutSvgFr}) => {
    return <div className="container">
        <div className="titleRow main bigTitle smallMargin alignStart">
            <div className="text moreWidth aos aos-init" data-aos="fade-up" data-delay="0"
                 style={{transitionDelay: '0ms'}}>
                <h2>{text1}<b>{text2}</b>{text3}</h2>
                {subtitle.map((item, index) => <p key={index}>{item}</p>)}
            </div>
            <div className="rightColumn alignTextRight aos aos-init" data-aos="fade-up" data-delay="100"
                 style={{transitionDelay: '100ms'}}>
                <div className="titleSVG noMargin">
                    {language === 'EN' ?
                        aboutSVG :
                        aboutSvgFr || aboutSVG
                    }
                </div>
            </div>
        </div>
    </div>
};

export default AboutInfo;

