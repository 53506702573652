import React from 'react';

const RotateFRText = () => {
    return <svg className="text" width="119" height="119" viewBox="0 0 119 107" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <g>
            <path fill="white"
                  d="M24.3,22.3L22.8,24l-4.4-4.1l1.5-1.6c0.6-0.7,1.3-1.1,2.1-1.1s1.5,0.2,2.2,0.8c0.7,0.6,1,1.3,1,2.1   C25.3,20.9,25,21.7,24.3,22.3z M23.6,21.7c0.4-0.5,0.6-1,0.6-1.5c0-0.5-0.3-1-0.7-1.4c-0.4-0.4-0.9-0.6-1.5-0.6s-1,0.2-1.5,0.7   l-0.8,0.9l3.1,2.8L23.6,21.7z"/>
            <path fill="white"
                  d="M32.3,15.2L29,17.7L25.4,13l3.3-2.5l0.6,0.7l-2.4,1.9l0.9,1.2l2.4-1.8l0.6,0.7L28.3,15l1,1.3l2.4-1.9L32.3,15.2z M27.2,9.3   l-0.4,1.9l-0.5,0.4l0.2-1.8L27.2,9.3z"/>
            <path fill="white"
                  d="M36.2,12.8l-0.9,0.5l-2.8-5.3l3.6-1.9l0.4,0.8l-2.7,1.4l0.7,1.4l2.6-1.4l0.4,0.8L35,10.6L36.2,12.8z"/>
            <path fill="white" d="M43.1,9.7l-1,0.4L40,4.5l1-0.4L43.1,9.7z"/>
            <path fill="white" d="M50.2,7.7l-3.6,0.8l-1.4-5.8l1-0.2l1.2,4.9L50,6.8L50.2,7.7z"/>
            <path fill="white"
                  d="M57.7,6.9l-4.1,0.3l-0.4-6l4.1-0.3l0.1,0.9l-3.1,0.2l0.1,1.6l3-0.2l0.1,0.9l-3,0.2l0.1,1.7L57.7,6L57.7,6.9z"/>
            <path fill="white"
                  d="M65.9,7.4l-1.2-0.1l-1.1-2.4l-1-0.1L62.3,7l-1-0.1l0.6-6l2.6,0.2c0.6,0.1,1,0.3,1.4,0.7c0.3,0.4,0.5,0.8,0.4,1.4   c0,0.5-0.2,0.9-0.5,1.2S65,4.9,64.6,4.9L65.9,7.4z M64,4c0.3,0,0.6,0,0.8-0.2c0.2-0.2,0.3-0.4,0.4-0.7c0-0.3,0-0.5-0.2-0.7   c-0.2-0.2-0.4-0.3-0.7-0.3L62.8,2l-0.2,1.9L64,4z"/>
            <path fill="white" d="M76.7,10.4l-1.2-0.5l0-6.4l1.1,0.4l-0.1,5.2L80,5.3l1.1,0.4L76.7,10.4z"/>
            <path fill="white"
                  d="M85.2,14.9l-3.5-2.1l3.1-5.1l3.5,2.1l-0.5,0.8L85.2,9l-0.8,1.3l2.6,1.6l-0.5,0.8l-2.6-1.6L83,12.5l2.6,1.6L85.2,14.9z"/>
            <path fill="white"
                  d="M91.5,20l-0.9-0.8l0.5-2.6L90.3,16l-1.5,1.7L88,17l3.9-4.5l2,1.7c0.4,0.4,0.7,0.8,0.7,1.3c0,0.5-0.1,1-0.5,1.4   c-0.3,0.4-0.7,0.6-1.1,0.6c-0.4,0-0.8,0-1.1-0.2L91.5,20z M92,16.2c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3   c0.2-0.2,0.3-0.5,0.2-0.7s-0.2-0.5-0.4-0.7l-1.1-0.9l-1.3,1.4L92,16.2z"/>
            <path fill="white"
                  d="M95.1,24.2c-0.7-0.8-0.9-1.6-0.8-2.5l1-0.1c-0.1,0.7,0.1,1.4,0.6,2c0.3,0.3,0.5,0.5,0.8,0.5s0.5,0,0.6-0.1   c0.2-0.1,0.2-0.4,0.2-0.6c0-0.3-0.1-0.6-0.3-0.9c-0.2-0.3-0.3-0.6-0.4-1s-0.2-0.7-0.1-1s0.2-0.7,0.5-0.9c0.4-0.3,0.8-0.4,1.4-0.3   c0.5,0.1,1,0.4,1.4,0.9c0.6,0.7,0.8,1.5,0.8,2.3l-1,0c0.1-0.6-0.1-1.2-0.6-1.8c-0.2-0.2-0.4-0.4-0.6-0.4c-0.2-0.1-0.4,0-0.6,0.1   c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0,0.3,0.1,0.5s0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.2,0.2,0.5,0.2,0.7   c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.3,0.5-0.5,0.7c-0.4,0.3-0.9,0.5-1.4,0.4C96.1,25.1,95.6,24.8,95.1,24.2z"/>
            <path fill="white" d="M102.4,36.3l-1.5-3.4l5.5-2.5l0.4,1l-4.6,2.1l1.1,2.4L102.4,36.3z"/>
            <path fill="white"
                  d="M104.7,43.5l-1.1-4l5.8-1.6l1.1,4l-0.9,0.2l-0.8-3l-1.5,0.4l0.8,2.9l-0.9,0.2l-0.8-2.9l-1.6,0.4l0.8,3L104.7,43.5z"/>
            <path fill="white"
                  d="M105.7,54.8l0-3l6,0.1l0,3c0,0.5-0.2,0.9-0.5,1.2s-0.7,0.4-1.1,0.4c-0.4,0-0.7-0.1-0.9-0.3c-0.2-0.2-0.4-0.5-0.5-0.8   c-0.1,0.3-0.2,0.6-0.5,0.8s-0.6,0.3-1,0.3c-0.5,0-0.9-0.2-1.2-0.5S105.7,55.4,105.7,54.8z M106.6,54.6c0,0.3,0.1,0.5,0.2,0.7   c0.1,0.2,0.3,0.2,0.6,0.3c0.2,0,0.4-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.7l0-1.7l-1.7,0L106.6,54.6z M109.2,54.6   c0,0.3,0.1,0.5,0.2,0.6c0.1,0.2,0.3,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.4,0.2-0.6l0-1.7l-1.6,0L109.2,54.6z"/>
            <path fill="white"
                  d="M104.3,65.3l0.2-1.2l1.2-0.2l0.5-2.7l-1.1-0.6l0.2-1.2l5.5,3.4l-0.2,1.3L104.3,65.3z M106.7,63.8l3-0.5l-2.6-1.6   L106.7,63.8z"/>
            <path fill="white"
                  d="M102.6,70.1c0.4-1,0.9-1.6,1.8-1.9l0.6,0.8c-0.7,0.3-1.2,0.8-1.4,1.5c-0.1,0.4-0.2,0.7-0.1,0.9c0.1,0.2,0.2,0.4,0.4,0.5   c0.2,0.1,0.4,0,0.6-0.1c0.2-0.2,0.4-0.4,0.6-0.7c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.3,0.5-0.5,0.8-0.6c0.3-0.1,0.7-0.1,1.1,0   c0.5,0.2,0.8,0.5,1,1c0.2,0.5,0.1,1-0.1,1.7c-0.3,0.9-0.9,1.5-1.6,1.8l-0.5-0.8c0.6-0.3,1-0.7,1.3-1.4c0.1-0.3,0.1-0.5,0.1-0.8   c-0.1-0.2-0.2-0.4-0.4-0.4c-0.1-0.1-0.3,0-0.4,0s-0.3,0.2-0.4,0.4c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.3,0.4-0.5,0.6   c-0.2,0.2-0.3,0.4-0.5,0.5s-0.4,0.2-0.7,0.3c-0.3,0-0.5,0-0.8-0.1c-0.5-0.2-0.9-0.5-1-1C102.3,71.4,102.3,70.8,102.6,70.1z"/>
            <path fill="white"
                  d="M93.6,85.1l1.5-1.6l4.4,4.1L98,89.1c-0.6,0.7-1.3,1-2.2,1.1c-0.8,0-1.5-0.2-2.2-0.8c-0.6-0.6-1-1.3-1-2.1   C92.6,86.5,92.9,85.8,93.6,85.1z M94.2,85.7c-0.4,0.5-0.6,1-0.6,1.5c0,0.5,0.3,1,0.7,1.4c0.4,0.4,0.9,0.6,1.5,0.6   c0.5,0,1-0.2,1.5-0.7l0.8-0.9l-3-2.8L94.2,85.7z"/>
            <path fill="white"
                  d="M85.5,92.1l3.3-2.5l3.6,4.8l-3.3,2.5l-0.6-0.7l2.4-1.9l-0.9-1.2l-2.4,1.8l-0.6-0.7l2.4-1.8l-1-1.3l-2.4,1.9L85.5,92.1z    M90.6,98.1l0.4-1.9l0.5-0.4l-0.2,1.8L90.6,98.1z"/>
            <path fill="white"
                  d="M81.6,94.5l0.9-0.5l2.8,5.3l-3.6,1.9l-0.4-0.8L84,99l-0.7-1.4L80.6,99l-0.4-0.8l2.6-1.4L81.6,94.5z"/>
            <path fill="white" d="M74.8,97.6l1-0.4l2.1,5.6l-1,0.4L74.8,97.6z"/>
            <path fill="white" d="M67.6,99.6l3.6-0.8l1.4,5.8l-1,0.2l-1.1-4.9l-2.6,0.6L67.6,99.6z"/>
            <path fill="white"
                  d="M60.1,100.4l4.1-0.3l0.4,6l-4.1,0.3l-0.1-0.9l3.1-0.2l-0.1-1.6l-3,0.2l-0.1-0.9l3-0.2l-0.1-1.7l-3.1,0.2L60.1,100.4z"/>
            <path fill="white"
                  d="M52,99.9l1.2,0.1l1.1,2.4l1,0.1l0.2-2.2l1,0.1l-0.6,6l-2.6-0.3c-0.6-0.1-1-0.3-1.4-0.7c-0.3-0.4-0.5-0.8-0.4-1.4   c0-0.5,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.4,1.1-0.5L52,99.9z M53.8,103.3c-0.3,0-0.6,0-0.8,0.2s-0.3,0.4-0.4,0.7c0,0.3,0,0.5,0.2,0.7   c0.2,0.2,0.4,0.3,0.7,0.3l1.4,0.1l0.2-1.9L53.8,103.3z"/>
            <path fill="white" d="M41.1,96.8l1.2,0.5l0,6.4l-1.1-0.4l0.1-5.2l-3.5,3.8l-1.1-0.4L41.1,96.8z"/>
            <path fill="white"
                  d="M32.7,92.3l3.5,2.2l-3.1,5.1l-3.5-2.2l0.5-0.8l2.6,1.6l0.8-1.3l-2.6-1.6l0.5-0.8l2.6,1.6l0.9-1.4l-2.6-1.6L32.7,92.3z"/>
            <path fill="white"
                  d="M26.4,87.2l0.9,0.8l-0.5,2.6l0.8,0.7l1.5-1.7l0.8,0.7l-4,4.5l-2-1.7c-0.4-0.4-0.7-0.8-0.7-1.3c0-0.5,0.1-1,0.5-1.4   c0.3-0.4,0.7-0.6,1.1-0.6c0.4,0,0.8,0,1.1,0.2L26.4,87.2z M25.9,91c-0.2-0.2-0.5-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3   c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.2,0.5,0.4,0.7l1.1,0.9l1.3-1.4L25.9,91z"/>
            <path fill="white"
                  d="M22.8,83c0.7,0.8,0.9,1.6,0.8,2.5l-1,0.1c0.1-0.7-0.1-1.4-0.6-2c-0.3-0.3-0.5-0.5-0.8-0.5s-0.5,0-0.6,0.1   c-0.2,0.1-0.2,0.4-0.2,0.6c0,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.3,0.6,0.4,1c0.1,0.3,0.2,0.7,0.1,1s-0.2,0.7-0.5,0.9   c-0.4,0.3-0.8,0.4-1.4,0.3s-1-0.4-1.4-0.9c-0.6-0.7-0.8-1.5-0.8-2.3l1,0c-0.1,0.6,0.1,1.2,0.6,1.8c0.2,0.2,0.4,0.4,0.6,0.4   c0.2,0.1,0.4,0,0.6-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.2-0.5-0.3-0.7   s-0.1-0.5-0.2-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.3-0.5,0.5-0.6c0.4-0.3,0.9-0.5,1.4-0.4C21.8,82.1,22.3,82.4,22.8,83z"/>
            <path fill="white" d="M15.5,70.8l1.5,3.4l-5.5,2.4l-0.4-1l4.6-2.1l-1.1-2.4L15.5,70.8z"/>
            <path fill="white"
                  d="M13.3,63.6l1.1,4l-5.8,1.5l-1.1-4l0.9-0.2l0.8,3l1.5-0.4l-0.8-2.9l0.9-0.2l0.8,2.9l1.6-0.4l-0.8-3L13.3,63.6z"/>
            <path fill="white"
                  d="M12.3,52.3l0,3l-6-0.1l0-3c0-0.5,0.2-0.9,0.5-1.2s0.7-0.4,1.1-0.4c0.4,0,0.7,0.1,0.9,0.3s0.4,0.5,0.5,0.8   c0.1-0.3,0.2-0.6,0.5-0.8c0.3-0.2,0.6-0.3,1-0.3c0.5,0,0.9,0.2,1.2,0.5C12.2,51.3,12.3,51.7,12.3,52.3z M8.8,52.5   c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.2-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.4-0.2,0.6l0,1.7l1.6,0L8.8,52.5z    M11.4,52.5c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.2-0.6-0.3c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.4-0.2,0.7l0,1.7l1.7,0   L11.4,52.5z"/>
            <path fill="white"
                  d="M13.7,41.8l-0.2,1.2l-1.2,0.2l-0.5,2.7l1,0.7l-0.2,1.2l-5.4-3.5l0.2-1.3L13.7,41.8z M11.3,43.3l-3,0.5l2.6,1.6L11.3,43.3z"/>
            <path fill="white"
                  d="M15.4,37c-0.4,1-1,1.6-1.8,1.9l-0.5-0.8c0.7-0.3,1.2-0.8,1.5-1.5c0.1-0.4,0.2-0.7,0.1-0.9c-0.1-0.2-0.2-0.4-0.4-0.5   c-0.2-0.1-0.4,0-0.6,0.1c-0.2,0.2-0.4,0.4-0.6,0.7c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.5,0.5-0.8,0.6s-0.7,0.1-1.1,0   c-0.5-0.2-0.8-0.5-1-1s-0.1-1,0.1-1.7c0.3-0.9,0.9-1.5,1.6-1.8l0.5,0.8c-0.6,0.3-1,0.7-1.3,1.4c-0.1,0.3-0.1,0.5-0.1,0.8   c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.1,0.3,0,0.4,0c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.6   c0.2-0.2,0.4-0.4,0.5-0.5c0.2-0.1,0.4-0.2,0.7-0.3s0.5,0,0.8,0.1c0.5,0.2,0.9,0.5,1,1C15.8,35.7,15.7,36.3,15.4,37z"/>
        </g>
    </svg>
};

export default RotateFRText;