import React, {useContext} from 'react';
import './about-block.css'
import AboutSVG from "../../icon/text_about";
import {Link} from "react-router-dom";
import AboutSvgFR from "../../icon/text_about_FR";
import LanguageContext from "../../../context/language-context";
import AnimateFigure from "../../animate-figure/animate-figure";

const AboutBlock = () => {
    const {language, aboutBlock} = useContext(LanguageContext);
    const {text1, text11, text2, text22, text3, text4, text5, text6} = aboutBlock;

    return <div className="aboutBlock fullHeight">
        <div className="aboutSVG lottie animated about-block" style={{visibility: "visible"}}>
            <AnimateFigure height={undefined} divider={0.5}/>
        </div>

        <div className="container">
            <div className="row">
                <div className="w50"/>
                <div className="w50">
                    <h2 className="aos aos-init" data-aos="fade-up" data-delay="0"
                        style={{"transitionDelay": '0ms'}}>
                        <span>{text1}</span>
                        <span>{text11}</span>
                        <span>{text2}</span>
                        <span>{text22}</span>
                        <span>{text3}&nbsp;<b>{text4}</b><b>.</b></span>
                    </h2>
                    <div className="text aos aos-init" data-aos="fade-up" data-delay="50"
                         style={{"transitionDelay": '50ms'}}>
                        <p>{text5}</p>
                    </div>

                    <div className="titleSVG aos aos-init" data-aos="fade-up" data-delay="100"
                         style={{transitionDelay: '100ms'}}>
                        {language === 'EN'
                            ? <AboutSVG/>
                            : <AboutSvgFR/>}
                        <Link to={language === 'EN' ? '/about' : '/fr/a-propos'}
                              className="line_hover type2 arrow bigPadding">
                            {text6}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default AboutBlock;