import React, {useContext} from 'react';
import contactImg from '../../../jpg/contact2-min-scaled.jpg'
import LanguageContext from "../../../context/language-context";

const ContactBanner = () => {
    const {contactPage} = useContext(LanguageContext);
    const {text1, text2, text3} = contactPage.banner;

    return <div className="contactsBanner">
        <div className="bg lazy"
             style={{display: 'block', backgroundImage: `url(${contactImg})`, visibility: 'visible', opacity: 1}}/>
        <div className="container">
            <div className="contactsDataItem aos aos-init" data-aos="fade-up" data-delay="0"
                 style={{transitionDelay: '0ms'}}>
                <ul>
                    <li>
                        <b>{text1}</b>
                        <a href="http://maps.google.com/?q=4-6 Place de l’Université, 1205 Geneva, Switzerland"
                           target="_blank" rel='noreferrer'>
                            {text2}
                        </a>
                    </li>
                    <li>
                        <b>{text3}</b>
                        <a href="tel:+41 (22) 552 6060">+41 (22) 552 6060</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
};

export default ContactBanner;

