import React, {useContext} from 'react';
import DirectionsSVG from "../../icon/text_directions";
import DirectionsSvgFr from "../../icon/text_directions_FR";
import LanguageContext from "../../../context/language-context";
import NewLogoWithoutText from "../../icon/new_logo_without_text";
import "./directions-info.scss";

const DirectionsInfo = () => {
    const {language, directionsPageInfo} = useContext(LanguageContext);
    const {text1, text2, text3, text4, text5, text6, text7, timeline} = directionsPageInfo;

    return (
        <div className="container no_padding_bottom directions-info">
            <div className="titleRow main">
                <div
                    className="text aos aos-init"
                    data-aos="fade-up"
                    data-delay="0"
                    style={{transitionDelay: '0ms'}}
                >
                    <h2>{text1}<span style={{color: "#f67c1f"}}>{text2}</span> {text3}</h2>
                </div>
                <div
                    className="titleSVG noMargin aos aos-init"
                    data-aos="fade-up" data-delay="100"
                    style={{transitionDelay: '100ms'}}
                >
                    {language === 'EN' ?
                        <DirectionsSVG/> :
                        <DirectionsSvgFr/>
                    }
                </div>
            </div>
            <div className="text contentText aos aos-init" data-aos="fade-up">
                <p>{text4}</p>
                <p>{text5}</p>
                <p>{text6} {timeline.length} {text7}</p>
            </div>
            <div className="horizontal-timeline">
                <NewLogoWithoutText width={50} height={50}/>
                <div className="horizontal-timeline__container horizontal-timeline__gradient">
                    {timeline.map((item, idx) => {
                        return <div className={`horizontal-timeline__item ${idx % 2 === 0
                            ? 'horizontal-timeline__item_bottom'
                            : 'horizontal-timeline__item_top'}`}>
                            <div>
                                <span style={{fontWeight: 'bold'}}>{item.date}</span>
                                <br/>
                                <span>{item.text}</span>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    );
};

export default DirectionsInfo;