import React, {useContext} from 'react';
import BtnScrollToTop from "../icon/btn-scroll-to-top";
import {Link} from "react-router-dom";
import LanguageContext from "../../context/language-context";
import NewSmallLogo from "../icon/new_small_logo";
import "./footer.scss";

const Footer = () => {
    const {language, footer} = useContext(LanguageContext);
    const {leftSide, rightSide, terms, linkToTerms, text, policy, linkToPolicy} = footer;

    const createLink = (item, idx, key) => {
        const {text, link} = item;

        return <Link to={link} key={key + idx}>{text}</Link>;
    };

    return (
        <footer className="wrapper">
            <BtnScrollToTop/>
            <div className="footer">
                <div className="footer__navigation">
                    <div className="footer__navigation-links">
                        {leftSide.map((item, idx) => createLink(item, idx, "left"))}
                    </div>
                    <div className="footer__navigation-logo">
                        <Link to={language === 'EN' ? '/' : '/fr'}>
                            <NewSmallLogo width={110} height={70} fill={"var(--indigo)"}/>
                        </Link>
                    </div>
                    <div className="footer__navigation-links">
                        {rightSide.map((item, idx) => createLink(item, idx, "right"))}
                    </div>
                </div>
                <div className="footer__docs">
                    <div className="footer__docs-item">
                        <Link to={linkToTerms}>{terms}</Link>
                    </div>
                    <div className="footer__docs-item footer__docs-item_copyright">{text}</div>
                    <div className="footer__docs-item">
                        <Link to={linkToPolicy}>{policy}</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;