import React from 'react';
import './home.css'
import VideoBlock from "./video-block/video-block";
import AboutBlock from "./about-block/about-block";
import ProjectBlock from "./project-block/project-block";
import CareersBlock from "./careers-block/careers-block";
import ContactBlock from "./contact-block/contact-block";
import withCookie from "../../hoc/withCookie";

const Home = () => {
    return <div className="wrapper mainPage main-page">
        <VideoBlock/>
        <AboutBlock/>
        <ProjectBlock/>
        <CareersBlock/>
        <ContactBlock/>
    </div>;
};

export default withCookie(Home);