import React, {useContext} from 'react';
import ContactBlock from "../home/contact-block/contact-block";
import Swiper from 'react-id-swiper';
import './project.css';
import {Link} from "react-router-dom";
import LanguageContext from "../../context/language-context";


const Project = ({data}) => {
    const {
        returnTo, title, directionSvg, shortDescribe, bannerImg, introduction, sliderImg, viewCareerTitle,
        viewCareerSvg, viewCareerText, nextDirectionTitle, nextDirectionSvg, nextDirectionText, linkToCareer,
        nextLink, fr
    } = data;

    const {language} = useContext(LanguageContext);

    const sliderParams = {
        slidesPerView: 'auto',
        spaceBetween: 15,
        pagination: {
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-btn-next',
            prevEl: '.swiper-btn-prev'
        }
    };

    const createPBlock = (item, idx, key) => {
        const {text, delay} = item;
        return <p key={key + idx}
                  className="aos aos-init"
                  data-aos="fade-up"
                  data-delay={delay}
                  style={{transitionDelay: `${delay}ms`}}>
            {text}
        </p>
    };

    const createLiBlock = (item, idx, key) => <li key={key + idx}>{item}</li>;

    const createSlide = (src, idx, key) => {
        return (
            <div
                key={key + idx}
                className="swiper-slide projectSingleSliderItem projectSliderItem swiper-slide-active"
                style={{marginRight: '16px'}}
            >
                <img className="bg swiper-lazy swiper-lazy-loaded" src={src} alt="projectSingleSlider"/>
            </div>
        );
    };

    return <div className="wrapper projectSinglePage" data-name="projectsPage">
        <div className="container">
            <div className={`titleRow main bigTitle smallMargin ${language === 'FR' ? 'fr' : ''}`}>
                <Link to={returnTo} className="goBack aos aos-init" data-aos="fade-up"/>
                <div className="text aos aos-init" data-aos="fade-up" data-delay="0"
                     style={{transitionDelay: '0ms'}}>
                    <h1>{title}</h1>
                </div>
                <div className="titleSVG noMargin aos aos-init" data-aos="fade-up" data-delay="100"
                     style={{transitionDelay: '100ms'}}>
                    {directionSvg}
                </div>
            </div>
            <div className="row margin0 between nowrap aos aos-init" data-aos="fade-up">
                <div className="text contentText moreWidth">
                    <p>{shortDescribe}</p>
                </div>
            </div>
        </div>

        <div className="projectSingle">
            <div className="articleBanner aos aos-init" data-aos="fade-up">
                <img
                    src={bannerImg}
                    alt="articleBanner"/>
            </div>
            <div className="container container_without_padding">
                <div className="articleContent text">
                    <h3 className="aos aos-init"
                        data-aos="fade-up"
                        data-delay="0"
                        style={{transitionDelay: '0ms'}}>
                        {introduction.title}
                    </h3>

                    {introduction.listOfP.map((item, idx) => createPBlock(item, idx, 'pList'))}

                    {introduction.listOfLi.length
                        ? <ul className="aos aos-init"
                              data-aos="fade-up"
                              data-delay="75"
                              style={{transitionDelay: '75ms'}}>
                            {introduction.listOfLi.map((item, idx) => createLiBlock(item, idx, 'LiList'))}
                        </ul>
                        : null
                    }

                    {introduction.listOfP2.map((item, idx) => createPBlock(item, idx, 'pList'))}

                    {introduction.listOfLi2.length
                        ? <ul className="aos aos-init"
                              data-aos="fade-up"
                              data-delay="75"
                              style={{transitionDelay: '75ms'}}>
                            {introduction.listOfLi2.map((item, idx) => createLiBlock(item, idx, 'LiList'))}
                        </ul>
                        : null
                    }


                    <div className="projectSlider projectSingleSlider aos aos-init"
                         data-aos="fade-up"
                         data-delay="100"
                         style={{transitionDelay: '100ms'}}>
                        <Swiper {...sliderParams}>
                            {sliderImg.map((src, idx) => createSlide(src, idx, 'slide'))}
                        </Swiper>
                        <span className="swiper-notification" aria-live="assertive" aria-atomic="true"/>
                    </div>
                </div>
            </div>

            <div className="articleNavigation">
                <div className="articleNavigationItem team">
                    <div className="container container_without_padding">
                        <div className="titleRow">
                            <h4 className="h2 aos aos-init" data-aos="fade-right">
                                {viewCareerTitle}
                            </h4>
                            <div className={`titleSVG noMargin aos aos-init ${fr ? 'fr' : ''}`} data-aos="fade-up"
                                 data-delay="0" style={{transitionDelay: '0ms'}}>
                                {viewCareerSvg}
                                <Link to={linkToCareer} className="line_hover type2 arrow bigPadding">
                                    {viewCareerText}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {nextDirectionTitle
                    ? <div className="articleNavigationItem projects">
                        <div className="container container_without_padding">
                            <div className="titleRow">

                                <h4 className="h2 aos aos-init" data-aos="fade-right">
                                    {nextDirectionTitle}
                                </h4>
                                <div className={`titleSVG noMargin aos aos-init ${fr ? 'fr' : ''}`} data-aos="fade-up"
                                     data-delay="0" style={{transitionDelay: '0ms'}}>
                                    {nextDirectionSvg}
                                    <Link to={nextLink} className="line_hover type2 arrow bigPadding">
                                        {nextDirectionText}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }


            </div>
        </div>

        <ContactBlock/>
    </div>
};

export default Project;