import React, {useState, useRef, useEffect} from 'react';

const FilterBlock = ({item, filter}) => {
    const [showDropMenu, setShowDropMenu] = useState(false);
    const {setFilter, data, selected} = filter;

    const ref = useRef(null);

    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowDropMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, []);

    const createLi = (item, idx) => {
        return <li key={'row' + idx}
                   onClick={() => setFilter(item)}
        >
            {item}
        </li>
    };

    return <div onClick={() => setShowDropMenu((showDropMenu) => {
        return !showDropMenu
    })}
                ref={ref}
                className={`filterPosiiton custom_dropdown smallWidth down ${showDropMenu ? 'open' : ''}`}>
        <div className="top">
            <div className="filterItem">
                                <span className="selected_job">
                                    {selected.length ? '' : "Filter by"} &nbsp;
                                    <span className="c_empty_job"><span/>
                                        {selected.length ? selected : item}
                                    </span>
                                </span>
                <div className="arrow"/>
            </div>
        </div>
        <ul style={{display: showDropMenu ? 'block' : 'none'}}>
            {data.map((item, idx) => createLi(item, idx))}
        </ul>
    </div>
};

export default FilterBlock;

