import React, {useContext} from 'react';
import LanguageContext from "../../context/language-context";
import AnimateFigure from "../animate-figure/animate-figure";
import {createLiBlock, createPBlock} from "../../util/util";
import withCookie from "../../hoc/withCookie";

const TermsAndConditions = () => {
    const {termsAndConditionsPage} = useContext(LanguageContext);
    const {title, texts} = termsAndConditionsPage;

    return <div className="wrapper privacyPage" data-name="projectsPage">
        <div className="container no_padding_bottom">
            <div className="titleRow main bigTitle">
                <div className="text moreWidth aos aos-init" data-aos="fade-up" data-delay="0"
                     style={{transitionDelay: '0ms'}}>
                    <h1>{title}</h1>
                </div>
            </div>

        </div>
        <div className="privacyContent container no_padding_top aos aos-init" data-aos="fade-up"
             data-delay="100" style={{transitionDelay: '100ms'}}>
            <div className="text">
                {texts.map((item, idx) => {
                    const {title, p, ul} = item;

                    return <div key={idx}>
                        {title && <h5><br/>{title}</h5>}
                        {p && p.map((pItem, pIdx) => createPBlock(pItem, pIdx, idx))}
                        {ul && <ul className="ul1">
                            {ul.map((ulItem, ulIdx) => createLiBlock(ulItem, ulIdx, idx))}
                        </ul>}
                    </div>
                })}
            </div>
        </div>

        <div className="contactsBlock small">
            <div className="wrap">
                <div className="contactLottie lottie shape contact-block" id="contactLottie"
                     style={{visibility: 'visible'}}>
                    <AnimateFigure
                        height={70}
                    />
                </div>
            </div>
        </div>
    </div>
};

export default withCookie(TermsAndConditions);