import {getFuturesDate, isEmptyObj} from "../../util/util";

const cookiePath = "/";
export const cookieNecessary = "Necessary";
export const cookieOther = "Other";
export const cookieOtherEnabled = "OtherCookieEnabled";
export const cookiePreferences = "Preferences";
export const cookieStatistics = "Statistics";
export const cookieConsent = "CookieConsent";
export const cookieConsentDate = "CookieConsentDate";
export const userId = "UserId";

export const allCookies = [cookieNecessary, cookiePreferences, cookieStatistics, cookieOtherEnabled, cookieConsent, cookieConsentDate, userId];

export const deleteCookie = (removeCookie, name) => {
    removeCookie(name, {cookiePath})
}

export const addCookie = (setCookie, key, value, expires = getFuturesDate(365)) => {
    if (key === undefined) {
        debugger;
    }

    setCookie(key, value, {cookiePath, expires});
}

export const uniqueId = () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);

    return dateString + randomness;
};

export const isEmptyCookie = (cookie) => {
    let cookieCounter = 0;

    if (cookie['_gid'])
        cookieCounter++;

    if (cookie['_ga_45SYNGBX4L'])
        cookieCounter++;

    if (cookie['_ga'])
        cookieCounter++;

    return isEmptyObj(cookie) || Object.keys(cookie).length === cookieCounter;
};
