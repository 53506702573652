import React from 'react';

const AboutSVG = () => {
    return <svg width="342" height="79" xmlns="http://www.w3.org/2000/svg"
                x="0px" y="0px" viewBox="0 0 195.5 43.8"
                style={{enableBackground: 'new 0 0 195.5 43.8'}} xmlSpace="preserve">
        <g>
            <path
                d="M27.9,34.5H12.5l-2.9,8.8H0.2L16.1,0.6h8.1l16,42.7h-9.3L27.9,34.5z M14.9,27.3h10.7l-5.4-16L14.9,27.3z"/>
            <path d="M44.2,43.2V0.6h14.9c2.5,0,4.7,0.2,6.7,0.7c2,0.5,3.6,1.2,5,2.2c1.4,1,2.4,2.2,3.1,3.6c0.7,1.5,1.1,3.2,1.1,5.2
    		c0,1-0.1,1.9-0.4,2.8c-0.2,0.9-0.6,1.7-1.1,2.5c-0.5,0.8-1.1,1.5-1.9,2.1c-0.8,0.6-1.7,1.2-2.7,1.6c1.2,0.3,2.3,0.8,3.2,1.4
    		c0.9,0.6,1.6,1.4,2.2,2.2c0.6,0.8,1,1.8,1.3,2.7c0.3,1,0.4,2,0.4,3c0,4.1-1.3,7.3-4,9.4s-6.5,3.2-11.4,3.2H44.2z M53,18.5h6.5
    		c2.2,0,3.9-0.5,5-1.4c1.1-0.9,1.6-2.2,1.6-3.9c0-1.9-0.6-3.3-1.7-4.2c-1.1-0.8-2.9-1.3-5.3-1.3H53V18.5z M53,24.7v11.5h7.5
    		c1.1,0,2.1-0.1,2.9-0.4s1.5-0.7,2.1-1.2c0.5-0.5,1-1.1,1.2-1.8s0.4-1.4,0.4-2.2c0-0.9-0.1-1.7-0.4-2.5s-0.6-1.4-1.1-1.9
    		c-0.5-0.5-1.1-0.9-1.9-1.2c-0.8-0.3-1.8-0.4-2.9-0.4H53z"/>
            <path d="M117.5,22.9c0,3.3-0.4,6.2-1.3,8.8c-0.9,2.6-2.1,4.8-3.7,6.6c-1.6,1.8-3.5,3.2-5.7,4.1s-4.7,1.5-7.4,1.5
    		c-2.7,0-5.1-0.5-7.4-1.5s-4.1-2.3-5.8-4.1s-2.9-4-3.8-6.6c-0.9-2.6-1.3-5.5-1.3-8.8V21c0-3.2,0.4-6.2,1.3-8.8
    		c0.9-2.6,2.1-4.8,3.7-6.6c1.6-1.8,3.5-3.2,5.7-4.2S96.7,0,99.3,0c2.7,0,5.1,0.5,7.4,1.5s4.1,2.4,5.7,4.2c1.6,1.8,2.8,4,3.7,6.6
    		c0.9,2.6,1.3,5.5,1.3,8.8V22.9z M108.6,20.9c0-4.5-0.8-7.9-2.4-10.2c-1.6-2.3-3.9-3.5-6.9-3.5c-3,0-5.3,1.2-6.9,3.5
    		c-1.6,2.3-2.4,5.7-2.4,10.2v1.9c0,2.2,0.2,4.2,0.6,5.9c0.4,1.7,1,3.1,1.8,4.3s1.8,2,2.9,2.6c1.2,0.6,2.5,0.9,4,0.9
    		c3,0,5.2-1.2,6.8-3.5c1.6-2.3,2.4-5.8,2.4-10.2V20.9z"/>
            <path d="M156.2,0.6v28.2c0,2.5-0.4,4.6-1.2,6.5s-2,3.5-3.4,4.7c-1.4,1.3-3.2,2.2-5.2,2.9c-2,0.6-4.2,1-6.5,1c-2.4,0-4.6-0.3-6.6-1
    		c-2-0.6-3.7-1.6-5.2-2.9c-1.4-1.3-2.6-2.8-3.4-4.7c-0.8-1.9-1.2-4.1-1.2-6.5V0.6h8.8v28.2c0,2.8,0.7,4.8,2,6.1
    		c1.3,1.3,3.2,1.9,5.6,1.9c2.4,0,4.2-0.6,5.6-1.9s2-3.3,2-6.1V0.6H156.2z"/>
            <path d="M195.5,7.7h-13.1v35.5h-8.8V7.7h-12.9V0.6h34.7V7.7z"/>
        </g>
    </svg>
};

export default AboutSVG;