import React from 'react';
import CareersInfo from "./careers-info/careers-info";
import './careers';
import CareersBanner from "./careers-banner/careers-banner";
import OpenedPositions from "./opened-positions/opened-positions";
import AnimateFigure from "../animate-figure/animate-figure";
import withCookie from "../../hoc/withCookie";

const Careers = () => {
    return <div className="wrapper careersPage">
        <CareersInfo/>
        <CareersBanner/>
        <OpenedPositions/>

        <div className="contactsBlock">
            <div className="wrap">
                <div className="contactLottie lottie shape animated contact-block" style={{visibility: 'visible'}}>
                    <AnimateFigure
                        height={70}
                    />
                </div>
            </div>
        </div>

    </div>
};

export default withCookie(Careers);