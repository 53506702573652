import React from 'react';
import ContactForm from "./contact-form/contact-form";
import './contact.css';
import ContactBanner from "./contact-banner/contact-banner";
import withCookie from "../../hoc/withCookie";

const Contact = () => {
    return <div className="wrapper contactPage">
       <ContactForm/>
       <ContactBanner/>
    </div>
};

export default withCookie(Contact);