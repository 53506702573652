import React from 'react';

const Close = ({setState}) => {
    return  <svg onClick={() => setState(false)}
                 className="close"
                 width="32"
                 height="32"
                 viewBox="0 0 32 32"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
        <rect x="3.97925" y="27" width="32" height="2" transform="rotate(-45 3.97925 27)" fill="white"/>
        <rect x="5.39331" y="3.99976" width="32" height="2" transform="rotate(45 5.39331 3.99976)"
              fill="white"/>
    </svg>
};

export default Close;