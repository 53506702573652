import React from 'react';

const Slash = () => {
    return <svg width="9"
                height="15"
                viewBox="0 0 9 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <line opacity="0.3"
              x1="0.565878"
              y1="14.7519"
              x2="8.56588"
              y2="0.75193"
              stroke="white" />
    </svg>
};

export default Slash;