import React from 'react';

const NextDirection = () => {
    return <svg id="layer_1" xmlns="http://www.w3.org/2000/svg"
                x="0px" y="0px" width="833.2px"
                height="76.7px" viewBox="0 0 833.2 76.7"
                style={{enableBackground: 'new 0 0 833.2 76.7'}} xmlSpace="preserve">
        <g>
            <path d="M63.1,75.7H47.3L16.5,26.5v49.2H0.7V0.9h15.8l30.8,49.3V0.9h15.8V75.7z"/>
            <path
                d="M123.1,43.3H92.7v20h35.6v12.4H76.9V0.9h51.4v12.5H92.7v17.8h30.4V43.3z"/>
            <path
                d="M165.1,26.7l14.4-25.8h18.2L175.4,38l22.9,37.7h-18.4l-14.8-26.2l-14.8,26.2H132L154.9,38L132.6,0.9h18.2L165.1,26.7z"/>
            <path d="M264.1,13.4h-23.5v62.3h-15.8V13.4h-23.2V0.9h62.5V13.4z"/>
            <path d="M296.8,75.7V0.9h23.6c5.2,0,9.9,0.9,14.2,2.6c4.3,1.7,8,4.2,11.1,7.3c3.1,3.1,5.5,6.9,7.2,11.3c1.7,4.4,2.6,9.2,2.6,14.6
									v3.4c0,5.3-0.9,10.2-2.6,14.6c-1.7,4.4-4.1,8.1-7.2,11.2s-6.8,5.6-11.2,7.3c-4.3,1.7-9.1,2.6-14.3,2.6H296.8z M312.6,13.4v49.9h7.6
									c6.3,0,11-2,14.3-6c3.3-4,4.9-9.8,4.9-17.2v-3.5c0-7.6-1.6-13.4-4.9-17.3c-3.2-3.9-8-5.9-14.2-5.9H312.6z"/>
            <path d="M383.8,75.7H368V0.9h15.8V75.7z"/>
            <path d="M426.9,48.3h-12.6v27.4h-15.8V0.9H427c4.4,0,8.4,0.5,11.9,1.5c3.5,1,6.5,2.4,8.9,4.3s4.3,4.3,5.6,7.1c1.3,2.8,2,6.1,2,9.8
									c0,2.7-0.3,5.2-0.9,7.4c-0.6,2.2-1.5,4.1-2.7,5.8c-1.2,1.7-2.6,3.2-4.3,4.4c-1.7,1.3-3.6,2.4-5.7,3.3L458.3,75v0.7h-17L426.9,48.3z
									 M414.3,35.9h12.8c2.1,0,3.9-0.3,5.5-0.8c1.6-0.5,2.9-1.3,3.9-2.3c1-1,1.8-2.1,2.3-3.5c0.5-1.4,0.8-2.9,0.8-4.5
									c0-3.5-1-6.3-3.1-8.3c-2.1-2-5.2-3-9.5-3h-12.7V35.9z"/>
            <path
                d="M513.6,43.3h-30.4v20h35.6v12.4h-51.5V0.9h51.4v12.5h-35.5v17.8h30.4V43.3z"/>
            <path d="M588.4,50.8c-0.3,3.8-1.2,7.3-2.6,10.5c-1.5,3.2-3.5,5.9-6.1,8.2c-2.6,2.3-5.8,4.1-9.5,5.3c-3.7,1.3-7.9,1.9-12.7,1.9
									c-4.9,0-9.3-0.8-13.3-2.5c-3.9-1.7-7.2-4-10-7.2c-2.7-3.1-4.8-6.9-6.3-11.3c-1.5-4.4-2.2-9.4-2.2-14.9v-4.9
									c0-5.5,0.8-10.5,2.3-14.9c1.5-4.4,3.6-8.2,6.4-11.3c2.8-3.1,6.1-5.5,10-7.2c3.9-1.7,8.3-2.5,13.2-2.5c4.8,0,9,0.7,12.7,2
									c3.7,1.3,6.8,3.1,9.4,5.5c2.6,2.3,4.6,5.1,6,8.4c1.4,3.2,2.4,6.8,2.7,10.5h-15.8c-0.2-2.2-0.6-4.2-1.2-5.9
									c-0.6-1.7-1.5-3.1-2.7-4.3c-1.2-1.2-2.7-2-4.5-2.6c-1.8-0.6-4-0.9-6.6-0.9c-5.3,0-9.3,1.9-11.9,5.7c-2.6,3.8-3.9,9.6-3.9,17.6v5
									c0,3.9,0.3,7.3,0.9,10.2c0.6,2.9,1.5,5.3,2.7,7.3c1.2,2,2.9,3.4,4.9,4.4c2,1,4.4,1.5,7.2,1.5c2.5,0,4.6-0.3,6.5-0.8
									c1.8-0.5,3.3-1.4,4.5-2.5c1.2-1.1,2.2-2.5,2.8-4.2c0.7-1.7,1.1-3.6,1.3-5.9H588.4z"/>
            <path d="M655.1,13.4h-23.5v62.3h-15.8V13.4h-23.2V0.9h62.5V13.4z"/>
            <path d="M680.9,75.7h-15.8V0.9h15.8V75.7z"/>
            <path d="M758.7,40c0,5.7-0.8,10.8-2.4,15.4c-1.6,4.5-3.8,8.4-6.7,11.5c-2.9,3.1-6.3,5.6-10.3,7.3s-8.4,2.5-13.2,2.5
									c-4.8,0-9.2-0.8-13.2-2.5s-7.5-4.1-10.4-7.3c-2.9-3.1-5.2-7-6.8-11.5c-1.6-4.5-2.4-9.7-2.4-15.4v-3.3c0-5.7,0.8-10.8,2.4-15.3
									c1.6-4.6,3.8-8.4,6.7-11.6c2.9-3.2,6.3-5.6,10.3-7.3s8.4-2.5,13.2-2.5c4.8,0,9.2,0.8,13.2,2.5s7.5,4.1,10.3,7.3
									c2.9,3.2,5.1,7.1,6.7,11.6c1.6,4.6,2.4,9.7,2.4,15.3V40z M742.6,36.6c0-7.8-1.4-13.8-4.3-17.9c-2.9-4.1-7-6.1-12.3-6.1
									c-5.3,0-9.5,2-12.3,6.1c-2.9,4.1-4.3,10-4.3,17.9V40c0,3.9,0.4,7.4,1.1,10.3c0.8,3,1.8,5.5,3.3,7.5c1.4,2,3.2,3.6,5.2,4.6
									c2.1,1,4.4,1.6,7.1,1.6c5.3,0,9.4-2.1,12.3-6.2s4.3-10.1,4.3-17.9V36.6z"/>
            <path
                d="M832.6,75.7h-15.8L786,26.5v49.2h-15.8V0.9H786l30.8,49.3V0.9h15.8V75.7z"/>
        </g>
    </svg>;
};

export default NextDirection;

