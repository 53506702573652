import React, {useContext} from 'react';
import TypingText from "./typing-text";
import RotateENText from "../../icon/rotate_scroll_down_EN";
import OvalWithCircle from "../../icon/oval-with-circle";
import RotateFRText from "../../icon/rotate_scroll_down_FR";
import {Link} from "react-router-dom";
import LanguageContext from "../../../context/language-context";
import video from "../../../video/PBFS-intro_new.mp4";
import "./video-block.css";

const VideoBlock = () => {
    const {language, videoBlock} = useContext(LanguageContext);
    const {text1, text2, text3} = videoBlock;

    return <div className="first-screen full-height white-text">
        <div className="video lazy">
            <video src={video} playsInline autoPlay loop muted/>

            {/*<img src={videoGif} alt="loading..." />*/}
        </div>

        <div className="container">
            <div className="content">
                <section className="cd-intro aos aos-init">
                    <h1 className="cd-headline letters type">
                        <span>{text1}</span>
                        <span className="cd-words-wrapper">
                                <TypingText
                                    language={language}
                                />
 						 </span>
                        <span style={{display: "block"}}>{text2}</span>
                    </h1>
                </section>
                <div className="bottomLink aos aos-init">
                    <Link to={language === 'EN' ? '/investment' : '/fr/investissement'} className="line_hover type2 arrow">
                        {text3}
                    </Link>
                </div>

            </div>
        </div>

        <div className="scrollDown aos aos-init aos-animate fadeDone" data-aos="fade-up" data-delay="200"
             style={{'transitionDelay': '200ms'}}>
            {language === 'EN' ? <RotateENText/> : <RotateFRText/>}
            <OvalWithCircle/>
        </div>
    </div>
};

export default VideoBlock;