import React, {useContext} from 'react';
import LanguageContext from "../../context/language-context";
import Project from "./project";
import withCookie from "../../hoc/withCookie";

const WrapperEnergyGenerationAndStorage = () => {
    const {projects} = useContext(LanguageContext);

    return <div>
        <Project
            data={projects.energy}
        />
    </div>
};

export default withCookie(WrapperEnergyGenerationAndStorage);

