import React, {useContext} from 'react';
import './prokect-block.css';
import DirectionsSVG from "../../icon/text_directions";
import ProjectSlider from "../../project-slider/project-slider";
import {Link} from "react-router-dom";
import LanguageContext from "../../../context/language-context";
import DirectionsSvgFr from "../../icon/text_directions_FR";

const ProjectBlock = () => {
    const {language, projectBlock} = useContext(LanguageContext);
    const {text1, text2, text3, text4} = projectBlock;

    return <div className="projectsBlock">
        <div className="container">
            <div className="titleRow">
                <div className="text aos aos-init" data-aos="fade-up" data-delay="0"
                     style={{"transitionDelay": "0ms"}}>
                    <h2><b>{text1}</b>{text2}<b>{text3}</b></h2>
                </div>

                <div className="titleSVG aos aos-init" data-aos="fade-up" data-delay="100"
                     style={{transitionDelay: '100ms'}}>
                    {language === 'EN'
                        ? <DirectionsSVG/>
                        : <DirectionsSvgFr/>}
                    <Link to={language === 'EN' ? '/investment' : '/fr/investissement'}
                          className="line_hover type2 arrow bigPadding">
                        {text4}
                    </Link>
                </div>
            </div>
            <ProjectSlider
                slider={'sliderText'}
            />
        </div>
    </div>
};

export default ProjectBlock;