import React, {useContext} from 'react';
import LanguageContext from "../../../context/language-context";
import './foundation-info.scss';
import FoundationSVG from "../../icon/text_foundation";
import FoundationSVGFr from "../../icon/text_foundation_FR";

const FoundationInfo = () => {
    const {language, foundationPage} = useContext(LanguageContext);
    const {text1, text2, text3, text4, text5} = foundationPage;

    return (
        <div className="container no_padding_bottom directions-info">
            <div className="titleRow main">
                <div
                    className="text aos aos-init"
                    data-aos="fade-up"
                    data-delay="0"
                    style={{transitionDelay: '0ms'}}
                >
                    <h2>{text1}<span style={{color: "#f67c1f"}}>{text2}</span> {text3}</h2>
                </div>
                <div
                    className="titleSVG noMargin aos aos-init"
                    data-aos="fade-up" data-delay="100"
                    style={{transitionDelay: '100ms'}}
                >
                    {language === 'EN' ?
                        <FoundationSVG/> :
                        <FoundationSVGFr/>
                    }
                </div>
            </div>
            <div className="text contentText aos aos-init" data-aos="fade-up">
                <p>{text4}</p>
                <p>{text5}</p>
            </div>
        </div>
    );
};

export default FoundationInfo;

