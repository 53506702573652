import React from 'react';

const AboutSvgFR = () => {
    return <svg className="aprov" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                x="0px" y="0px" viewBox="0 0 380 79"
                style={{enableBackground:'new 0 0 380 79'}} xmlSpace="preserve">
								<g>
									<path d="M50.3,71H36.9l-3.5-11.3h-17L13.1,71H-0.1l18-53h13.6L50.3,71z M15.5,3.8h12.2l1.6,9.3h-7.1L15.5,3.8z M30.6,50l-5.8-20.4
										L19.3,50H30.6z"/>
                                    <path d="M88.3,71H76.2V18h17.6c1.5,0,2.9,0,4.4,0c1.5,0,2.9,0.1,4.4,0.2c0.5,0.1,1.2,0.2,2.1,0.4c0.9,0.2,1.9,0.5,3,0.9
										c1.1,0.5,2.2,1.1,3.4,1.8c1.2,0.8,2.2,1.8,3.1,3.1c0.6,1,1.3,2.3,2,4c0.7,1.7,1.1,4,1.1,6.7c0,0.5,0,1.1-0.1,1.8
										c-0.1,0.8-0.2,1.7-0.4,2.6c-0.2,1-0.5,2-0.9,3.1c-0.4,1.1-1,2.1-1.8,3.2c-0.2,0.4-0.7,0.9-1.2,1.5c-0.6,0.7-1.3,1.3-2.2,2
										c-0.9,0.7-1.9,1.3-3,1.7c-1,0.4-2.2,0.8-3.5,1c-1.3,0.2-2.8,0.4-4.5,0.4c-1.7,0.1-3.7,0.1-5.9,0.1h-5.4V71z M88.3,42.8h7
										c0.8,0,1.7,0,2.5-0.1c0.8,0,1.6-0.2,2.4-0.4c0.7-0.2,1.4-0.5,2.1-0.9c0.6-0.4,1.2-1,1.8-1.8c0.8-1.2,1.3-2.7,1.3-4.4
										c0-0.3,0-0.7-0.1-1.2s-0.2-1.1-0.4-1.7c-0.2-0.6-0.6-1.2-1-1.8c-0.5-0.6-1.1-1.1-1.9-1.6c-1.1-0.6-2.4-1-3.9-1.1s-3.4-0.1-6-0.2
										h-3.7V42.8z"/>
                                    <path d="M134.9,71h-11.9V18h16.2c1.9,0,3.8-0.1,5.7,0c1.9,0,3.8,0.1,5.7,0.3c1.7,0.2,3.2,0.4,4.4,0.8c1.3,0.4,2.3,0.9,3.3,1.4
										c0.9,0.6,1.7,1.1,2.3,1.8c0.6,0.6,1.2,1.3,1.6,1.9c0.5,0.7,1,1.7,1.6,3.1c0.6,1.4,0.8,3.1,0.8,5.3c0,0.4,0,1.1-0.1,2
										c-0.1,0.9-0.3,1.9-0.6,3c-0.3,1.1-0.9,2.2-1.6,3.4c-0.7,1.2-1.7,2.2-3,3c-0.9,0.6-1.7,0.9-2.3,1.1c-0.7,0.2-1.2,0.3-1.7,0.5
										c0.2,0,0.5,0.1,0.9,0.3c0.3,0.1,0.6,0.2,0.9,0.3c1.2,0.6,2.1,1.3,2.7,2.2c0.6,0.9,1.1,1.5,1.2,1.8c0.5,1,0.9,2.1,1.1,3.2
										c0.2,1.1,0.4,2.2,0.6,3.3c0.3,1.9,0.6,3.6,0.8,5.1c0.2,1.6,0.7,3.2,1.4,5c0.2,0.4,0.3,0.8,0.5,1.2c0.1,0.4,0.3,0.8,0.6,1.2
										c0.4,0.7,0.8,1.3,1.2,1.9h-12.5c-0.4-0.5-0.7-1-0.9-1.4c-0.6-1-1-2.1-1.3-3.3c-0.3-1.2-0.6-2.9-0.9-5.1c-0.2-1.2-0.4-2.3-0.6-3.5
										c-0.2-1.2-0.6-2.3-1.2-3.3c-0.9-1.5-1.9-2.3-3.3-2.5c-1.3-0.2-2.8-0.3-4.4-0.3c-0.6,0-1.1,0-1.6,0c-0.5,0-1,0-1.6-0.1h-4.2V71z
										 M134.9,41.8h4.1c0.5,0,1.1,0,1.6,0s1.1,0,1.6-0.1c1.3,0,2.6-0.1,4.2-0.2c1.5-0.1,2.9-0.6,4-1.3c0.5-0.4,1.1-1.1,1.7-2
										c0.6-0.9,0.9-2.3,0.9-4c0-0.7-0.1-1.4-0.2-2c-0.1-0.6-0.4-1.2-0.8-1.8c-0.5-0.6-1.1-1.1-1.8-1.5c-0.7-0.3-1.5-0.6-2.3-0.8
										c-0.8-0.1-1.6-0.2-2.4-0.2c-0.8,0-1.6,0-2.3,0h-8.3V41.8z"/>
                                    <path d="M222.5,43.6c0,2.1-0.1,4.2-0.4,6.2c-0.3,2.1-0.8,4-1.5,5.9c-0.7,1.9-1.6,3.7-2.8,5.4c-1.2,1.7-2.6,3.3-4.2,4.7
										c-1.7,1.5-3.4,2.7-5.2,3.5s-3.5,1.4-5,1.8c-1.6,0.4-3,0.6-4.3,0.8c-1.3,0.1-2.4,0.1-3.2,0.1c-3,0-5.7-0.4-8.4-1.2
										c-2.6-0.8-5.1-2.1-7.5-4c-1.8-1.4-3.3-2.9-4.5-4.6c-1.3-1.7-2.3-3.5-3.1-5.5c-0.8-2-1.4-4-1.8-6.1c-0.4-2.1-0.6-4.2-0.6-6.4
										c0-0.8,0-1.9,0.1-3.3c0.1-1.5,0.4-3.1,0.8-5c0.5-1.8,1.1-3.8,2.1-5.8c0.9-2,2.2-3.9,3.9-5.7c0.7-0.8,1.6-1.7,2.7-2.6
										c1.1-0.9,2.5-1.8,4.1-2.6c1.6-0.8,3.4-1.5,5.5-2s4.4-0.8,7.1-0.8c2.3,0,4.4,0.2,6.2,0.6c1.8,0.4,3.4,0.9,4.8,1.5
										c1.4,0.6,2.6,1.3,3.6,2c1,0.7,1.9,1.4,2.7,2c0.9,0.8,1.9,1.7,2.9,2.9s2,2.7,2.9,4.4c0.9,1.7,1.6,3.7,2.2,5.9
										C222.1,38.2,222.4,40.8,222.5,43.6z M210,43.6c-0.1-2.1-0.4-4.2-0.9-6.2c-0.5-2.1-1.4-4-2.7-5.7c-0.4-0.5-0.8-0.9-1.4-1.5
										c-0.6-0.5-1.2-1.1-2.1-1.5c-0.8-0.5-1.8-0.9-2.9-1.2c-1.1-0.3-2.4-0.5-3.9-0.5c-0.6,0-1.5,0.1-2.7,0.2c-1.3,0.2-2.6,0.7-3.9,1.5
										c-0.9,0.6-1.7,1.3-2.5,2.1c-0.8,0.9-1.6,2-2.3,3.3c-0.7,1.3-1.2,2.8-1.7,4.6c-0.4,1.8-0.6,3.8-0.6,6c0,2.3,0.2,4.3,0.7,6
										c0.5,1.8,1,3.3,1.8,4.7c0.7,1.3,1.5,2.4,2.4,3.3c0.9,0.9,1.8,1.5,2.7,2c0.5,0.3,1.4,0.6,2.4,0.9s2.3,0.6,3.9,0.6
										c0.5,0,1.1,0,1.9-0.1s1.6-0.3,2.4-0.6c0.8-0.3,1.7-0.7,2.7-1.3c0.9-0.5,1.8-1.3,2.7-2.3c1.8-2.1,2.9-4.3,3.4-6.7
										C209.9,48.6,210.1,46.1,210,43.6z"/>
                                    <path d="M241.6,71h-12.1V18H247c1.5,0,2.9,0,4.4,0s2.9,0.1,4.4,0.2c0.5,0.1,1.2,0.2,2.1,0.4c0.9,0.2,1.9,0.5,3,0.9
										c1.1,0.5,2.2,1.1,3.4,1.8c1.2,0.8,2.2,1.8,3.1,3.1c0.7,1,1.3,2.3,2,4c0.7,1.7,1.1,4,1.1,6.7c0,0.5,0,1.1-0.1,1.8
										c-0.1,0.8-0.2,1.7-0.4,2.6c-0.2,1-0.5,2-0.9,3.1c-0.4,1.1-1,2.1-1.8,3.2c-0.3,0.4-0.7,0.9-1.2,1.5c-0.6,0.7-1.3,1.3-2.2,2
										c-0.9,0.7-1.9,1.3-3,1.7c-1,0.4-2.2,0.8-3.5,1c-1.3,0.2-2.8,0.4-4.5,0.4c-1.7,0.1-3.7,0.1-5.9,0.1h-5.4V71z M241.6,42.8h7
										c0.8,0,1.7,0,2.5-0.1c0.8,0,1.6-0.2,2.4-0.4c0.7-0.2,1.4-0.5,2.1-0.9c0.6-0.4,1.2-1,1.8-1.8c0.8-1.2,1.3-2.7,1.3-4.4
										c0-0.3,0-0.7-0.1-1.2c0-0.5-0.2-1.1-0.4-1.7c-0.2-0.6-0.6-1.2-1-1.8c-0.5-0.6-1.1-1.1-1.9-1.6c-1.1-0.6-2.4-1-3.9-1.1
										c-1.4-0.1-3.4-0.1-6-0.2h-3.7V42.8z"/>
                                    <path d="M327.3,43.6c0,2.1-0.1,4.2-0.4,6.2c-0.3,2.1-0.8,4-1.5,5.9c-0.7,1.9-1.6,3.7-2.8,5.4c-1.2,1.7-2.6,3.3-4.2,4.7
										c-1.7,1.5-3.4,2.7-5.2,3.5c-1.8,0.8-3.5,1.4-5,1.8c-1.6,0.4-3,0.6-4.3,0.8c-1.3,0.1-2.4,0.1-3.2,0.1c-3,0-5.7-0.4-8.4-1.2
										c-2.6-0.8-5.1-2.1-7.5-4c-1.8-1.4-3.3-2.9-4.5-4.6c-1.3-1.7-2.3-3.5-3.1-5.5c-0.8-2-1.4-4-1.8-6.1c-0.4-2.1-0.6-4.2-0.6-6.4
										c0-0.8,0-1.9,0.2-3.3c0.1-1.5,0.4-3.1,0.8-5c0.5-1.8,1.1-3.8,2.1-5.8s2.2-3.9,3.9-5.7c0.7-0.8,1.6-1.7,2.7-2.6
										c1.1-0.9,2.5-1.8,4.1-2.6c1.6-0.8,3.4-1.5,5.5-2c2.1-0.5,4.4-0.8,7.1-0.8c2.3,0,4.4,0.2,6.2,0.6c1.8,0.4,3.4,0.9,4.8,1.5
										c1.4,0.6,2.6,1.3,3.6,2c1,0.7,1.9,1.4,2.7,2c0.9,0.8,1.9,1.7,2.9,2.9s2,2.7,2.9,4.4c0.9,1.7,1.6,3.7,2.2,5.9S327.2,40.8,327.3,43.6
										z M314.7,43.6c-0.1-2.1-0.4-4.2-0.9-6.2c-0.5-2.1-1.4-4-2.7-5.7c-0.4-0.5-0.8-0.9-1.4-1.5c-0.6-0.5-1.2-1.1-2.1-1.5
										c-0.8-0.5-1.8-0.9-2.9-1.2c-1.1-0.3-2.4-0.5-3.9-0.5c-0.6,0-1.5,0.1-2.7,0.2c-1.3,0.2-2.6,0.7-3.9,1.5c-0.9,0.6-1.7,1.3-2.5,2.1
										c-0.8,0.9-1.6,2-2.3,3.3c-0.7,1.3-1.2,2.8-1.7,4.6c-0.4,1.8-0.6,3.8-0.6,6c0,2.3,0.2,4.3,0.7,6c0.5,1.8,1,3.3,1.8,4.7
										c0.7,1.3,1.5,2.4,2.4,3.3c0.9,0.9,1.8,1.5,2.7,2c0.6,0.3,1.4,0.6,2.4,0.9c1.1,0.4,2.4,0.6,3.9,0.6c0.5,0,1.1,0,1.9-0.1
										c0.8-0.1,1.5-0.3,2.4-0.6c0.8-0.3,1.7-0.7,2.7-1.3c0.9-0.5,1.8-1.3,2.7-2.3c1.8-2.1,2.9-4.3,3.4-6.7
										C314.6,48.6,314.8,46.1,314.7,43.6z"/>
                                    <path d="M364.3,34.3c-0.1-1.2-0.4-2.2-0.6-3.2c-0.3-0.9-0.9-1.8-1.8-2.6c-1.1-0.9-2.3-1.6-3.7-1.8c-1.4-0.3-2.7-0.4-4.1-0.4
										c0,0-0.5,0-1.3,0c-0.8,0-1.8,0.2-2.8,0.5c-1,0.3-2,0.8-2.8,1.6c-0.9,0.8-1.3,1.9-1.3,3.3c0,0.7,0.3,1.5,0.7,2.3
										c0.4,0.8,1.4,1.5,2.8,2.1c1.1,0.5,2.4,0.8,3.9,1.1c1.4,0.3,3.4,0.7,5.8,1.3c2,0.5,3.8,0.9,5.4,1.4c1.5,0.5,2.9,1,4,1.5
										c1.2,0.6,2.2,1.2,3,1.8c0.8,0.7,1.6,1.4,2.3,2.2c0.7,0.9,1.4,2,2.1,3.5c0.7,1.5,1.1,3.5,1.1,6c0,2-0.2,3.7-0.7,5.2
										c-0.5,1.5-1,2.8-1.7,3.9c-0.7,1.1-1.4,2-2.2,2.7c-0.8,0.7-1.5,1.3-2.1,1.8c-1.4,1-2.9,1.7-4.6,2.2c-1.7,0.5-3.3,0.9-4.8,1.1
										c-1.5,0.2-2.9,0.3-4,0.4c-1.1,0-1.9,0-2.2,0c-0.4,0-1,0-2,0c-1,0-2.1-0.1-3.5-0.3c-1.3-0.2-2.7-0.4-4.2-0.8s-2.9-0.9-4.2-1.6
										c-0.4-0.3-1-0.6-1.9-1.2c-0.9-0.5-1.7-1.3-2.6-2.3c-1-1.1-1.8-2.3-2.4-3.5c-0.6-1.2-1-2.3-1.3-3.4c-0.3-1.1-0.5-2.1-0.5-2.9
										c-0.1-0.9-0.1-1.6-0.2-2.1l11.9-0.7c0.1,1.5,0.3,2.7,0.7,3.7s1.2,1.9,2.4,2.8c0.8,0.6,1.8,1.1,3.1,1.6c1.3,0.5,3,0.7,5.2,0.7
										c3.3,0,5.7-0.6,7.2-1.7c1.5-1.1,2.3-2.6,2.3-4.4c0-0.6-0.2-1.4-0.5-2.3c-0.3-0.9-1.2-1.7-2.6-2.4c-0.7-0.3-1.7-0.7-3-1.1
										c-1.3-0.3-2.9-0.8-4.7-1.2l-2.5-0.5c-0.8-0.2-1.7-0.4-2.6-0.6c-0.9-0.2-1.8-0.4-2.6-0.7c-0.9-0.3-2-0.7-3.4-1.2
										c-1.4-0.5-2.7-1.4-4.1-2.5c-1.3-1.1-2.5-2.6-3.5-4.4s-1.5-4.1-1.5-6.8c0-1.7,0.2-3.1,0.6-4.4c0.4-1.3,0.9-2.4,1.5-3.4
										c0.6-1,1.2-1.8,1.9-2.4c0.7-0.6,1.3-1.2,1.8-1.6c1.4-1,3.3-1.9,5.7-2.7c2.5-0.8,5.6-1.2,9.5-1.2c2.8,0,5.3,0.3,7.4,0.8
										c2.1,0.5,3.9,1.2,5.5,2c1.6,0.8,2.8,1.7,3.9,2.8c1,1.1,1.9,2.2,2.5,3.3c0.7,1.3,1.2,2.6,1.5,3.9c0.3,1.3,0.5,2.6,0.7,4.1
										L364.3,34.3z"/>
								</g>
								</svg>
};

export default AboutSvgFR;