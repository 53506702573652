import React, {useContext} from 'react';
import LanguageContext from "../../../context/language-context";
import "./project-inspire.scss";

const ProjectInspire = () => {
    const {projectInspire} = useContext(LanguageContext);
    const {text1, text2, text3, text4, blocks} = projectInspire;

    const simpleText = (text, key) => <p key={`text${key}`}>{text}</p>;

    const strongText = (text, key) => <p key={`strongText${key}`}><strong>{text}</strong></p>;

    const listText = (list, key) => <ul key={`list${key}`}>
        {list.map((innerItem, listIdx) => {
            return <li key={`inner${key}${listIdx}`}>{innerItem}</li>
        })}
    </ul>;

    const imageWithLink = ({link, svg}, key) => <a key={key} href={link} target={link.includes("http") ? "_blank" : "_self"}>{svg}</a>;

    const footer = ({text, imgs}, key) => <div key={`footer${key}`} className="project-inspire__footer">
        {text}
        {imgs &&
        <div className="project-inspire__logos">
            {imgs.map((image, i) => imageWithLink(image, i))}
        </div>}
    </div>;

    const createContent = (item, idx) => {
        const {type, value} = item;

        if (type === 'text')
            return simpleText(value, idx);

        if (type === 'strongText')
            return strongText(value, idx);

        if (type === 'list')
            return listText(value, idx);

        if (type === 'footer')
            return footer(value, idx);

        if (type === 'imgLink')
            return imageWithLink(value, idx);
    };

    const createBlock = (item, idx) => {
        const {delay, header, contents} = item;
        const count = idx + 1;
        if (!contents) return null;
        return <div key={`inspire${idx}`} className="projectsInspireItem w50 w50-mobile aos aos-init project-block" data-aos="fade-up"
                    data-delay={delay} style={{transitionDelay: `${delay}ms`}}>
            <div className="top">
                <h3>{header}</h3>
                <span className="count">{count < 10 ? `0${count}` : count}</span>
            </div>

            <div key={idx} className="text whiteText">
                {contents.map((item, idx) => createContent(item, idx))}
            </div>
        </div>
    };

    return <div className="projectsInspire">
        <div className="container no_padding_top">
            <div className="text mediumWidth aos aos-init" data-aos="fade-up">
                <h3>{text1}<b>{text2}</b>{text3}</h3>
                <p>{text4}</p>
            </div>
            <div className="projectsInspireWrap row">
                {blocks.map((item, idx) => createBlock(item, idx))}
            </div>
        </div>
    </div>;
};

export default ProjectInspire;