import React, {useContext} from 'react';
import LanguageContext from "../../../context/language-context";
import CareersSVG from "../../icon/text_careers";
import CareersSvgFr from "../../icon/text_careers_FR";
// import PbfsLogoLarge from "../../icon/pbfs_logo_large";
import NewSmallLogo from "../../icon/new_small_logo";

const CareersInfo = () => {
    const {language, careersInfoBlock} = useContext(LanguageContext);
    const {text1, text2} = careersInfoBlock;

    return <div className="container no_padding_bottom">
        <div className="titleRow main bigTitle smallMargin alignStart">
            <div className="text moreWidth aos aos-init" data-aos="fade-up" data-delay="0"
                 style={{transitionDelay: '0ms'}}>
                <h2>{text1}</h2>
                <p/>
                <p>
                    {/*<PbfsLogoLarge/>*/}
                    <NewSmallLogo width={120} height={60} fill={"var(--indigo)"}/>
                </p>
                <p>{text2}<br/>
                    &nbsp;&nbsp;</p>
                <p/>
            </div>
            <div className="rightColumn alignTextRight aos aos-init" data-aos="fade-up" data-delay="100"
                 style={{transitionDelay: '100ms'}}>
                <div className="titleSVG noMargin">
                    {language === 'EN'
                        ? <CareersSVG/>
                        : <CareersSvgFr/>}
                </div>
            </div>
        </div>
    </div>
};

export default CareersInfo;

