import React, {useState} from 'react';
import CookieButton from "./CookieButton";
import CookieSettingsModal from "./CookieSettingsModal";
import "./cookie.css";
import {isEmptyCookie} from "./cookie-util";

const Cookies = ({cookie, setCookie, removeCookie}) => {
    const [showModal, setShowModal] = useState(false);

    return <div className="white-text">
        <CookieButton
            showModal={showModal}
            setShowModal={setShowModal}
        />

        {showModal || isEmptyCookie(cookie) ? <CookieSettingsModal
            removeCookie={removeCookie}
            setShowModal={setShowModal}
            cookie={cookie}
            setCookie={setCookie}
        /> : null}
    </div>
};

export default Cookies;

