import React, {useContext} from 'react';
import './contact-block.css';
import ContactSVG from "../../icon/text_contact";
import {Link} from "react-router-dom";
import LanguageContext from "../../../context/language-context";
import AnimateFigure from "../../animate-figure/animate-figure";

const ContactBlock = () => {
    const {language, contactBlock} = useContext(LanguageContext);
    const {text1, text2, text3, text4} = contactBlock;

    return <div className="contactsBlock">
        <div className="wrap">
            <div className="container no_padding_bottom">
                <div className="titleRow">
                    <div className="text moreWidth aos aos-init" data-aos="fade-up" data-delay="0"
                         style={{transitionDelay: '0ms'}}>
                        <h2>{text1}<b>{text2}</b>{text3}
                        </h2>
                    </div>
                    <div className="titleSVG noMargin aos aos-init" data-aos="fade-up" data-delay="100"
                         style={{transitionDelay: '100ms'}}>
                        <ContactSVG/>

                        <Link to={language === 'EN' ? '/contact' : '/fr/contact'} className='line_hover type2 arrow bigPadding'>
                            {text4}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="contactLottie lottie shape animated contact-block" style={{visibility: 'visible'}}>
                <AnimateFigure
                    height={70}
                />
            </div>
        </div>
    </div>
};

export default ContactBlock;