import React, {useContext, useEffect, useState} from 'react';
import banner from '../../../png/Careers-page3-1200x501.png';
import {Link} from "react-router-dom";
import LanguageContext from "../../../context/language-context";
import './opened-positions.css';
import ValidLabelInput from "../../valid-label-input/valid-label-input";
import {HttpServiceContext} from "../../../context";
import FilterBlock from "./filter-block";

const OpenedPositions = () => {
    const [showedPositions, setShowedPositions] = useState([]);
    const [typedEmail, setTypedEmail] = useState('');
    const [typedName, setTypedName] = useState('');
    const [typedSecondName, setTypedSecondName] = useState('');
    const [typedPhone, setTypedPhone] = useState('');
    const [typedMessage, setTypedMessage] = useState('');
    const [file, setFile] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [agreeError, setAgreeError] = useState(false);
    const [hasErrors, setHasErrors] = useState({});
    const [filterByFunction, setFilterByFunction] = useState('');
    const [filterByProject, setFilterByProject] = useState('');
    const [filterByDirection, setFilterByDirection] = useState('');
    const [mobShowDrop, setMobShowDrop] = useState(false);
    const [successfullySent, setSuccessfullySent] = useState(false);

    const httpService = useContext(HttpServiceContext);
    const {careersPositions, contactForm, careerPage, errorMessages} = useContext(LanguageContext);
    const {title, email, firstName, secondName, phone, message, cv, terms1, terms2, sendMail, termsLink} = contactForm;
    const {openedPosition, filters, clearFilter, filterOpenedPositionText} = careerPage;

    useEffect(() => {
        setHasErrors({});
    }, [contactForm]);

    const checkFilters = (functions, project, direction) => {
        if (filterByFunction.length && filterByFunction !== functions) {
            return false;
        }

        if (filterByProject.length && filterByProject !== project) {
            return false;
        }

        return !(filterByDirection.length && filterByDirection !== direction);
    };

    const createPositionBlock = (item, idx) => {
        const {
            delay, positionName, chanceOfLifetime, requirements, responsibilities, willBePlus,
            functions, project, direction, place
        } = item;

        if (!checkFilters(functions, project, direction)) {
            return;
        }

        const openStyle = (showedPositions.includes(idx) ? {transform: 'translateY(-50%) rotate(0)'} : {});

        return <div key={`position${idx}`}
                    className='accordion aos aos-init'
                    data-aos="fade-up"
                    data-delay={delay}
                    style={{transitionDelay: `${delay}ms`}}>
            <div className="top">
                <div>
                    <h5>{positionName}</h5>
                    <p>{place}</p>
                </div>
                <div
                    className="arrow"
                    style={openStyle}
                    onClick={() => {
                        setShowedPositions(showedPositions => {
                            const newShowedPosition = [...showedPositions];
                            const foundIdx = newShowedPosition.indexOf(idx);

                            if (foundIdx !== -1) {
                                newShowedPosition.splice(foundIdx, 1);
                            } else {
                                newShowedPosition.push(idx);
                            }

                            return newShowedPosition;
                        })
                    }}
                />

            </div>
            <div className="content" style={showedPositions.includes(idx) ? {display: 'block'} : {}}>
                <div className="banner">
                    <img className="bg lazy" alt=""
                         src={banner}
                         style={{display: 'block', visibility: 'visible', opacity: 1}}/>
                </div>
                <div className="row margin0">
                    <div className="leftColumn text">
                        <h3>Chance of a lifetime</h3>
                        <p className="wordsection1" style={{margin: '0in', textAlign: 'justify'}}>
                            {chanceOfLifetime}
                        </p>
                        <h5>Responsibilities</h5>
                        <ul>
                            {responsibilities.map((item, idx) => {
                                return <li key={`responsibility${idx}`}>{item}</li>
                            })}
                        </ul>
                        <h5>Requirements</h5>
                        <ul>
                            {requirements.map((item, idx) => {
                                return <li key={`requirement${idx}`}>{item}</li>
                            })}
                        </ul>
                        {willBePlus.length
                            ? <p>Will be counted as a plus:</p>
                            : null
                        }

                        {willBePlus.length
                            ? <ul>
                                {willBePlus.map((item, idx) => {
                                    return <li key={`willBePlus${idx}`}>{item}</li>
                                })}
                            </ul>
                            : null
                        }

                    </div>

                    <div className="rightColumn">
                        <h3>{title}</h3>
                        <div className="contactForm careerForm">
                            <div role="form" className="wpcf7" id="wpcf7-f497-o1" lang="en-US" dir="ltr">
                                <div className="screen-reader-response"/>
                                <div>
                                    <p>
                                        <ValidLabelInput
                                            labelText={email}
                                            inputType={"email"}
                                            inputSize={"40"}
                                            value={typedEmail}
                                            setValue={setTypedEmail}
                                            addErrors={setHasErrors}
                                        />
                                        <ValidLabelInput
                                            labelText={firstName}
                                            inputType={"text"}
                                            inputSize={"40"}
                                            value={typedName}
                                            setValue={setTypedName}
                                            addErrors={setHasErrors}
                                        />
                                        <ValidLabelInput
                                            labelText={secondName}
                                            inputType={"text"}
                                            inputSize={"40"}
                                            value={typedSecondName}
                                            setValue={setTypedSecondName}
                                            addErrors={setHasErrors}
                                        />
                                        <ValidLabelInput
                                            labelText={phone}
                                            inputType={"tel"}
                                            inputSize={"40"}
                                            maxLength={"17"}
                                            value={typedPhone}
                                            setValue={setTypedPhone}
                                            addErrors={setHasErrors}
                                        />
                                        <label className="with_line textarea">
                                            <span className="hover_text">
                                                {message}
                                            </span>
                                            <span>
                                                <textarea onChange={(e) => setTypedMessage(e.target.value)}
                                                          cols="40"
                                                          rows="10"/>
                                            </span>
                                        </label>
                                        <label className="file attatchFile">
                                            <span>
                                                {cv}
                                            </span>
                                            <span>
                                                <input onChange={(e) => setFile(e.target.files[0])}
                                                       type="file"
                                                       name="file-344"
                                                       size="40"
                                                       accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.txt,.ppt,.pptx,.odt,.avi,.ogg,.m4a,.mov,.mp3,.mp4,.mpg,.wav,.wmv"/>
                                            </span>
                                        </label>
                                        <br/>
                                        <label>
                                            <span>
                                                <span>
                                                    <span>
                                                        <label>
                                                            <input onChange={() => setAgreeTerms((agreeTerms) => {
                                                                return !agreeTerms
                                                            })}
                                                                   type="checkbox"
                                                                   name="acceptance-737"
                                                                   value="1"
                                                                   aria-invalid="false"/>
                                                                                      <span>
                                                                                          {terms1}
                                                                                          <Link to={termsLink}>
                                                                                                {terms2}
                                                                                            </Link>
                                                                                      </span>
                                                            {
                                                                agreeError
                                                                    ? <div
                                                                        className='terms-alert'>{errorMessages.terms}</div>
                                                                    : null
                                                            }

                                                            {
                                                                successfullySent
                                                                    ? <div
                                                                        className='terms-alert'>{errorMessages.successfully}</div>
                                                                    : null
                                                            }
                                                        </label>
                                                    </span>
                                                </span>
                                            </span>
                                        </label>
                                    </p>
                                    <div className="bottomRow">
                                        <button onClick={() => {
                                            if (Object.values(hasErrors).includes(true)) {
                                                return;
                                            }

                                            if (!agreeTerms) {
                                                setAgreeError(true);
                                                return;
                                            }

                                            const formData = new FormData();
                                            formData.append('file', file);
                                            formData.append('email', typedEmail);
                                            formData.append('name', typedName);
                                            formData.append('secondName', typedSecondName);
                                            formData.append('phoneNumber', typedPhone);
                                            formData.append('message', typedMessage);
                                            formData.append('subject', 'New CV');
                                            formData.append('positionName', positionName);

                                            httpService.postRequest('/mail', formData)
                                                .then(() => {
                                                    setTypedEmail("");
                                                    setTypedName("");
                                                    setTypedSecondName("");
                                                    setTypedPhone("");
                                                    setSuccessfullySent(true);
                                                    setHasErrors({
                                                        [email.replace(/\s/g, '')]: true,
                                                        [firstName.replace(/\s/g, '')]: true,
                                                        [secondName.replace(/\s/g, '')]: true,
                                                        [phone.replace(/\s/g, '')]: true
                                                    });
                                                })
                                                .catch(e => {
                                                    setSuccessfullySent(false);
                                                });
                                            setAgreeError(false);
                                        }}
                                                type="submit"
                                                className="line_hover type2 arrow">
                                            {sendMail}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    };

    const getFilterRows = (item) => {
        const activeFilters = [];

        careersPositions.forEach(item => {
            const {functions, project, direction} = item;
            if (checkFilters(functions, project, direction)) {
                activeFilters.push(functions);
                activeFilters.push(project);
                activeFilters.push(direction);
            }
        });

        switch (item) {
            case 'Job Function':
                return {
                    setFilter: setFilterByFunction,
                    data: Array.from(new Set(careersPositions.map(item => item.functions)))
                        .filter(item => activeFilters.includes(item)),
                    selected: filterByFunction
                };
            case 'Project':
                return {
                    setFilter: setFilterByProject,
                    data: Array.from(new Set(careersPositions.map(item => item.project)))
                        .filter(item => activeFilters.includes(item)),
                    selected: filterByProject
                };
            default:
                return {
                    setFilter: setFilterByDirection,
                    data: Array.from(new Set(careersPositions.map(item => item.direction)))
                        .filter(item => activeFilters.includes(item)),
                    selected: filterByDirection
                };

        }
    };

    const countOpenedPosition = () => {
        return careersPositions.filter(item => (filterByFunction.length === 0 || item.functions === filterByFunction))
            .filter(item => (filterByDirection.length === 0 || item.direction === filterByDirection))
            .filter(item => (filterByProject.length === 0 || item.project === filterByProject))
            .length
    };

    const createClearBlock = () => {
        return filterByFunction.length || filterByProject.length || filterByDirection
            ? <div onClick={() => {
                setFilterByFunction('');
                setFilterByProject('');
                setFilterByDirection('');
            }}
                   className="clear_filter clear_filter_2">
                {clearFilter}
            </div>
            : null
    };

    return <div className="positionsWrap">
        <div className="container">
            <div className="positionsTitleRow row margin0 between alignCenter aos aos-init"
                 data-aos="fade-up">
                <h4>{openedPosition} (<span className="counts_item">{countOpenedPosition()}</span>)</h4>
                <div className="filtersWrap desktopOnly">

                    {createClearBlock()}

                    {filters.map((item, idx) => <FilterBlock
                        key={'filer' + idx}
                        item={item}
                        filter={getFilterRows(item)}
                    />)}
                </div>
                <div className="padding_top"/>
                <div className="fiil_hide">
                    <div className="filterPosiiton custom_dropdown borderMobile mobileOnly">
                        {
                            !mobShowDrop
                                ? <div onClick={() => setMobShowDrop((mobShowDrop) => {
                                    return !mobShowDrop
                                })}
                                       className="top">
                                    <div className="filterItem">
                                        <span>{filterOpenedPositionText}</span>
                                        <div className="arrow"/>
                                    </div>
                                </div>
                                : null
                        }

                        {mobShowDrop
                            ? <ul style={{display: 'block'}}>
                                <li onClick={() => setMobShowDrop((mobShowDrop) => {
                                    return !mobShowDrop
                                })}
                                    className="hiddenTop">
                                    <span>{filterOpenedPositionText}</span>
                                    <div className="close"/>
                                </li>
                                {filters.map((item, idx) => <FilterBlock
                                    key={'filer' + idx}
                                    item={item}
                                    filter={getFilterRows(item)}
                                />)}
                            </ul>
                            : null}
                    </div>

                    {createClearBlock()}
                </div>
            </div>
            <div className="accordionsWrap">
                {careersPositions.map((item, idx) => createPositionBlock(item, idx))}
            </div>
        </div>
    </div>
};

export default OpenedPositions;