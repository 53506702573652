import React, {useContext} from 'react';
import NotFoundIcon from "../icon/404";
import LanguageContext from "../../context/language-context";
import {Link} from "react-router-dom";
import {about2} from "../../jpg";

const NotFound = () => {
    const {notFoundPage} = useContext(LanguageContext);
    const {head1, head2, text, link, linkText} = notFoundPage;

    return <div className="wrapper page404" data-name="">
        <div className="container no_padding_bottom">
            <div className="titleRow main bigTitle alignStart">
                <div className="text moreWidth aos aos-init" data-aos="fade-up" data-delay="0"
                     style={{transitionDelay: '0ms'}}>
                    <h1>{head1} <b>{head2}</b></h1>
                    <p>{text}</p>
                    <Link to={link} className="line_hover type2 arrow">
                        {linkText}
                    </Link>
                </div>
                <div className="rightColumn alignTextRight aos aos-init" data-aos="fade-up" data-delay="100"
                     style={{transitionDelay: '100ms'}}>
                    <div className="titleSVG noMargin">
                       <NotFoundIcon/>
                    </div>
                </div>
            </div>
        </div>
        <div className="articleBanner aos aos-init" data-aos="fade-up">
            <img src={about2} alt="404"/>
        </div>
    </div>
};

export default NotFound;

