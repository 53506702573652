import React from 'react';

const CareersSVG = () => {
    return <svg width="447" height="79" xmlns="http://www.w3.org/2000/svg"
                x="0px" y="0px" viewBox="0 0 256.2 43.8"
                style={{'"enableBackground:': 'new 0 0 256.2 43.8'}} xmlSpace="preserve">
        <g>
            <path d="M35.1,29c-0.2,2.2-0.6,4.2-1.5,6s-2,3.4-3.4,4.7c-1.4,1.3-3.2,2.3-5.3,3c-2.1,0.7-4.4,1.1-7,1.1c-2.7,0-5.2-0.5-7.4-1.4
		c-2.2-0.9-4-2.3-5.5-4.1c-1.5-1.8-2.7-3.9-3.5-6.5c-0.8-2.5-1.2-5.4-1.2-8.5v-2.8c0-3.1,0.4-6,1.3-8.5c0.8-2.5,2-4.7,3.6-6.5
		c1.5-1.8,3.4-3.1,5.6-4.1C12.9,0.5,15.3,0,18,0c2.7,0,5,0.4,7,1.1c2,0.8,3.8,1.8,5.2,3.1c1.4,1.3,2.5,2.9,3.3,4.8
		c0.8,1.8,1.3,3.9,1.5,6h-8.8c-0.1-1.3-0.3-2.4-0.7-3.4c-0.3-1-0.8-1.8-1.5-2.5c-0.6-0.7-1.5-1.2-2.5-1.5c-1-0.3-2.2-0.5-3.7-0.5
		c-3,0-5.2,1.1-6.6,3.2c-1.5,2.2-2.2,5.5-2.2,10v2.9c0,2.2,0.2,4.1,0.5,5.8c0.3,1.7,0.8,3,1.5,4.2c0.7,1.1,1.6,1.9,2.7,2.5
		c1.1,0.6,2.4,0.8,4,0.8c1.4,0,2.6-0.2,3.6-0.5c1-0.3,1.8-0.8,2.5-1.4s1.2-1.4,1.6-2.4c0.4-1,0.6-2.1,0.7-3.3H35.1z"/>
            <path
                d="M64.9,34.5H49.5l-2.9,8.8h-9.3L53.1,0.6h8.1l16,42.7h-9.3L64.9,34.5z M51.9,27.3h10.7l-5.4-16L51.9,27.3z"/>
            <path d="M97,27.6h-7v15.6h-8.8V0.6H97c2.5,0,4.7,0.3,6.6,0.8c2,0.6,3.6,1.4,5,2.5s2.4,2.4,3.1,4c0.7,1.6,1.1,3.5,1.1,5.6
		c0,1.6-0.2,3-0.5,4.2c-0.4,1.2-0.9,2.3-1.5,3.3c-0.7,1-1.5,1.8-2.4,2.5c-0.9,0.7-2,1.3-3.2,1.9l9.2,17.4v0.4H105L97,27.6z M90,20.5
		h7.1c1.2,0,2.2-0.2,3-0.5c0.9-0.3,1.6-0.7,2.2-1.3c0.6-0.6,1-1.2,1.3-2c0.3-0.8,0.4-1.6,0.4-2.6c0-2-0.6-3.6-1.7-4.7
		c-1.1-1.2-2.9-1.7-5.3-1.7H90V20.5z"/>
            <path d="M145.2,24.8h-16.9v11.4h19.8v7.1h-28.6V0.6H148v7.1h-19.7v10.2h16.9V24.8z"/>
            <path d="M178.9,24.8H162v11.4h19.8v7.1h-28.6V0.6h28.5v7.1H162v10.2h16.9V24.8z"/>
            <path d="M202.8,27.6h-7v15.6H187V0.6h15.8c2.5,0,4.7,0.3,6.6,0.8s3.6,1.4,5,2.5s2.4,2.4,3.1,4c0.7,1.6,1.1,3.5,1.1,5.6
		c0,1.6-0.2,3-0.5,4.2c-0.4,1.2-0.9,2.3-1.5,3.3s-1.5,1.8-2.4,2.5c-0.9,0.7-2,1.3-3.2,1.9l9.2,17.4v0.4h-9.4L202.8,27.6z
		 M195.8,20.5h7.1c1.2,0,2.2-0.2,3-0.5c0.9-0.3,1.6-0.7,2.2-1.3s1-1.2,1.3-2c0.3-0.8,0.4-1.6,0.4-2.6c0-2-0.6-3.6-1.7-4.7
		c-1.1-1.2-2.9-1.7-5.3-1.7h-7.1V20.5z"/>
            <path d="M247.4,32.1c0-0.7-0.1-1.4-0.3-2c-0.2-0.6-0.6-1.1-1.2-1.7c-0.6-0.5-1.5-1-2.5-1.5c-1.1-0.5-2.5-1-4.2-1.6
		c-2-0.6-3.8-1.3-5.5-2.1c-1.7-0.8-3.2-1.7-4.5-2.8c-1.3-1.1-2.3-2.3-3.1-3.7c-0.8-1.4-1.1-3-1.1-4.9c0-1.8,0.4-3.4,1.2-4.9
		c0.8-1.5,1.9-2.7,3.3-3.8c1.4-1,3.1-1.8,5-2.4c1.9-0.6,4-0.9,6.3-0.9c2.4,0,4.5,0.3,6.4,1c1.9,0.7,3.6,1.6,5,2.7s2.4,2.6,3.2,4.2
		s1.1,3.4,1.1,5.3h-8.8c0-0.9-0.1-1.7-0.4-2.5s-0.7-1.4-1.3-2c-0.6-0.5-1.3-1-2.2-1.3c-0.9-0.3-1.9-0.5-3.1-0.5
		c-1.2,0-2.2,0.1-3,0.4c-0.8,0.3-1.6,0.6-2.1,1.1c-0.6,0.4-1,1-1.3,1.6c-0.3,0.6-0.4,1.3-0.4,1.9c0,1.4,0.7,2.6,2.2,3.5
		c1.4,0.9,3.5,1.8,6.3,2.7c2.3,0.7,4.4,1.5,6.1,2.4s3.2,1.9,4.4,3s2.1,2.4,2.7,3.8c0.6,1.4,0.9,3,0.9,4.7c0,1.9-0.4,3.5-1.1,5
		s-1.8,2.7-3.1,3.7s-3,1.8-4.9,2.3s-4,0.8-6.4,0.8c-1.4,0-2.8-0.1-4.2-0.4s-2.7-0.6-4-1.1s-2.5-1.1-3.6-1.9s-2-1.7-2.9-2.7
		s-1.4-2.2-1.9-3.5s-0.7-2.8-0.7-4.4h8.8c0,1.3,0.2,2.4,0.6,3.3s1,1.6,1.7,2.2s1.6,1,2.7,1.2s2.2,0.4,3.5,0.4c1.1,0,2.1-0.1,3-0.4
		s1.5-0.6,2.1-1s1-0.9,1.2-1.5S247.4,32.7,247.4,32.1z"/>
        </g>
    </svg>
};

export default CareersSVG;