import React from 'react';

const OvalWithCircle = () => {
    return <svg className="mouse" width="26" height="26" viewBox="0 0 26 26" fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.8368 0.585693C8.24164 0.585693 4.50342 4.32237 4.50342 8.91499V17.2569C4.50342 21.8494 8.24164 25.5857 12.8368 25.5857C17.4319 25.5857 21.1701 21.8495 21.1701 17.2569V8.91499C21.1701 4.32237 17.4317 0.585693 12.8368 0.585693ZM20.2442 17.2569C20.2442 21.3389 16.9212 24.6598 12.8368 24.6598C8.7523 24.6598 5.42933 21.3389 5.42933 17.2569V8.91499C5.42933 4.83292 8.7523 1.51161 12.8368 1.51161C16.9212 1.51161 20.2442 4.8328 20.2442 8.91499V17.2569Z"
            fill="#F1702E"/>
        <circle cx="13" cy="9" r="2" fill="#F1702E"/>
    </svg>
};

export default OvalWithCircle;