import React from 'react';

const AboutBanner = ({aboutBanner}) => {
    return <div className="articleBanner aos aos-init" data-aos="fade-up">
        <img className="lazy" alt="about" src={aboutBanner}
             style={{display: 'block', visibility: 'visible', opacity: 1}}/>
    </div>;
};

export default AboutBanner;

