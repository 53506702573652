import React, {useContext} from 'react';
import AboutInfo from "./about-info/about-info";
import './about.css'
import AboutBanner from "./about-banner/about-banner";
import CoreValueBlock from "./core-value-block/core-value-block";
import EthosBlock from "./ethos-block/ethos-block";
import ProjectInspire from "./project-inspire/project-inspire";
import TimeLineVision from "./time-line-vision/time-line-vision";
import CareersBlock from "../home/careers-block/careers-block";
import ContactBlock from "../home/contact-block/contact-block";
import LanguageContext from "../../context/language-context";
import AboutSVG from "../icon/text_about";
import AboutSvgFR from "../icon/text_about_FR";
import aboutBanner from '../../jpg/ABOUT2-scaled.jpg';
import withCookie from "../../hoc/withCookie";

const About = () => {
    const {language, aboutPageInfo, aboutPageCoreValue} = useContext(LanguageContext);
    const {text1, text2, text3, text4} = aboutPageInfo;
    const {text1: coreText1, text2: coreText2, textList} = aboutPageCoreValue;

    return <div className="wrapper aboutPage">
        <AboutInfo
            language={language}
            text1={text1}
            text2={text2}
            text3={text3}
            subtitle={text4}
            aboutSVG={<AboutSVG/>}
            aboutSvgFr={<AboutSvgFR/>}
        />
        <AboutBanner aboutBanner={aboutBanner}/>
        <CoreValueBlock text1={coreText1} text2={coreText2} textList={textList}/>
        <EthosBlock/>
        <ProjectInspire/>
        <TimeLineVision/>
        <CareersBlock/>
        <ContactBlock/>
    </div>
};

export default withCookie(About);