import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCookie} from "@fortawesome/free-solid-svg-icons";

const CookieButton = ({showModal, setShowModal}) => {
    if (showModal)
        return null;

    return <div className="cookie-button color-text cursor">
        <FontAwesomeIcon icon={faCookie} onClick={() => setShowModal(true)}/>
    </div>
};

export default CookieButton;

