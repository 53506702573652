import {
    augmented,
    augmentedRealityBanner,
    augmentedRealitySlide1,
    augmentedRealitySlide2,
    augmentedRealitySlide3,
    biopharmaBanner,
    biopharmaSlide1,
    biopharmaSlide2,
    biopharmaSlide3,
    dataEcosystemBanner,
    dataEcosystemSlide1,
    dataEcosystemSlide2,
    dataEcosystemSlide3,
    edTech,
    edTechBanner,
    edTechSlide1,
    edTechSlide2,
    edTechSlide3,
    education,
    energyBanner,
    energySlide1,
    energySlide2,
    energySlide3,
    fintechBanner,
    fintechSlide1,
    fintechSlide2,
    fintechSlide3,
    foodSecurity,
    gamingBanner,
    gamingSlide1,
    gamingSlide2,
    gamingSlide3,
    healthProblems,
    humanitarian,
    neuromathematicksBanner,
    neuromathematicksSlide1,
    neuromathematicksSlide2,
    neuromathematicksSlide3,
    planetProtection,
    vehilesBanner,
    vehilesSlide1,
    vehilesSlide2,
    vehilesSlide3
} from "../jpg";
import React from "react";
import DirectionsSVG from "../component/icon/text_directions";
import {ReactComponent as SicticLogoWhite} from "../svg/SICTIC_logo_white.svg";
import {ReactComponent as SecaLogo} from "../svg/Seca_logo.svg";
import {ReactComponent as FoundationLight} from "../svg/p-foundation-light.svg";

import ViewCareer from "../component/icon/view_career";
import NextDirection from "../component/icon/next_direction";
import DirectionsSvgFr from "../component/icon/text_directions_FR";
import ViewCareerFr from "../component/icon/view_career_FR";

const careerPositions = () => {
    return [
        {
            delay: '100',
            positionName: 'Position name: Network Engineer',
            place: 'Geneva, Switzerland',
            shortPositionMane: 'Network Engineer',
            chanceOfLifetime: 'Our team develops an application based on artificial intelligence (AI) for technical and fundamental analysis, big data mining and natural language processing. We offer a competitive salary, challenging tasks, participation in professional conferences/seminars, friendly teammates, a great office in the city centre.',
            responsibilities: [
                'Construction, monitoring and support of network infrastructure',
                'Configuring routing rules, firewall rules, building VPN channels (CISCO)',
                'Ensuring network security',
                'Execution of planned tasks to check fault tolerance',
                'Management of backup and recovery processes',
            ],
            requirements: [
                'Higher technical education (Computer Science, Mathematics, etc.)',
                'Professional experience of at least 5 years',
                'Experience in configuration and construction of fault-tolerant networks based on CISCO (ASA, ISR, Catalyst)',
                'GRE, VLAN, LACP, ACL, IPSEC VPN, VRRP, OSPF, BGP',
                'Experience working with Linux / UNIX (CentOS) family of systems',
                'Practical experience with network management / monitoring / access / system analysis',
                'Experience with software and hardware firewalls',
                'Experience with virtualization technologies (VmWare)',
                'Punctual, responsible, with a great desire to work and study',
            ],
            willBePlus: [
                'Experience in setting up and working with Atlassian products (Jira \\ Sevice Desk \\ Confluencz \\ Bitbucket)',
                'Experience with IP telephony (FreeBPX, CISCO)',
                'Experience with databases, creation of fault-tolerant systems (Postgresql)',
                'GIT code management (Bitbucket)',
            ],
            functions: 'Network engineer',
            project: 'IT team',
            direction: 'General Administration'
        },
        {
            delay: '150',
            positionName: 'Position name: System Administrator',
            place: 'Geneva, Switzerland',
            shortPositionMane: 'System Administrator',
            chanceOfLifetime: 'Our team develops an application based on artificial intelligence (AI) for technical and fundamental analysis, big data mining and natural language processing. We offer a competitive salary, challenging tasks, friendly teammates, a great office in the city centre.',
            responsibilities: [
                'User support',
                'Administration of workstations and network infrastructure',
                'Maintenance of networks, servers and office equipment',
                'Writing instructions for users',
                'Handling purchase process for office equipment',
            ],
            requirements: [
                'Higher technical education degree',
                'At least 1 year of professional experience as a system administrator',
                'Windows and Linux \\ UNIX \\ similar systems, networking technologies',
                'Punctual, responsible, with a great desire to work and study',
            ],
            willBePlus: [],
            functions: 'System Administrator',
            project: 'IT team',
            direction: 'General Administration'
        },
        {
            delay: '200',
            positionName: 'Position name: Physicist',
            place: 'Geneva, Switzerland',
            shortPositionMane: 'Physicist',
            chanceOfLifetime: 'We’re looking for a Physicist to lead a project of alternative energy generation and storage capacities development. We offer a competitive salary, challenging tasks, young and friendly teammates, a great office in the city centre.',
            responsibilities: [
                'The main responsibility at the current stage is to perform research in the field of alternative energy generation sources.',
            ],
            requirements: [
                'At least Master Degree in Physics, Electrical Engineering, or a relevant field of study',
                'Hands-on experience in laboratory, manufacturing, and/or field service environments',
                'Ability to efficiently and effectively self-manage all stages of a project',
                'Experience in using computer simulations to guide and confirm test results',
            ],
            willBePlus: [],
            functions: 'Physicist',
            project: 'Ventures',
            direction: 'Energy Generation and Storage'
        },
        {
            delay: '250',
            positionName: 'Position name: Senior Java Developer',
            place: 'Geneva, Switzerland',
            shortPositionMane: 'Senior Java Developer',
            chanceOfLifetime: 'Our team develops an application based on artificial intelligence (AI) for technical and fundamental analysis, big data mining and natural language processing. We offer a competitive salary, challenging tasks, young and friendly teammates, a great office in the city centre.',
            responsibilities: [
                'Web application implementation and refactoring',
                'Performance testing and optimization',
                'Scaling and containerization',
                'CI\\CD',
                'Unit testing and documenting of developed code',
                'Communication with BA and stakeholders',
                'Proactive position in solution development',
            ],
            requirements: [
                'At least Bachelor Degree in Computer Science, Mathematics or related field',
                'Strong Java core knowledge',
                'Spring Framework',
                'Web app scaling, load balancing experience',
                'React experience',
                'Familiar with OWASP top 10',
                'Docker experience',
                'Cloud provider (AWS, Azure) experience',
            ],
            willBePlus: [],
            functions: 'Java Developer',
            project: 'Research, analysis and valuation',
            direction: 'Fintech'
        },
        {
            delay: '300',
            positionName: 'Position name: Data Scientist',
            place: 'Geneva, Switzerland',
            shortPositionMane: 'Data Scientist',
            chanceOfLifetime: 'Our team develops an application based on artificial intelligence (AI) for technical and fundamental analysis, big data mining and natural language processing. We offer a competitive salary, challenging tasks, young and friendly teammates, a great office in the city centre.',
            responsibilities: [
                'Analysis of the market data to test hypotheses and extract insights',
                'Designing, development, testing and maintaining of the trading strategies as well as enhancement of the existing ones',
            ],
            requirements: [
                'At least Bachelor Degree in Computer Science, Mathematics or related field',
                'Experience in time series analysis (our main research domain is commodity futures)',
                'R or Python development experience',
                'Experience with algorithm development',
                'Capable of documenting and explaining complex tasks and ideas',
                'Deep understanding of modern ML techniques: supervised/unsupervised/semi-supervised learning; participated in at least one project where ML is used in production',
                'Experience with the data visualization in shiny/plotly/matplotlib',
                'A good team player, punctual, responsible, with a great desire to work and study',
            ],
            willBePlus: [],
            functions: 'Data Scientist',
            project: 'Research, analysis and valuation',
            direction: 'Fintech'
        },
        {
            delay: '350',
            positionName: 'Position name: Data Scientist',
            place: 'Geneva, Switzerland',
            shortPositionMane: 'Senior Data Scientist /Lead',
            chanceOfLifetime: 'Our team develops an application based on artificial intelligence (AI) for technical and fundamental analysis, big data mining and natural language processing. We offer a competitive salary, challenging tasks, young and friendly teammates, a great office in the city centre.',
            responsibilities: [
                'Design, implement and optimize machine learning models for investment decision-making based on public news, financial reports, quotes etc',
                'Build an automated financial report generation system',
                'Write and document production-ready Python code that is easy to support',
                'Lead a small team of junior data scientists (2 people)',
            ],
            requirements: [
                'At least Master’s Degree in Computer Science, Mathematics or related field',
                'Work experience in Data Science – minimum 4 years. Includes relevant experience in programming, time series analysis, and writing pipelines',
                'Python development experience',
                'Deep understanding of modern ML techniques',
                'Familiarity with at least one Deep Learning framework',
                'Ability to perform an independent iterative scientific research',
                'Experience with software engineering best practices (e.g., unit testing, code reviews, design documentation)',
                'Punctual, responsible, with a great desire to work and study',
            ],
            willBePlus: [],
            functions: 'Data Scientist',
            project: 'Research, analysis and valuation',
            direction: 'Fintech'
        },
        {
            delay: '400',
            positionName: 'Position name: Aerodynamics Engineer',
            place: 'Geneva, Switzerland',
            shortPositionMane: 'Aerodynamics Engineer',
            chanceOfLifetime: 'We’re looking for a Aerodynamics Engineer to join our team to work on a bunch of challenging projects designed to decrease the human footprint on the climate change and contribute to sustainable future.',
            responsibilities: [
                'Develop the design of an aerodynamic device',
                'Provide simulation of the device',
                'Work with engineering, designers, suppliers, and program management to execute efficient aerodynamic designs and ensure optimal aerodynamic performance',
                'Document and present findings to cross-functional teams and management',
            ],
            requirements: [
                'Degree in Aerodynamics, Aerospace Engineering, Mechanical Engineering or related field',
                'Expert understanding of fluid dynamics',
                'Experience in aerodynamic devices simulation (e.g. COMSOL, ANSYS, ADAMS)',
                'Hands-on experience with prototyping of aerodynamic devices',
                'Familiarity with geometry creation, simplification and data management within CAD software',
                'Passion for sustainable energy and related technologies'
            ],
            willBePlus: [],
            functions: 'Engineer',
            project: 'Ventures',
            direction: 'Aerodynamics'
        },
        {
            delay: '450',
            positionName: 'Position name: Acoustic Engineer',
            place: 'Geneva, Switzerland',
            shortPositionMane: 'Acoustic Engineer',
            chanceOfLifetime: 'We’re looking for a Acoustic Engineer to join our team to work on a bunch of challenging projects designed to decrease the human footprint on the climate change and contribute to sustainable future.',
            responsibilities: [
                'Develop the design of acoustic device',
                'Support designs with acoustic analysis and calculations',
                'Provide simulation of the device',
                'Prepare mechanical drawings (2D/3D-CAD)',
                'Conduct in-house tests of materials and assemblies',
                'Prepare technical documentation such as reports, analysis, test procedures and installation instructions',

            ],
            requirements: [
                'Degree in Acoustic Engineering, Mechanical Engineering or relevant field',
                'Profound knowledge of the principles of sound and vibration',
                'Familiarity with geometry creation, simplification and data management within CAD software',
                'Experience with acoustic simulation environments (e.g. ANSYS, COMSOL)',
                'Hands-on experience with prototyping of acoustic devices and/or systems',
                'Familiarity with piezoelectric materials and associated applications is a plus',
                'Proactive team player with excellent written and verbal communication skills in English, Russian (spoken) is a plus',
                'Must show high initiative, be self-motivated, and have ability to act independently on technical matters, manage time effectively',
            ],
            willBePlus: [],
            functions: 'Engineer',
            project: 'Ventures',
            direction: 'Acoustics'
        },
        {
            delay: '500',
            positionName: 'Position name: Biochemical Engineer',
            place: 'Geneva, Switzerland',
            shortPositionMane: 'Biochemical Engineer',
            chanceOfLifetime: 'In this role you will be part of a multidisciplinary team involved in the development and lifecycle of new innovative product that is designed to decrease the human footprint on the climate change and contribute to sustainable future.',
            responsibilities: [
                'Take a lead of an innovative product development including defining, testing, and optimizing key product metrics',
                'Design and develop the prototypes and marketable products',
                'Lead studies to examine process sequences',
                'Collaborate with manufacturing to prepare product specification or safety sheets, standard operating procedures, user manuals, or qualification and validation reports',
            ],
            requirements: [
                'MSc or Ph.D. in Chemistry, Biology, Chemical Engineering, Biochemistry or another relevant field',
                'Experience in lab work, biocatalysis and fermentation technology',
                'A strong knowledge of decomposition of organic matter is a plus',
                'Committed to building a technology from the ground up',
                'Ability to work and collaborate in an interdisciplinary, fast-paced and challenging environment',
                'Strong problem-solving, analytical, mathematical, and scientific skills',
            ],
            willBePlus: [],
            functions: 'Engineer',
            project: 'Ventures',
            direction: 'Biochemistry'
        },
    ]
};

export const EN = {
    language: 'EN',
    header: [
        {caption: 'Home', link: '/', toLink: '/fr', showInHeader: true},
        {caption: 'Investment', link: '/investment', toLink: '/fr/investissement', showInHeader: true},
        {caption: 'About', link: '/about', toLink: '/fr/a-propos', showInHeader: true},
        {caption: 'ESG', link: '/esg', toLink: '/fr/esg', showInHeader: true},
        {caption: 'Foundation', link: '/foundation', toLink: '/fr/foundation', showInHeader: true},
        {caption: 'Careers', link: '/careers', toLink: '/fr/carrieres', showInHeader: true},
        {caption: 'Contact', link: '/contact', toLink: '/fr/contact', showInHeader: true},
        {caption: 'Terms', link: '/terms-conditions', toLink: '/fr/conditions-generales', showInHeader: false},
        {caption: 'Policy', link: '/privacy-policy', toLink: '/fr/politique-de-confidentialite', showInHeader: false},
        {
            caption: 'Neuromathematics',
            link: '/directions/neuromathematics-and-neurobiology',
            toLink: '/fr/domaines/neuromathematics-and-neurobiology',
            showInHeader: false
        },
        {caption: 'Biopharma', link: '/directions/biopharma', toLink: '/fr/domaines/biopharma', showInHeader: false},
        {caption: 'Tintech', link: '/directions/fintech', toLink: '/fr/domaines/fintech', showInHeader: false},
        {
            caption: 'Cybersecurity',
            link: '/directions/data-ecosystems-and-cybersecurity',
            toLink: '/fr/domaines/data-ecosystems-and-cybersecurity',
            showInHeader: false
        },
        {
            caption: 'Vehicles',
            link: '/directions/autonomous-vehicles',
            toLink: '/fr/domaines/autonomous-vehicles',
            showInHeader: false
        },
        {
            caption: 'Energy',
            link: '/directions/energy-generation-and-storage',
            toLink: '/fr/domaines/energy-generation-and-storage',
            showInHeader: false
        },
        {
            caption: 'Augmented reality',
            link: '/directions/augmented-reality',
            toLink: '/fr/domaines/augmented-reality',
            showInHeader: false
        },
        {caption: 'Edtech', link: '/directions/edtech', toLink: '/fr/domaines/edtech', showInHeader: false},
        {caption: 'Gaming', link: '/directions/gaming', toLink: '/fr/domaines/gaming', showInHeader: false},
    ],
    aboutBlock: {
        text1: "The Reason says:",
        text11: "“This is impossible”",
        text2: "Experience notes:",
        text22: "“This is reckless”",
        text3: "We say:",
        text4: "“Let's try it”",
        text5: "In this ever-changing world, it is important not to give up the search for those universal principles that tie everything together. New ideas are constantly being generated, but fundamental laws of physics and mathematics remain unchanged. Here in PBFS, we generate ideas that lead to innovative solutions. ",
        text6: "Discover PBFS "
    },
    careerBlock: {
        text1: 'Team up with',
        text2: 'We are a team of enthusiasts, united to create innovative solutions by doing the things we excel at. Together, we brainstorm, work hard, support each other and celebrate success Together, we shape the future for the better.',
        text3: 'View careers '
    },
    contactBlock: {
        text1: 'Are you ',
        text2: 'passionate',
        text3: ' about your research, have a start-up and looking for investments?',
        text4: 'Contact us now'
    },
    projectBlock: {
        text1: 'Science can do a lot',
        text2: '. And by applying it in practice we can do even more',
        text3: '.',
        text4: 'Discover all investment directions',
    },
    videoBlock: {
        text1: 'Artificial and ',
        text2: 'Intelligence for Science, Research and Development',
        text3: 'Discover PBFS Investment directions',
    },
    sliderText: [
        {
            title: 'DISCOVER DIRECTION',
            name: 'Neuromathematics and Neurobiology',
            descriptions: [
                'Combination of neuroscience, biology and computing',
                'Cognitive sciences and human brain studies',
                'Brain-inspired computer technologies',
            ],
            img: neuromathematicksBanner,
            url: '/investment/neuromathematics-and-neurobiology'
        },
        {
            title: 'DISCOVER DIRECTION',
            name: 'Biopharma',
            descriptions: [
                'Biological sources as inner instructions on how the human body cells function and heal',
                'Biosimilar biopharmaceuticals',
                'Evolvement of bioprocessing technologies',
            ],
            img: biopharmaBanner,
            url: '/investment/biopharma'
        },
        {
            title: 'DISCOVER DIRECTION',
            name: 'Fintech',
            descriptions: [
                'Combination of scientific research with practical modelling and application',
                'Use of machine learning and natural language processing',
                'Self-developed and tested models for evaluation of financial instruments and companies',
            ],
            img: fintechBanner,
            url: '/investment/fintech'
        },
        {
            title: 'DISCOVER DIRECTION',
            name: 'Data Ecosystems and Cybersecurity',
            descriptions: [
                'Infrastructure, analytics, and applications used to capture, store and analyze data',
                'Data transparency, safe distribution and authenticity checks',
                'Invention of ways to protect against cyber-attacks',
            ],
            img: dataEcosystemBanner,
            url: '/investment/data-ecosystems-and-cybersecurity'
        },
        {
            title: 'DISCOVER DIRECTION',
            name: 'Autonomous Vehicles',
            descriptions: [
                'Smart systems as an essential part of transport infrastructure',
                'Cars with self-charging batteries',
                'Urban transport that goes by air',
            ],
            img: vehilesBanner,
            url: '/investment/autonomous-vehicles'
        },
        {
            title: 'DISCOVER DIRECTION',
            name: 'Energy generation and Storage',
            descriptions: [
                'Automated power generation, transfer and storage processes',
                'New technologies of energy storage and transmission',
                'Wireless power sources and charges',
            ],
            img: energyBanner,
            url: '/investment/energy-generation-and-storage'
        },
        {
            title: 'DISCOVER DIRECTION',
            name: 'Augmented Reality',
            descriptions: [
                'Memory mirrors and virtual design and dressing rooms',
                'Itineraries and on-screen recommendations in restaurants, sightseen spots and vehicles',
                'Avatar companions, including for disabled people',
            ],
            img: augmented,
            url: '/investment/augmented-reality'
        },
        {
            title: 'DISCOVER DIRECTION',
            name: 'EdTech',
            descriptions: [
                'Creation of an online educational platform comprising interactive learning tools and VR sessions',
                'A mix of cutting-edge technologies, gaming and classic teaching methodologies',
                'Making discoveries in different fields of hard and natural sciences',
            ],
            img: edTech,
            url: '/investment/edtech'
        },
        {
            title: 'DISCOVER DIRECTION',
            name: 'Gaming',
            descriptions: [
                'New generation of economic games',
                'Combination of computer technology and education',
                'Immersive technologies in teaching students',
            ],
            img: gamingBanner,
            url: '/investment/gaming'
        },
    ],
    foundationSliderText: [
        {
            // title: 'DISCOVER DIRECTION',
            name: 'Quality Education',
            descriptions: [
                'Encourage transformation in education system',
                'Develop a new type of school for kids with outstanding academic ability and leadership potential',
                'Provide financial support for gifted children that lack financial means to help them reach their full potential',
            ],
            img: education,
            // url: '/investment/neuromathematics-and-neurobiology'
        },
        {
            // title: 'DISCOVER DIRECTION',
            name: 'Food Security',
            descriptions: [
                'Skills building trainings and access to agritech for eco-farming small business',
                'Grants for R&D initiatives on climate resilient crops',
                'Grants for science-based and technology-driven solutions for progressive countryside',
            ],
            img: foodSecurity,
            // url: '/investment/neuromathematics-and-neurobiology'
        },
        {
            // title: 'DISCOVER DIRECTION',
            name: 'Serious Health Problems Relief',
            descriptions: [
                'Support medical research designed to effectuate cures of major diseases',
                'Support emerging technologies within and outside biomedicine that could be adopted to address major global health challenges',
                'Targeted, short-term investments to determine the viability of innovations to specific areas of global health',
            ],
            img: healthProblems,
            // url: '/investment/neuromathematics-and-neurobiology'
        },
        {
            // title: 'DISCOVER DIRECTION',
            name: 'Planet Protection',
            descriptions: [
                'Fund innovative and sustainable projects which advance climate and clean energy equity',
                'Showcase the value of “circular economy” in the low-income countries',
                'Support awareness campaigns on recycling and waste minimization',
            ],
            img: planetProtection,
            // url: '/investment/neuromathematics-and-neurobiology'
        },
        {
            // title: 'DISCOVER DIRECTION',
            name: 'Humanitarian Aid',
            descriptions: [
                'Psychosocial assistance to victims of war and military conflicts',
                'Help refugees to gain necessary skills to compete at the local job market',
                'Facilitate access to finance and medical service to the kids and adolescents who lost limbs at the course of war and military conflicts',
            ],
            img: humanitarian,
            // url: '/investment/neuromathematics-and-neurobiology'
        },
    ],
    footer: {
        leftSide: [
            {text: 'About', link: '/about'},
            {text: 'Investment', link: '/investment'}
        ],
        rightSide: [
            {text: 'Careers', link: '/careers'},
            {text: 'Contact', link: '/contact'}
        ],
        terms: 'Terms & Conditions',
        linkToTerms: '/terms-conditions',
        text: '© PBFS 2023',
        policy: 'Privacy Policy',
        linkToPolicy: '/privacy-policy',
    },
    directionsPageInfo: {
        text1: 'We invest in ',
        text2: 'start-ups',
        text3: 'driven by the biggest ambitions to make an impact',
        text4: 'We team up with entrepreneurial minds and wild hearts with the craziest ideas, energized by their mission and unstoppable by obstacles.',
        text5: 'Our investment process is rapid, disciplined, and transparent. We consider entrepreneurs as partners and empower them to start small, learn quick and scale up even quicker.',
        text6: "PBFS has invested in",
        text7: "prominent companies and continues the active search for ambitious founders with breakthrough ideas.",
        criteriaTitle: 'Our investment criteria are:',
        criteria: [
            {
                title: "Innovation",
                description: "We believe that future will be shaped by advances in technologies through innovations, thus we invest into truly innovative products.",
                transition: "50"
            },
            {
                title: "Impact",
                description: "We prioritize exceptional ideas with potential to become high-impact companies of tomorrow.",
                transition: "150"
            },
            {
                title: "People",
                description: "Talent. Unconventional thinking. Drive. You need to have it all.",
                transition: "250"
            },
            {
                title: "Valuation",
                description: "We invest in early-stage startups, usually at Pre-Seed, Seed or Series A rounds. We stay with you through the cycle.",
                transition: "350"
            },
            {
                title: "Geography",
                description: "We generally focus on companies located in Switzerland, Israel and Western Europe.",
                transition: "450"
            }
        ],
        timeline: [
            {date: "October 2021 ", text: "Autonomous vehicles"},
            {date: "November 2021 ", text: "Agritech"},
            {date: "January 2022 ", text: "Medtech"},
            {date: "January 2022 ", text: "Fintech"},
            {date: "March 2022 ", text: "Biotech"},
            {date: "April 2022 ", text: "Data analytics"},
        ],
        members: {
            header: "PBFS is a member of:"
        }
    },
    esgPage: {
        header: {
            title: {
                startText: "In PBFS we believe that ",
                colorfulText: "responsible ",
                endText: "ownership and business success go hand in hand"
            },
            subtitle: [
                "PBFS mission is sustainable growth via creating value for our shareholders and society along with reducing the environmental impact in all areas of our business. We invest in disruptive and transformational technologies that can support a low-carbon economy of tomorrow.",
                "We believe that business as well as environmental, social and governance (ESG) questions are equally important for a long-term sustainability of any organization and when combined can provide added value for our Company and for the society.",
            ]
        },
        factors: {
            title: "ESG factors",
            subtitle: "The Company is committed to applying ESG-minded business practices, including but not limited to responsible investment decisions, valuation of performance and reputation of all its business decisions.",
            factorsList: [
                {
                    transition: '50',
                    boldText: "Environmental",
                    text: "We aim to embed environmental factors in everything we do. We believe we have a responsibility to minimize the energy, carbon, water, and waste impacts of our business through our entire corporate actions. The key directions of the Company’s activities concentrate around investment into businesses that contribute to carbon neutrality, such as renewable energy technologies, opportunities in clean tech and ecologically sound businesses, responsible waste management."
                },
                {
                    transition: '150',
                    boldText: "Social",
                    text: "PBFS is committed to the principles of diversity and inclusion, non-discrimination and equality of opportunities. Our success is based on mutual respect, understanding the value of the contribution of each of us and fair treatment of each employee. The Company seeks to provide comfortable and safe working conditions to its personnel and makes efforts to fully develop the professional qualities of its employees."
                },
                {
                    transition: '250',
                    boldText: "Governance",
                    text: "Strong governance, highly ethical business practices and prudent risk management are critical ingredients to achievement of our business goals. This includes a sound approach to corporate governance that complies with all applicable laws, rules, regulations, and policies as well as unwavering adherence to our values. The Company encourages an open culture within its organization, with business ethics, integrity and transparency, reporting and compliance being essential to the way the Company operates its business."
                }
            ]
        }
    },
    foundationPage: {
        text1: 'We believe that good ',
        text2: 'corporate citizenship ',
        text3: 'should be a matter of course and is not something to be undertaken for publicity purposes',
        text4: `The 𝘱 Foundation is a non-profit charitable organization independent of PBFS. It supports innovative projects in the fields of education, environment, humanitarian aid, food security and medical research.`,
        text5: 'Our aim in each sector is to improve performance, provide wider access and inclusion, foster innovation and catalyze access to finance. We invest in interventions globally, developing and supporting scalable solutions that address the high-societal needs. The work we fund is framed by the Sustainable Development Goals (SDGs).',
        criteriaTitle: 'Our Approach',
        criteriaTitleText: 'Our approach to philanthropic donations and non-commercial sponsorship mirrors our sustainable business model and innovation culture. Our selection criteria are based on the following principles: ',
        criteria: [
            {
                title: "Emphasis on Impact",
                description: "The 𝘱 Foundation emphasis is on projects addressing societal challenges and making a real difference through innovation, collaboration, quality, and sustainability. When assessing a project, we focus on the potential impact and lasting improvements it could bring to the society, rather than on the cost.",
                transition: "50"
            },
            {
                title: "Commitment and Focus",
                description: "Our aim is to be a reliable, long-term, and active partner. We prefer to support a small number of selected projects, being actively engaged as an equal partner at the earliest possible stage of any project to optimize the use of the available resources.",
                transition: "150"
            },
            {
                title: "Engagement",
                description: "The 𝘱 Foundation looks to dedicate its resources to projects where we believe our involvement can make a significant difference. We believe that sharing expertise and human talent, as well as transferring know-how can be much more effective and sustainable than a strict material or financial contribution.",
                transition: "250"
            },
            {
                title: "Compliance",
                description: "Our philanthropic donations do not aim to compete or replace the local governments’ programs, and only support projects that are locally approved and accepted. The 𝘱 Foundation strictly respects the applicable local governance, culture, and independence and adheres to the high integrity business standards.",
                transition: "350"
            },
        ],
    },
    aboutPageInfo: {
        text1: 'There is a certain order in the Universe, and we like to say that ',
        text2: 'mathematics',
        text3: ' is a good way to understand it',
        text4: ["Science and technology are at the heart of our business."],
    },
    aboutPageCoreValue: {
        text1: 'Our core values keep us together',
        text2: 'Our core values are the cornerstones on which the idea of who we are and what we aspire for is built. ',
        textList: [
            {
                boldText: 'Efficient and reliable performance ',
                text: 'Our projects accumulate the interests of people and businesses with diverse backgrounds and goals. We strive to make our decisions pitch-perfect and bring long-term value to our partners. ',
                transition: '50',
            },
            {
                boldText: 'Commitment to innovations and excellence ',
                text: 'In PBFS, we believe that fundamental research and experiments drive growth and new opportunities. We always look to support viable innovative ideas, and our goal is to build an ample investor’s and partner’s community that will embody these innovative decisions into reality. ',
                transition: '150',
            },
            {
                boldText: 'Mutual respect and support ',
                text: 'We treat our employees and all our partners with professionalism, dignity and respect, creating an environment where people can work together, make changes and become better. We appreciate and support our employees and are ready to assist their initiative and career growth. ',
                transition: '250',
            },
        ],
    },
    projectInspire: {
        text1: 'Our ',
        text2: 'projects',
        text3: ' inspire us',
        text4: 'Our strategy is to invest in the future where smart decisions, effective resource management and healthy, long lives, are a common truth. We plan to enter the 2035 with a wide portfolio of projects that would mirror our knowledge and research results. ',
        blocks: [
            {
                delay: '0',
                header: 'Research, analysis and valuation',
                contents: [
                    {
                        type: 'text',
                        value: 'Our research and analysis are based on automatic data collection, analysis and prediction of data relationships using artificial intelligence tools.'
                    },
                    {type: 'strongText', value: 'We use unique algorithms to perform:'},
                    {
                        type: 'list', value: [
                            'Industry and economic research;',
                            'Technical analysis and research, including trends and forecasting models;',
                            'Fundamentals including business valuation, financials and ratios, bankruptcy index, ESG index;',
                            'Create a precise risk assessment model which could be applied to each and every company in a defined industry.'
                        ]
                    },
                    {
                        type: 'footer',
                        value: 'PBFS has been working on the in-house development of the artificial intelligence models capable of unprejudiced analysis of markets and valuation techniques. The Real PRAITIKS Value (RPV) and a p-bankruptcy model have already been derived to identify net liquidity of an enterprise and its bankruptcy index respectively.'
                    }

                ]
            },
            {
                delay: '25',
                header: 'News media filtering',
                contents: [
                    {
                        type: 'text',
                        value: 'We gather and analyze data from news media to generate useful insights for effective business decisions. We mine sentiments and filter news to support industry and company analysis and understand future trends in target industries.'
                    },
                    {
                        type: 'list', value: [
                            'Usage of news analysis in artificial intelligence models as support for prediction patterns;',
                            'Using latest machine-learning methods to gather information faster and more thoroughly, and to distinguish truthful and relevant information;',
                            'Creation of unbiased news contents through raising an AI-powered news assistant system for the purposes of artificial intelligence models’ consumption;',
                            'Creation of AI-powered personalized news feeds to build a seamless and meaningful news experience.'
                        ]
                    },
                ]
            },
            {
                delay: '50',
                header: 'Venture and equity financing',
                contents: [
                    {
                        type: 'text',
                        value: 'PBFS’s main focus is to constantly look into the latest technical developments and disruptive technologies. Our equity investment decisions will be based on careful analysis of the prospective investments, market potential, team capabilities and its alignment with our investment goals. Our main investment directions include:'
                    },
                    {
                        type: 'list', value: [
                            'Neuromathematics and Neurobiology',
                            'Biopharma',
                            'Data Ecosystems and Cyber Security',
                            'Autonomous Vehicles',
                            'Energy Generation and Storage',
                            'Augmented Reality',
                            'Fintech',
                            'EdTech',
                            'Gaming',
                        ]
                    },
                    {
                        type: 'footer', value: {
                            text: "PBFS is a member of:",
                            imgs: [
                                {
                                    svg: <SecaLogo/>,
                                    link: "https://www.seca.ch/"
                                },
                                {
                                    svg: <SicticLogoWhite/>,
                                    link: "https://www.sictic.ch/"
                                },
                            ]
                        }
                    }
                ]
            },
            {
                delay: '75',
                header: 'Social projects and charity',
                contents: [
                    {
                        type: 'text',
                        value: 'As part of its ongoing commitment to responsible corporate behavior, PBFS has set up the 𝘱 Foundation – a non-profit charitable organization. Our approach to philanthropic donations and non-commercial sponsorship mirrors our business model and innovation culture. We focus our resources on a limited number of projects that can make an impact that matters in society and deliver valuable benefits from our contributions. We give priority to innovative, high-quality projects in the fields of:'
                    },
                    {
                        type: 'list', value: [
                            'Education',
                            'Food security',
                            'Medical research',
                            'Environment',
                            'Humanitarian aid',
                        ]
                    },
                    {
                        type: 'text',
                        value: 'The projects we fund are framed by the Sustainable Development Goals (SDGs). During the selection process we demonstrate our commitment to environmentally and socially responsible operations.',
                    },
                    {
                        type: 'footer', value: {
                            text: "Read more:",
                            imgs: [
                                {
                                    svg: <FoundationLight/>,
                                    link: "foundation"
                                }
                            ]
                        }
                    }
                ],
            },
        ],
    },
    timeLineVision: {
        text1: 'Our ',
        text2: 'vision',
        text3: ' in a timeline',
        text4: 'Time flies, and we are excited to imagine our future where disruptive technologies already rule the world.',
        list: [
            {
                bold: 'March 2018',
                text: 'PBFS SA was born',
            },
            {
                bold: 'April-December 2018',
                text: 'Gathering the core team of self-motivated and energetic individuals',
            },
            {
                bold: 'August 2018',
                text: 'PBFS Vision 2035 and its goals and main investment directions were defined',
            },
            {
                bold: 'January 2019',
                text: 'Our first fintech product was launched',
            },
            {
                bold: 'February 2019',
                text: 'Development of PRAITIKS was started – an artificial intelligence tool for data systematization and financial analysis of enterprises, industries, and countries',
            },
            {
                bold: 'May 2019',
                text: 'News media filtering project was started',
            },
            {
                bold: 'July-December 2019',
                text: 'In-house bankruptcy model research, development, and testing has been performed',
            },
            {
                bold: 'November 2020',
                text: 'Development of a mobile game with educational aspects was started',
            },
            {
                bold: 'January 2021 – April 2021',
                text: 'Projects related to renewable energy and waste management were launched',
            },
            {
                bold: 'July 2022',
                text: 'Opened PBFS (Israel) LTD and a local R&D center',
            },
            {
                bold: 'August 2022 - further',
                text: 'We will not give up all the secret, but much more is yet coming',
            },
            {
                bold: '2035',
                text: 'PBFS Vision comes true',
            },
        ]
    },
    careersInfoBlock: {
        text1: 'Team up with',
        text2: 'We are a team of enthusiasts, united to create a positive change in the world by doing the things we excel at. Together, we brainstorm, work hard, support each other and celebrate success. Together, we shape the future for the better.',
    },
    careersPositions: careerPositions(),
    careerPage: {
        openedPosition: 'Opened positions',
        filters: [
            'Job Function',
            'Project',
            'Direction',
        ],
        clearFilter: 'Clear filter',
        filterOpenedPositionText: 'Filter opened position'
    },
    contactForm: {
        title: 'Apply now',
        email: 'Your email',
        yourName: 'Your name',
        firstName: 'Enter your first name',
        secondName: 'Enter your last name',
        phone: 'Enter your phone',
        message: 'Message to hiring manager',
        enterMessage: 'Message to hiring manager',
        cv: 'Attach CV and cover latter',
        terms1: 'I agree to the ',
        terms2: 'Terms & Conditions ',
        termsLink: '/terms-conditions',
        sendMail: 'Send application',
        sendMessage: 'Send message',
    },
    contactPage: {
        defaultSubjects: [
            {title: 'Choose the subject', selected: true},
            {title: 'Investment', selected: false},
            {title: 'Projects', selected: false},
            {title: 'Complaints', selected: false},
            {title: 'Other', selected: false},
        ],
        text1: 'Are you ',
        text2: 'passionate',
        text3: ' about your research, have a start-up and looking for investments? ',
        text4: 'We often speak to be listened to, but not heard. Here at PBFS, we understand that communication is a two way street and thus we strive to create a culture where every person and every partner is heard.',
        banner: {
            text1: 'Address: ',
            text2: '4-6 Place de l’Université, 1205 Geneva, Switzerland',
            text3: 'Phone: ',
        }
    },
    termsAndConditionsPage: {
        title: "Terms & Conditions",
        texts: [
            {
                p: [
                    "Terms and conditions for PBFS website:",
                    "Welcome to PBFS website. These website Standard Terms and Conditions (further – the “Terms”) shall govern the use of PBFS website accessible at #https://pbfs.swiss/# (further – the “Website”), regardless of how it is accessed.",
                    "These Terms will be applied fully and affect the use of this Website. Please, read these Terms carefully before accessing any materials on this Website. By accessing and using this Website, you acknowledge to have read and agree to be bound by all the Terms written in here."
                ]
            },
            {
                title: "Intellectual Property Rights",
                p: [
                    "Under these Terms, PBFS SA and/or its affiliates and/or licensors own all the intellectual property rights and materials contained in this Website, including but not limited to the information, contents, logo, products, and other media, graphics, designs and/or trademarks (further - the “Content”). You are granted limited rights exclusively for purposes of strictly private and personal viewing of the Content contained on this Website. You do not acquire any rights by reproducing, copying or downloading in any way the Content available on this Website; all rights are retained by PBFS SA and/or its affiliates and/or licensors.",
                    "The materials displayed on this Website may not be used in any other way without the prior written consent of PBFS SA.",
                    "You are specifically restricted from all of the following:"
                ],
                ul: [
                    "publishing any Website Content in any other media;",
                    "modifying the Content or use it for any other purpose, or use it on any other website or computer network without the prior written consent of PBFS SA;",
                    "selling, sublicensing and/or otherwise commercializing any Website Content;",
                    "publicly performing and/or showing any Website Content, for commercial purposes;",
                    "using this Website in any way that is or may be damaging to this Website;",
                    "using this Website in any way that impacts user access to this Website;",
                    "using this Website contrary to applicable laws and regulations, or in any way that may cause harm to the Website, or to any person or business entity;",
                    "engaging in any data mining, data extracting or any other similar activity in relation to this Website;",
                    "using this Website to engage in any advertising or marketing."
                ]
            },
            {
                title: "Website content",
                p: [
                    "Certain areas of this Website are restricted from being accessed, and PBFS SA may further restrict access to any areas of this Website, at any time, in its absolute discretion. All provisions of the Terms shall survive the termination of access, including, without limitation, intellectual property rights, warranty disclaimers, and limitations of liability provisions.",
                    "This Website, together with all the Content, information and materials contained therein, is provided “as is” with all faults, if any, and PBFS SA express no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as providing advice to anyone nor shall constitute a solicitation or an offer. All the Content, including information and materials published or made available on the Website are provided for informational purposes, exclusively for non-commercial, strictly private and personal use.",
                    "PBFS SA reserves the right to change, modify or remove the all or any Contents of the Website at any time or for any reason at PBFS SA sole discretion, without notice. However, PBFS SA has no obligation to update any information on the Website. It is your responsibility to consult these Terms regularly in order to take note of any changes made to them. Continued access to the Website following any modification in these Terms will constitute your acceptance of the Terms as modified. If you do not accept these Terms, you may not use the Website.",
                    "PBFS SA also reserves the right to modify or discontinue all or part of the Website without notice at any time. PBFS SA shall not be liable to you or any third party for any modification, suspension or discontinuance of the Website.",
                    "There may be information on the Website that contains typographical errors, inaccuracies or omissions, including descriptions, availability and various other information. PBFS SA reserves the right to correct any errors, omissions or inaccuracies and to change and update the information on the Website at any time, without prior notice. PBFS SA does not warrant the accuracy, timeliness, completeness, security or reliability of the Website or the Content, including information or results obtained from use of the Website. In no event will PBFS SA be liable for any damages whatsoever resulting from the use of the Content of the Website. PBFS SA also declines all responsibility if the use of this information contravenes a patent, copyright or registered trademark.",
                    "PBFS SA cannot guarantee that the Website will be available at all times. PBFS SA may experience hardware, software or any other problems or need to perform maintenance of the Website, resulting in interruptions, delays or errors. You agree that PBFS SA has no liability whatsoever for any loss, damage or inconvenience caused by your inability to access or use the Website during any downtime or discontinuance of the Website. Nothing in these Terms is construed to obligate PBFS SA to maintain and support the Website or to supply any corrections, updates or releases in connection therewith."
                ]
            },
            {
                title: "Exclusion of liability",
                p: [
                    "In no event shall PBFS SA, nor any of its shareholders, officers, agents, directors and employees, be held liable for anything arising out of or in any way connected with your use of and access to this Website, including its Сontent, use of information and services available on the Website, or the inability to use or access the Website or any information or services available on the Website. Your use of the Website is at your own risk. PBFS SA, including its shareholders, officers, agents, directors and employees shall not be held liable for any direct or indirect, consequential or special liability, claim or damage of any kind, whether in contract or in tort, including negligence, arising out of or in any way related to the use of this Website, or any links to third party websites.",
                    "You agree and acknowledge that Internet transmissions maybe not be completely private or secure. You understand that any message or information that you send to the Website may be read or intercepted by others, even though the protection of information that identifies you personally is of utmost importance to PBFS SA. PBFS SA makes no guarantee or representation that the use of the Website is protected from viruses, security threats or other vulnerabilities."
                ]
            },
            {
                title: "Links",
                p: [
                    "PBFS SA formally declines all responsibility for any third party websites and their content that are linked to at the Website. These contents and links are provided solely for your convenience and information. PBFS SA has no control over, does not assume any responsibility for and does not make any warranties or representations as to, any third party websites, including but not limited to, the accuracy, subject matter, quality or legality of the content of these sites. The decision to activate these links rests exclusively with you. By activating these links, you are responsible for reading and compliance with the terms and conditions and privacy policies of the third party websites."
                ]
            },
            {
                title: "Privacy Policy",
                p: [
                    "PBFS SA has established a Privacy Policy, as amended from time to time, which explains how personal data is collected on the PBFS Website and used. The Privacy Policy is part of these Terms and is incorporated herein by reference. #https://pbfs.swiss/privacy-policy#."
                ]
            },
            {
                title: "Severability",
                p: [
                    "If any provision of these Terms is found to be invalid, void, cancelled or otherwise unenforceable, such provision shall be deleted without affecting or impairing the remaining provisions herein."
                ]
            },
            {
                title: "Revision",
                p: [
                    "PBFS SA can revise these Terms at any time as it considers appropriate, and by using this Website you are expected to review these Terms on a regular basis."
                ]
            },
            {
                title: "Assignment",
                p: [
                    "PBFS SA is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification."
                ]
            },
            {
                title: "Choice of law and jurisdiction",
                p: [
                    "These Terms shall be governed by and interpreted in accordance with the Swiss law. The sole place of jurisdiction for all disputes arising out of or in connection with the Terms, the Privacy Policy as part of these Terms, and the use of the Website is Geneva, Switzerland. PBFS SA reserves the right to initiate proceedings against you before the competent court at your domicile or any other competent authority."
                ]
            }
        ]
    },
    privacyPolicyPage: {
        title: 'Privacy Policy',
        texts: [
            {
                p: [
                    "This Privacy Policy is designed to explain to our site visitors what information we gather about them, what we use that information for, and with whom we share that information, in compliance with Swiss, EU and any other applicable data protection laws. It also sets out their rights in relation to such information and whom to contact for more information or queries.",
                    "This Privacy Policy applies to PBFS SA (hereinafter referred to as the “Company”).",
                    "PBFS SA takes our site visitors’ privacy seriously. WE WILL NOT USE OR SHARE ANY PERSONAL INFORMATION WITH ANYONE EXCEPT AS DESCRIBED IN THIS PRIVACY POLICY.",
                    "When this Privacy Policy refers to “our Website” or “the Website”, it means the specific webpages of the Company: #https://pbfs.swiss/# By visiting this Website, you are accepting and consenting to the practices described in this Privacy Policy. This Website may contain links to third party websites, to which this Privacy Policy does not apply.",
                ]
            },
            {
                title: "Types of Personal Information collected",
                p: [
                    "The Company may collect and store any information that our visitors explicitly and knowingly provide us or enable us to access, whether via the Website, software made available for use in connection with the Website (hereinafter referred to as “Software”), or direct communication (for example, in the process of filling forms, submitting enquiries or subscribing to our newsletter), including, for example, information which identifies or relates to you personally, such as your name, age, e-mail address, and/or career interests, and others (hereinafter referred to as the “Personal Information”), and from other sources, including commercially available sources, such as public databases (where permitted by law). We are required by law to always have a lawful basis for processing your Personal Information."
                ]
            },
            {
                title: "Processing of Personal Information",
                p: [
                    "We collect and process your Personal Information with the utmost care and in compliance with the applicable legal provisions. Processing is limited to what is strictly necessary and only for the purposes described in this Privacy Policy. We will only store your Personal Information for no longer than is necessary for the purposes for which it was processed in order to provide our services and as provided by law.",
                    "We take appropriate technical and organizational security measures to protect our databases from external intrusion, unauthorized access or misuse, loss and falsification, by developing internal guidelines, training of our staff and network security controls.",
                    "We may share some or all Personal Information with our other related party companies and/or affiliated companies that we have or may have in the future, by entering into a written contract, providing such a company undertakes to protect privacy of our visitors in accordance with terms provided herein and have appropriate security measures in place to protect your Personal Information, for the following reasons"
                ]
            },
            {
                ul: [
                    "In relation to our Website hosting and operation, IT systems, support, and maintenance;",
                    "For authorizations/approvals with relevant decision makers;",
                    "To meet our customer needs when providing services;",
                    "For reporting purposes;",
                    "For meeting regulatory requirements."
                ]
            },
            {
                p: [
                    "We make sure that such related party companies and/or affiliated companies have appropriate security standards in place to protect your Personal Information.",
                    "We may enter into a written agreement with third-party service providers to carry out certain business functions for us. These third parties will process your Personal Information on our behalf, as our processor. In such case, your Personal Information will be disclosed to them, and we will make sure that these third parties have appropriate security measures in place to protect your Personal Information. The written agreement between us will impose appropriate security measures on these third parties.",
                    "Otherwise, we will not disclose your Personal Information to any third party, unless:"
                ]
            },
            {
                ul: [
                    "Required to do so as part of any legal obligation or legal proceedings conducted between our visitors and the Company;",
                    "Required to do so by law or a court order;",
                    "Required to do so by regulatory authorities;",
                    "In case the entire activity of the Website is transferred, purchased or sold to a third party, providing such party undertakes to protect privacy in accordance with terms provided herein. You will be informed of any transfer to such third party and may object within a given term to the transfer of your Personal Information. You will also be informed in case the third party decides to change the purposes of processing of Personal Information."
                ]
            },
            {
                p: [
                    "The Company uses Personal Information because it is necessary:"
                ]
            },
            {
                ul: [
                    "To process our visitors’ inquiries, respond to their requests to improve our products and services, content and advertising;",
                    "To ensure proper operation and performance of our Website, monitoring its use, functionality for users, security and integrity on an ongoing basis;",
                    "To identify and prevent fraudulent or criminal activity online, misuses of our products and services, as well as the security of our IT systems, architecture and networks;",
                    "For our general record-keeping and customer relationship management, for good governance and risk management;",
                    "For internal purposes such as auditing, data analysis and research, including to produce statistical research and reports to improve the Company’s products and services;",
                    "To assess and verify any job application and/or inquiries that our visitors submit through the Website;",
                    "For legal and/or regulatory obligations or requests that the Company is subject to, including but not limited to keeping records for tax purposes or providing information to a public body or a law enforcement body;",
                    "For the conclusion of a contract at the request of our visitors or to perform contractual obligations that we owe towards our visitors."
                ]
            },
            {
                p: [
                    "If a visitor is a potential candidate for employment with the Company, we may have received visitor’s Personal Information from third parties such as open external websites, social media or recruiters. We will use the Personal Information we receive to contact the visitor about a potential opportunity."
                ]
            },
            {
                title: "Storage of Personal information",
                p: [
                    "The Personal Information we collect from you may be processed outside Switzerland and the European Economic Area (“EEA”). In such case, if any of our processing activities require your personal data to be transferred outside the EEA, we will only make that transfer if:"
                ],
                ul: [
                    "the country to which the personal data is to be transferred ensures an adequate level of protection for personal data;",
                    "we have put in place appropriate safeguards to protect your personal data, such as an appropriate approved form of contract with the recipient;",
                    "the transfer is necessary for one of the reasons specified in data protection legislation; or",
                    "you explicitly consent to the transfer."
                ]
            },
            {
                title: "Processing of Non-Identifying Information",
                p: [
                    "The Company collects and aggregates non-identifying information regarding the use of the Website, including, for example, but not limited to occupation, language, zip code, area code, time zone, unique device identifier, type of a device through which the Website is accessed, pages browsed, search inquiries, the type of browser that our visitors are using and its settings, IP (Internet Protocol) address, the URL they have come from and the time spent at that URL, content of Cookies set by us (as further detailed below) and your domain type and server (“Non-Identifying Information”).",
                    "Please, note that Non-Identifying Information may be aggregated separately for each specific IP. That said, IP addresses, while identifying a device connected to the Internet and its general location, are in themselves anonymous and contain no information regarding the identity of the ultimate person using the device – such identity should be known solely to your internet service provider.",
                    "Non-Identifying Information shall be used to ensure the adequate operation of the Website, improve, modify, cancel, enrich or adjust the Website, in general or to specific area of interest of our visitors.",
                    "The Company reserves the right to use and disclose Non-Identifying Information (in the form collected) to third parties, including, for example, potential and actual business partners, affiliates and consultants, at our sole discretion, for statistical reporting.",
                    "If we do combine Non-Identifying information with Personal Information, the combined information will be treated as personal information for as long as it remains combined and shall not be shared or disclosed to third parties. Otherwise, we will not identify you from any online identifiers like your IP address."
                ]
            },
            {
                title: "About Cookies and Personal Information we collect automatically",
                p: [
                    "In some instances, the Company and our third-party content companies use cookies, web beacons and other tracking technologies to automatically collect certain types of information when you visit this Website, as well as through emails that we may exchange. Examples of this type of information include, but are not limited to:"
                ],
                ul: [
                    "Identifiers, such as your IP address, your browser type, your operating system, or domain name; and",
                    "Internet or other electronic network activity information, such as the dates and times of your use of the Website, the route by which you choose to access them, and your use of any hyperlinks or downloadable content available on this Website."
                ]
            },
            {
                p: [
                    "We collect this information using cookies. Such collection of information helps us to customize your online experience, improve the performance, usability and effectiveness of our Website.",
                    "Cookies are small data files downloaded and stored on a user’s device while browsing a website, often used to keep record of actions taken on such website or track related browsing activity. Where cookies are used, a statement will be sent to your browser explaining the use of cookies. For additional information regarding Cookies and the way to control them, please check out the Help file of your browser or visit #https://www.aboutcookies.org/#.",
                    "Essential Cookies are essential for the Website to function properly. They are only used during a session and are deleted once you leave the Website.",
                    "Analytical Cookies are used only with your consent. These Cookies enable us, on the basis of anonymous data, to better understand how you use our Website so that we continually optimize it. You can accept or refuse the use of analytical cookies in the cookies banner on the home page of the Website and may change your preferences via the settings on the Website.",
                    "A notification banner appears on the Website requiring your consent to collect Cookies. Please note that modern browsers enable you to disable Cookies (if you do not know how to do this, check out the Help file of your browser), however, this may interfere with your use of the Website and/or its services.",
                    "Unless you disable Cookies, by using or browsing the Website you explicitly consent to Cookies being stored and set and information being accessed on your equipment by both the Company and third-party content companies.",
                    "You have the option to render some web beacons unusable by rejecting their associated cookies. The web beacon may still record an anonymous visit from your IP address, but cookie information will not be recorded."
                ],
                ul: [
                    "We use Cookies in order to ensure proper operation, collect Non-Identifying Information, verify information, tailor the Website to your specific preferences, and ensure information security;",
                    "Additionally, we may use third-party content companies in order to present content on our Website. Such parties may use Cookies placed on your computer in order to adjust or track the content presented on the Website;",
                    "Regarding use of Cookies by third-party content companies, please note that such use is subject to the privacy policy of said parties and is not subject to this Privacy Policy."
                ]
            },
            {
                p: [
                    "The following Cookies are used in connection with the Website:"
                ],
                ul: [
                    "Cookies placed by the Company – used for maintaining active session between browser restarts, facilitating the Website navigation and usability, managing access rights and remembering personal settings;",
                    "Cookies placed by Google Analytics– used to track users as they navigate the Website and help improve the Website’s usability."
                ]
            },
            {
                p: [
                    "Cookies by themselves do not reveal your email address nor allow to identify you personally."
                ]
            },
            {
                title: "Your rights",
                p: [
                    "As provided by law, you have the right to access, consult and request a physical or electronic copy of the information concerning you. You also have the right to request information about the source of your Personal information.",
                    "a) Right to information:",
                    "You are entitled to the following information held by us, as a data controller, about the processing of your Personal Information:"
                ],
                ul: [
                    "The specific Personal Information processed about you as a data subject;",
                    "The purposes of processing associated with your Personal Information;",
                    "The categories of Personal Information concerned by our processing operations;",
                    "The possible recipients of this data, and at least the categories of recipients concerned;",
                    "The period for which the data is to be kept, and at least the criteria for determining this period;",
                    "The information available on the origin of the Personal Information, insofar as this Personal information hasn’t been collected from the data controller."
                ]
            },
            {
                p: [
                    "b) Other rights:",
                    "You also have the right to correct, revise or erase Personal Information concerning you, to restrict or object to the processing of such Personal Information. You may also revoke your consent to the processing of Personal Information at any time. As soon as we receive your notification that you are revoking your consent, we will no longer process the Personal Information for the purposes to which you originally consented.",
                    "These rights may be exercised by emailing us to legal@pbfs.swiss, enclosing a copy of your ID or equivalent information (at our request and to the extent permitted by law)."
                ]
            },
            {
                title: "Newsletter",
                p: [
                    "In some of our newsletters and other communications, we may monitor recipient actions such as email open rates through embedded links within the messages. We collect this information to gauge user interest and to enhance future user experiences. We will only send out newsletter to you if you subscribe to it. You may also withdraw your consent to stop receiving emails from PBFS SA marketing list by clicking on the “unsubscribe” link in the email you received by us."
                ]
            },
            {
                title: "Privacy Policy updates",
                p: [
                    "This Privacy Policy is part of the Terms and Conditions of this Website #https://pbfs.swiss/#",
                    "The Company may modify or amend this Privacy Policy from time to time. Such changes will be effective upon posting. Please check back frequently to see if any changes to this Privacy Policy."
                ]
            },
            {
                title: "Contact us",
                p: [
                    "If you have complaints about PBFS SA’s processing of your Personal Information, you may have the right to contact the applicable supervisory authority. However, we would appreciate the chance to deal with your concerns and resolve any possible issue before you approach such authority. We kindly ask you to contact us first at legal@pbfs.swiss.",
                    "If you have any questions, please contact us at email legal@pbfs.swiss.",
                    "Last update: July 2023"
                ]
            }
        ],

        // linkToCookies1: '',
        // linkToCookies2: '',
    },
    projects: {
        neuromathematics: {
            returnTo: '/investment',
            title: 'Neuromathematics and Neurobiology',
            directionSvg: <DirectionsSVG/>,
            shortDescribe: 'Artificial intelligence (AI) is not meant to end humans’ era. The progress in artificial intelligence increases the pace for technological development, augments human intelligence, and does the most to change the world.',
            bannerImg: neuromathematicksBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Neural networks are computer systems inspired by the biological neural networks’ structural and functional principles. Neural networks learn from observational data by considering examples and constitute the basis for artificial intelligence.',
                        delay: 0,
                    },
                    {
                        text: 'Our research is focused on cognitive sciences and human brain studies within three dimensions, comprising and combining neuroscience, biology and computing:',
                        delay: 25,
                    },
                ],
                listOfLi: [
                    'Complex cognitive functions of brain stimulate development of brain-inspired computer technologies;',
                    'Research of memory storage functioning as the foundation for modern advanced methods of data storage and processing;',
                    'Nature of energetic activity in the brain is the basis for development of cutting-edge technologies of energy generation, storage and use.'
                ],
                listOfP2: [
                    {
                        text: 'Many deep learning algorithms and applications already affect human lives and will continue to evolve in the future. Our main areas of research and investments include:',
                        delay: 0,
                    }
                ],
                listOfLi2: [
                    'Business intelligence and data analysis, including forecasting, and risk analysis;',
                    'Creation of databases for further deep learning, and self-writing software;',
                    'Image, video and handwriting recognition;',
                    'Virtual online assistants;',
                    'Diagnosis of medical problems and precision medicine.',
                ]
            },
            sliderImg: [neuromathematicksSlide1, neuromathematicksSlide2, neuromathematicksSlide3],
            viewCareerTitle: 'Join the core team',
            viewCareerSvg: <ViewCareer/>,
            viewCareerText: 'View Careers',
            linkToCareer: '/careers',
            nextDirectionTitle: 'Biopharma',
            nextDirectionSvg: <NextDirection/>,
            nextDirectionText: 'View next direction',
            nextLink: '/investment/biopharma',
        },
        biopharma: {
            returnTo: '/investment',
            title: 'Biopharma',
            directionSvg: <DirectionsSVG/>,
            shortDescribe: '“Medicine is a science of uncertainty and an art of probability”, – William Osler, Canadian physician.',
            bannerImg: biopharmaBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Biopharmaceuticals take advantage of natural processes and mechanisms to drive successful disease treatment. They study and address biological sources as inner instructions on how the human body cells develop, function, grow, reproduce and heal.',
                        delay: 0,
                    },
                    {
                        text: 'Biopharmaceuticals have made an enormous impact in advancing early disease detection, and the industry has experienced significant growth in recent years. Particularly, advances have taken place in such areas as DNA, gene and cell therapies. This recent evolution has brought the mankind to the verge of very promising discoveries, and the key areas the Company sees among them are:',
                        delay: 25,
                    },
                ],
                listOfLi: [
                    'Biosimilar biopharmaceuticals;',
                    'Evolvement of bioprocessing technologies;',
                    'Personalized treatment of cohorts of patients based on genome research.'
                ],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [biopharmaSlide1, biopharmaSlide2, biopharmaSlide3],
            viewCareerTitle: 'Join the core team',
            viewCareerSvg: <ViewCareer/>,
            viewCareerText: 'View Careers',
            nextDirectionTitle: 'FinTech',
            nextDirectionSvg: <NextDirection/>,
            nextDirectionText: 'View next direction',
            linkToCareer: '/careers',
            nextLink: '/investment/fintech',
        },
        fintech: {
            returnTo: '/investment',
            title: 'FinTech',
            directionSvg: <DirectionsSVG/>,
            shortDescribe: '“Noone has a monopoly for bright ideas. This is a good reason to partner with fintech companies”',
            bannerImg: fintechBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Fintech has found its place in the innovation economy with launching financial services applications, emerging digital incubators, and private venture capital skyrocketing.',
                        delay: 0,
                    },
                    {
                        text: 'The Company has a talented and enthusiastic team of professionals in the fields of mathematics and programming who successfully combine scientific research with practical modelling and application in real-life economic situations. The outcome of our fruitful work represents self-developed and tested models that allow to evaluate financial instruments and companies and predict their valuation, growth, decline or bankruptcy with a high degree of probability.',
                        delay: 25,
                    },
                    {
                        text: 'As areas for further application of its studies, the Company sees the future in the financial services based on technologies which are capable of re-inventing efficient finance.',
                        delay: 50,
                    },
                ],
                listOfLi: [],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [fintechSlide1, fintechSlide2, fintechSlide3],
            viewCareerTitle: 'Join the core team',
            viewCareerSvg: <ViewCareer/>,
            viewCareerText: 'View Careers',
            nextDirectionTitle: 'Data Ecosystems and Cybersecurity',
            nextDirectionSvg: <NextDirection/>,
            nextDirectionText: 'View next direction',
            linkToCareer: '/careers',
            nextLink: '/investment/data-ecosystems-and-cybersecurity',
        },
        dataEcosystems: {
            returnTo: '/investment',
            title: 'Data Ecosystems and Cybersecurity',
            directionSvg: <DirectionsSVG/>,
            shortDescribe: 'It takes many years of efforts to build your corporate reputation, and just one cyber-security incident to completely ruin it.',
            bannerImg: dataEcosystemBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'A data ecosystem is a collection of infrastructure, analytics, and applications used to capture, store and analyze data. Data ecosystems provide companies with data they rely on to make better pricing, operations, and marketing decisions. We see data ecosystems just like natural ecosystems which evolve over time. Our aim is to look deeper into:',
                        delay: 0,
                    }
                ],
                listOfLi: [
                    'Blockchain technology which allows for data transparency, safe distribution and authenticity checks;',
                    'Gathering of information based on biometric identification of humans;',
                    'DNA data storage.',
                ],
                listOfP2: [
                    {
                        text: 'What is more disturbing is that with digital economy thriving, the threat of cyber-crime is increasing every day. It is essential that organizations and individuals invent ways to protect themselves against cyber- attacks. Development of deep learning neural networks to identify anomalies and hacking attempts for banking sector, defense, healthcare, and in inter device communication (internet of things) can be a good investment of time and resources to provide for secure future.',
                        delay: 0
                    }
                ],
                listOfLi2: []
            },
            sliderImg: [dataEcosystemSlide1, dataEcosystemSlide2, dataEcosystemSlide3],
            viewCareerTitle: 'Join the core team',
            viewCareerSvg: <ViewCareer/>,
            viewCareerText: 'View Careers',
            nextDirectionTitle: 'Autonomous Vehicles',
            nextDirectionSvg: <NextDirection/>,
            nextDirectionText: 'View next direction',
            linkToCareer: '/careers',
            nextLink: '/investment/autonomous-vehicles',
        },
        vehicles: {
            returnTo: '/investment',
            title: 'Autonomous Vehicles',
            directionSvg: <DirectionsSVG/>,
            shortDescribe: 'Our aim is to help people achieve their goal to reduce pollution and make transportation sustainable',
            bannerImg: vehilesBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Smart systems are becoming an essential part of transport infrastructure, both in terms of traffic management and vehicle control. Computers are currently taking over logistics. Besides unmanned vehicles which are already on the roads, the Company is interested in driving the following developments:',
                        delay: 0,
                    }
                ],
                listOfLi: [
                    'Cars with self-charging batteries charging from an electrified road, generating energy in batteries from spare parts friction or air and road resistance;',
                    'Urban transport that goes by air;',
                    'Related industries, such as production of onboard sensors, software, automation systems, mapping, simulation & image recognition, pedestrian analysis, communication tools.',
                ],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [vehilesSlide1, vehilesSlide2, vehilesSlide3],
            viewCareerTitle: 'Join the core team',
            viewCareerSvg: <ViewCareer/>,
            viewCareerText: 'View Careers',
            nextDirectionTitle: 'Energy generation and Storage',
            nextDirectionSvg: <NextDirection/>,
            nextDirectionText: 'View next direction',
            linkToCareer: '/careers',
            nextLink: '/investment/energy-generation-and-storage',
        },
        energy: {
            returnTo: '/investment',
            title: 'Energy generation and Storage',
            directionSvg: <DirectionsSVG/>,
            shortDescribe: 'We aim at continuous research and update of energy generation technologies',
            bannerImg: energyBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Private alternative energy (micro wind generators, solar panels, etc.) will further develop. Power generation, transfer and storage processes will eventually become automated, requiring little or no human involvement.',
                        delay: 0,
                    }
                ],
                listOfLi: [
                    'New technologies of energy storage and transmission. The power supply principle of mobile devices, which are a part of our everyday life, will change, with a large portion of energy to be transmitted from the body of the user via generators sewn into clothes and footwear;',
                    'Wireless power sources and charges that can be integrated in various surfaces in the public space including wireless charging tables for restaurants, hotels and offices, special made wireless charging furniture, industrial surfaces such as wireless charging counter tops.',
                ],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [energySlide1, energySlide2, energySlide3],
            viewCareerTitle: 'Join the core team',
            viewCareerSvg: <ViewCareer/>,
            viewCareerText: 'View Careers',
            nextDirectionTitle: 'Augmented Reality',
            nextDirectionSvg: <NextDirection/>,
            nextDirectionText: 'View next direction',
            linkToCareer: '/careers',
            nextLink: '/investment/augmented-reality',
        },
        augmentedReality: {
            returnTo: '/investment',
            title: 'Augmented Reality',
            directionSvg: <DirectionsSVG/>,
            shortDescribe: 'We believe that augmented reality is one of major technological revolutions in the growing industrial digitalization, as it will change the way people visualize things',
            bannerImg: augmentedRealityBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Nowadays augmented reality (AR) brings digital overlays of contextual information or special effects onto the physical world through a smartphone. Moreover, AR and virtual reality are expected to reach potential in many more industries besides games:',
                        delay: 0,
                    }
                ],
                listOfLi: [
                    'Healthcare – projecting position of organs;',
                    'Real estate and home improvement – visualizing homes;',
                    'E-commerce – memory mirrors and virtual design and dressing rooms helping to imagine interiors and outfits;',
                    'Travel – providing itineraries and on-screen recommendations on restaurants and sightseen spots. Taking people on field trips around the world;',
                    'Avatar companions, including for disabled people.',
                ],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [augmentedRealitySlide1, augmentedRealitySlide2, augmentedRealitySlide3],
            viewCareerTitle: 'Join the core team',
            viewCareerSvg: <ViewCareer/>,
            viewCareerText: 'View Careers',
            nextDirectionTitle: 'EdTech',
            nextDirectionSvg: <NextDirection/>,
            nextDirectionText: 'View next direction',
            linkToCareer: '/careers',
            nextLink: '/investment/edtech',
        },
        edTech: {
            returnTo: '/investment',
            title: 'EdTech',
            directionSvg: <DirectionsSVG/>,
            shortDescribe: '"Technology will never replace great teachers, but technology in the hands of great teachers is transformational", - George Couros.',
            bannerImg: edTechBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Education is as old as humanity itself. Our ancestors were educated within the family or tribe, until the Greeks established the foundation of philosophical mentorship. In the twenty-first century digitization reaches more and more areas of our everyday life, including education.',
                        delay: 0,
                    },
                    {
                        text: 'EdTech is the combination of IT tools and educational practices aimed at facilitating and reforming learning. EdTech helps to shape the modern world and its further development. The use of technology affects human cognitive abilities, as the tools we use define and shape our thinking.',
                        delay: 50,
                    },
                    {
                        text: 'Different organisations take advantage of the technology to deliver education where it is most needed. However, many traditional educational institutions still hesitate to use or experiment with online courses. The Company’s goal is to contribute to further development of education technologies, provide a window to the world for many talented students, and give them access to learning resources and best learning practices.',
                        delay: 100,
                    },
                    {
                        text: 'We recognize that while EdTech encompasses many different cutting-edge technologies, from cloud-based software, scientific networking platforms, and gaming to more technical areas like VR-mediated lesson plans, there’s still a way to go for more complex EdTech solutions. Our aim is to create a great mix of best-in-class learning technologies, gaming elements, and classic pedagogic methodologies to give the students from all around the world an opportunity to acquire solid knowledge and learning skills through engaging interactive classes. We will empower both students to make discoveries in different fields of hard and natural sciences, and educators to amplify their tools and globalize their reach.',
                        delay: 150,
                    },
                ],
                listOfLi: [],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [edTechSlide1, edTechSlide2, edTechSlide3],
            viewCareerTitle: 'Join the core team',
            viewCareerSvg: <ViewCareer/>,
            viewCareerText: 'View Careers',
            nextDirectionTitle: 'Gaming',
            nextDirectionSvg: <NextDirection/>,
            nextDirectionText: 'View next direction',
            linkToCareer: '/careers',
            nextLink: '/investment/gaming',
        },
        gaming: {
            returnTo: '/investment',
            title: 'Gaming',
            directionSvg: <DirectionsSVG/>,
            shortDescribe: '"We do not stop playing because we grow old. We grow old because we stop playing", - Benjamin Franklin',
            bannerImg: gamingBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Game development is one of the fastest growing industries of computer technology as well as of the global entertainment sector.',
                        delay: 0,
                    },
                    {
                        text: 'Games become cultural phenomena and game development is considered an art. Cybersport is recognized as the sport by various countries. The number of game players grows from year to year, and game development itself has become a highly paid profession.',
                        delay: 50,
                    },
                    {
                        text: 'The gaming industry becomes a conduit for modern education, creativity and innovation. The combination of computer technology and education creates a new reality where achievements in computer games turn into capabilities evaluation metrics.',
                        delay: 100,
                    },
                    {
                        text: 'Immersive technologies (VR, AR, MR) are also game changers when it comes to teaching students future-ready skills, sparkling critical thinking, communication, and creativity, ensuring concentration and maximum involvement in the process, thereby improving efficiency and guaranteeing security.',
                        delay: 150,
                    },
                ],
                listOfLi: [],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [gamingSlide1, gamingSlide2, gamingSlide3],
            viewCareerTitle: 'Join the core team',
            viewCareerSvg: <ViewCareer/>,
            viewCareerText: 'View Careers',
            nextDirectionTitle: undefined,
            nextDirectionSvg: undefined,
            nextDirectionText: undefined,
            linkToCareer: '/careers',
            nextLink: undefined,
        },
    },
    errorMessages: {
        text: 'The field is required.',
        email: 'The e-mail address entered is invalid.',
        phoneNumber: 'The telephone number is invalid.',
        terms: 'You must accept the terms and conditions before sending your message.',
        successfully: 'Successfully sent'
    },
    cookie: {
        text: 'We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.',
        button: 'Privacy policy'
    },
    notFoundPage: {
        head1: 'This page must be playing hide and seek.',
        head2: 'Or is lost?',
        text: 'Curiosity. There is nothing more exciting than when faced with a question to which the answer is unknown to start looking for a response.',
        link: '/investment',
        linkText: 'Discover PBFS investment directions',
    }
};


export const FR = {
    language: 'FR',
    header: [
        {caption: 'Accueil', link: '/fr', toLink: '/', showInHeader: true},
        {caption: 'Investissement', link: '/fr/investissement', toLink: '/investment', showInHeader: true},
        {caption: 'À propos', link: '/fr/a-propos', toLink: '/about', showInHeader: true},
        {caption: 'ESG', link: '/fr/esg', toLink: '/esg', showInHeader: true},
        {caption: 'Fondation', link: '/fr/foundation', toLink: '/foundation', showInHeader: true},
        {caption: 'Carrières', link: '/fr/carrieres', toLink: '/careers', showInHeader: true},
        {caption: 'Contact', link: '/fr/contact', toLink: '/contact', showInHeader: true},
        {caption: 'Terms', link: '/fr/conditions-generales', toLink: '/terms-conditions', showInHeader: false},
        {caption: 'Policy', link: '/fr/politique-de-confidentialite', toLink: '/privacy-policy', showInHeader: false},
        {
            caption: 'Neuromathematics',
            link: '/fr/domaines/neuromathematics-and-neurobiology',
            toLink: '/directions/neuromathematics-and-neurobiology',
            showInHeader: false
        },
        {caption: 'Biopharma', link: '/fr/domaines/biopharma', toLink: '/directions/biopharma', showInHeader: false},
        {caption: 'Tintech', link: '/fr/domaines/fintech', toLink: '/directions/fintech', showInHeader: false},
        {
            caption: 'Cybersecurity',
            link: '/fr/domaines/data-ecosystems-and-cybersecurity',
            toLink: '/directions/data-ecosystems-and-cybersecurity',
            showInHeader: false
        },
        {
            caption: 'Vehicles',
            link: '/fr/domaines/autonomous-vehicles',
            toLink: '/directions/autonomous-vehicles',
            showInHeader: false
        },
        {
            caption: 'Energy',
            link: '/fr/domaines/energy-generation-and-storage',
            toLink: '/directions/energy-generation-and-storage',
            showInHeader: false
        },
        {
            caption: 'Augmented reality',
            link: '/fr/domaines/augmented-reality',
            toLink: '/directions/augmented-reality',
            showInHeader: false
        },
        {caption: 'Edtech', link: '/fr/domaines/edtech', toLink: '/directions/edtech', showInHeader: false},
        {caption: 'Gaming', link: '/fr/domaines/gaming', toLink: '/directions/gaming', showInHeader: false},
    ],
    aboutBlock: {
        text1: "Ce que dit la raison: «C’est impossible»",
        text2: "Ce que suggère l’expérience: «C’est bien trop risqué»",
        text3: "Notre réponse:",
        text4: "«Essayons!»",
        text5: "Dans un monde en constante fluctuation, il est essentiel d’être toujours à la recherche des principes universels qui relient toutes choses. De nouvelles idées apparaissent en permanence, mais les lois fondamentales de la physique et des mathématiques restent les mêmes. Chez PBFS, nous générons des idées qui mènent à des solutions innovantes. ",
        text6: "Découvrez PBFS ",
    },
    careerBlock: {
        text1: 'Rejoignez',
        text2: 'Notre équipe est constituée de professionnels passionnés et unis pour concevoir des idées innovantes et développer des solutions d’excellence. Ensemble, nous menons des réflexions, nous travaillons intensément, nous nous soutenons dans les moments difficiles et célébrons nos succès. Ensemble, nous façonnons l’avenir afin qu’il devienne meilleur.',
        text3: 'Voir les carrières '
    },
    contactBlock: {
        text1: 'Vous êtes ',
        text2: 'passionné(e)',
        text3: ' par votre sujet de recherche, vous êtes à la tête d’une startup et vous recherchez des investisseurs? ',
        text4: 'Contactez-nous'
    },
    esgPage: {
        header: {
            title: {
                startText: "Chez PBFS, nous croyons que l’actionnariat ",
                colorfulText: "responsable ",
                endText: "et le succès commercial vont de pair"
            },
            subtitle: [
                "La mission de PBFS est d'assurer une croissance durable en créant de la valeur pour nos actionnaires et pour la société, tout en réduisant l'impact environnemental dans tous les domaines de notre activité. Nous investissons dans des nouvelles technologies révolutionnaires qui pourront soutenir une économie à faible émission de carbone.",
                "Nous sommes convaincus que les problématiques commerciales ainsi que les critères environnementaux, sociaux et de gouvernance (ESG) sont tout aussi importants pour la durabilité à long terme d’une organisation. Leur rencontre permet d’apporter une valeur ajoutée à notre entreprise et à la société."
            ]
        },
        factors: {
            title: "Critères ESG",
            subtitle: "La société s'engage à appliquer des pratiques commerciales axées sur l'ESG, y compris, mais sans s'y limiter, à la prise de décisions d'investissement responsable, à l'évaluation des performances et à la réputation de toutes ses décisions commerciales.",
            factorsList: [
                {
                    transition: '50',
                    boldText: "Critères environnementaux",
                    text: "Nous cherchons à intégrer les critères environnementaux dans tout ce que nous faisons. Il est de notre responsabilité de minimiser l'impact de nos activités sur l'énergie, le carbone, l'eau et les déchets. Les orientations clés de nos activités se concentrent sur l'investissement dans des entreprises qui contribuent à la neutralité carbone, qui se spécialisent dans les technologies d'énergie renouvelable et dans les technologies propres, dans des entreprises axées sur les principes d’une pratique saine sur le plan écologique, ainsi que dans les entreprises portant sur la gestion responsable des déchets."
                },
                {
                    transition: '150',
                    boldText: "Critères sociaux",
                    text: "PBFS s'engage à respecter les principes de diversité et d'inclusion, de non-discrimination et d'égalité des chances. Notre succès est basé sur le respect mutuel, sur la compréhension de la valeur de contribution de chacun d'entre nous et sur le traitement équitable de chaque employé. Notre société cherche à offrir des conditions de travail confortables et sûres à son personnel et s'efforce de développer pleinement les qualités professionnelles de ses employés."
                },
                {
                    transition: '250',
                    boldText: "Critères de gouvernance",
                    text: "Une gouvernance solide, des pratiques commerciales hautement éthiques et une gestion prudente des risques sont les ingrédients essentiels à la réalisation de nos objectifs commerciaux. Cela implique une approche saine de la gouvernance d'entreprise, conforme à l'ensemble des lois, règles, réglementations et politiques applicables, ainsi qu'une adhésion inconditionnelle à nos valeurs. La société encourage une culture ouverte au sein de son organisation, l'éthique commerciale, l'intégrité et la transparence, l'établissement de rapports et la conformité étant essentiels à la manière dont la société exerce ses activités."
                }
            ]
        }
    },
    foundationPage: {
        text1: 'Nous sommes persuadés que la ',
        text2: 'mission sociale ',
        text3: 'de l\'entreprise devrait être implémentée dans toutes les activités et ne doit pas être entreprise à des fins publicitaires',
        text4: 'La 𝘱 Foundation est une organisation caritative à but non lucratif, indépendante de PBFS. Elle soutient des projets innovants dans les domaines de l\'éducation, de l\'environnement, de l\'aide humanitaire, de la sécurité alimentaire et de la recherche médicale.',
        text5: 'Notre objectif est d\'améliorer les performances dans chaque secteur d’activité choisi, d\'élargir l\'accès et l\'inclusion, de favoriser l\'innovation et de catalyser l\'accès au financement. Nous investissons dans des projets à l’échelle mondiale en développant des solutions évolutives qui répondent aux besoins sociétaux élevés. Les projets que nous finançons sont encadrés par les objectifs de développement durable (ODD).',
        criteriaTitle: 'Notre approche',
        criteriaTitleText: 'Notre approche de dons philanthropiques et du parrainage non-commercial reflète notre modèle d\'entreprise durable et notre culture de l’innovation. Nos critères de sélection reposent sur les principes suivants :',
        criteria: [
            {
                title: "Accent mis sur l'impact",
                description: "La 𝘱 Foundation met l’accent sur les projets qui répondent à des défis sociétaux et apportent une réelle différence grâce à l'innovation, la collaboration, la qualité et la durabilité. Durant la phase de l’évaluation, nous nous concentrons, non pas sur son coût, mais sur l'impact potentiel et les améliorations durables qu’un projet pourrait apporter à la société.",
                transition: "50"
            },
            {
                title: "Engagement et orientation",
                description: "L’objectif de 𝘱 Foundation est d’être un partenaire fiable, actif et à long terme. Nous concentrons dès lors nos ressources sur un petit nombre de projets sélectionnés et préfère s'engager au stade le plus précoce d’un projet afin d'optimiser l'utilisation des ressources disponibles.",
                transition: "150"
            },
            {
                title: "Implication",
                description: "La 𝘱 Foundation cherche à consacrer ses ressources à des projets où son implication pourrait amener à des changements significatifs. Nous sommes d’avis que le partage d'expertise et du savoir-faire, ainsi que des idées et des talents humains peuvent être bien plus efficaces et durables qu'une simple contribution financière.",
                transition: "250"
            },
            {
                title: "Conformité",
                description: "Nos dons philanthropiques ne visent pas à concurrencer ni à remplacer les programmes gouvernementaux, mais à soutenir des projets approuvés et acceptés localement. La 𝘱 Foundation respecte la gouvernance, ainsi que la culture et l'indépendance locales et adhère aux lois et règlementations applicables.",
                transition: "350"
            },
        ],
    },
    projectBlock: {
        text1: 'La science',
        text2: '. est capable de grandes choses. En la mettant en pratique, nous pouvons en réaliser encore davantage',
        text3: '.',
        text4: 'Découvrez tous nos domaines de recherche',
    },
    videoBlock: {
        text1: 'Intelligence Artificielle et ',
        text2: 'la Science, la Recherche et Développement',
        text3: 'Découvrez les domaines de recherche de PBFS',
    },
    sliderText: [
        {
            title: 'Découvrir la direction',
            name: 'Neuromathématiques et Neurobiologie',
            descriptions: [
                'Les sciences cognitives et l’étude du cerveau humain',
                'La combinaison des trois domaines des neurosciences, de la biologie et de l’informatique',
                'Le diagnostic médical et la médecine de précision',
            ],
            img: neuromathematicksBanner,
            url: '/fr/investissement/neuromathematics-and-neurobiology'
        },
        {
            title: 'Découvrir la direction',
            name: 'Biopharma',
            descriptions: [
                'Sources biologiques comme étant le mode d’emploi du fonctionnement et de la guérison des cellules du corps humain',
                'Les médicaments biologiques bio-similaires',
                'L’évolution des technologies biologiques de traitement',
            ],
            img: biopharmaBanner,
            url: '/fr/investissement/biopharma'
        },
        {
            title: 'Découvrir la direction',
            name: 'FinTech',
            descriptions: [
                'Combinaison réussie de recherche scientifique et applications pratiques de modèles',
                'L’utilisation du machine learning et du traitement du langage naturel',
                'La gestion de patrimoine basée sur des solutions exploitant l’intelligence artificielle',
            ],
            img: fintechBanner,
            url: '/fr/investissement/fintech'
        },
        {
            title: 'DISCOVER DIRECTION',
            name: 'Écosystèmes de données et Cybersécurité',
            descriptions: [
                'Infrastructure, analyse et applications utilisées pour capturer, stocker et analyser des données',
                'Une meilleure transparence des données, une distribution sécurisée et des garanties d’authenticité',
                'Сonception de nouvelles parades pour se prémunir contre les cyber attaques',
            ],
            img: dataEcosystemBanner,
            url: '/fr/investissement/data-ecosystems-and-cybersecurity'
        },
        {
            title: 'Découvrir la direction',
            name: 'Véhicules autonomes',
            descriptions: [
                'Les systèmes intelligents comme une composante essentielle des infrastructures de transport',
                'Les voitures avec batteries capables de se recharger elles-mêmes',
                'Les transports urbains par les airs',
            ],
            img: vehilesBanner,
            url: '/fr/investissement/autonomous-vehicles'
        },
        {
            title: 'Découvrir la direction',
            name: 'Energy generation and Storage',
            descriptions: [
                'Automated power generation, transfer and storage processes',
                'New technologies of energy storage and transmission',
                'Wireless power sources and charges',
            ],
            img: energyBanner,
            url: '/fr/investissement/energy-generation-and-storage'
        },
        {
            title: 'Découvrir la direction',
            name: 'Production et stockage d’énergie',
            descriptions: [
                'Les processus automatisés de production, de transport et de stockage d’énergie',
                'Les nouvelles technologies de stockage et de transport d’énergie',
                'Les sources d’énergie sans fil',
            ],
            img: augmented,
            url: '/fr/investissement/augmented-reality'
        },
        {
            title: 'Découvrir la direction',
            name: 'EdTech',
            descriptions: [
                'Creation of an online educational platform comprising interactive learning tools and VR sessions',
                'A mix of cutting-edge technologies, gaming and classic teaching methodologies',
                'Making discoveries in different fields of hard and natural sciences',
            ],
            img: edTech,
            url: '/fr/investissement/edtech'
        },
        {
            title: 'Découvrir la direction',
            name: 'Gaming',
            descriptions: [
                'New generation of economic games',
                'Combination of computer technology and education',
                'Immersive technologies in teaching students',
            ],
            img: gamingBanner,
            url: '/fr/investissement/gaming'
        },
    ],
    foundationSliderText: [
        {
            // title: 'DISCOVER DIRECTION',
            name: 'Enseignement de qualité',
            descriptions: [
                'Encourager la transformation du système éducatif',
                'Développer un nouveau type d\'école pour les enfants avec des capacités académiques et un potentiel de leaderships exceptionnels',
                'Apporter un soutien financier aux enfants surdoués qui manquent de moyens financiers, les aider à atteindre leur plein potentiel',
            ],
            img: education,
            // url: '/investment/neuromathematics-and-neurobiology'
        },
        {
            // title: 'DISCOVER DIRECTION',
            name: 'Sécurité alimentaire',
            descriptions: [
                "Formations de renforcement des compétences et accès à l'agritech pour les petites entreprises d'agriculture écologique ",
                'Subventions pour des initiatives de R&D sur les cultures résilientes au changement climatique',
                'Subventions pour des solutions scientifiques et technologiques permettant la progressivité des régions rurales',
            ],
            img: foodSecurity,
            // url: '/investment/neuromathematics-and-neurobiology'
        },
        {
            // title: 'DISCOVER DIRECTION',
            name: 'Aide aux problèmes de santé graves',
            descriptions: [
                'Soutenir la recherche médicale visant à développer et élaborer des traitements pour les maladies graves',
                'Soutenir le développement de technologies émergentes qui pourraient être adoptées pour répondre aux grands défis mondiaux de la santé',
                'Investissements ciblés à court terme pour déterminer la viabilité des innovations dans des domaines spécifiques de la santé mondiale',
            ],
            img: healthProblems,
            // url: '/investment/neuromathematics-and-neurobiology'
        },
        {
            // title: 'DISCOVER DIRECTION',
            name: 'Protection de la Planète',
            descriptions: [
                'Financer des projets innovants et durables qui font progresser l\'équité en matière de climat et d’énergie propre',
                'Présenter la valeur de "l\'économie circulaire" dans les pays à faible revenu',
                'Soutenir les campagnes de sensibilisation au recyclage et à la réduction des déchets',
            ],
            img: planetProtection,
            // url: '/investment/neuromathematics-and-neurobiology'
        },
        {
            // title: 'DISCOVER DIRECTION',
            name: 'Aide humanitaire',
            descriptions: [
                'Accompagnement psychosociale des victimes des guerres et des conflits militaires',
                'Aide aux réfugiés à acquérir les compétences nécessaires pour accéder au marché du travail local',
                "Faciliter l'accès au financement et aux services médicaux pour les enfants et les adolescents qui ont perdu des membres pendant la guerre et les conflits militaires"
            ],
            img: humanitarian,
            // url: '/investment/neuromathematics-and-neurobiology'
        },
    ],
    footer: {
        leftSide: [
            {text: 'À propos', link: '/a-propos'},
            {text: 'Investissement', link: '/fr/investissement'}
        ],
        rightSide: [
            {text: 'Carrières', link: '/carrieres'},
            {text: 'Contact', link: '/contact'}
        ],
        terms: 'Conditions générales',
        linkToTerms: '/fr/conditions-generales',
        text: '© PBFS 2023',
        policy: 'Politique de confidentialité',
        linkToPolicy: '/fr/politique-de-confidentialite',
    },
    directionsPageInfo: {
        text1: 'Nous investissons dans des ',
        text2: 'start-ups ',
        text3: 'animées des plus grandes ambitions pour avoir un réel impact sur le monde.',
        text4: 'Nous faisons équipe avec des esprits entrepreneuriaux aux idées les plus créatives, dynamisés par leur mission sans que rien ne les arrête.',
        text5: 'Notre processus d\'investissement est rapide, discipliné et transparent. Nous considérons les entrepreneurs comme nos partenaires et leur donnons les moyens pour démarrer leurs activités à petite échelle, apprendre rapidement et de se développer davantage.',
        text6: "PBFS a investi dans",
        text7: "entreprises éminents et poursuit la recherche de fondateurs ambitieux avec des grandes idées qui révolutionnent la marché.",
        criteriaTitle: 'Nos critères d\'investissement sont les suivants:',
        criteria: [
            {
                title: "Innovation",
                description: "Nous sommes convaincus que l'avenir sera façonné par les avancées technologiques, raison pour laquelle nous investissons dans des produits réellement innovants.",
                transition: "50"
            },
            {
                title: "Impact",
                description: "Nous donnons priorité aux idées exceptionnelles avec le potentiel de devenir des entreprises pouvant avoir un impact important sur le futur.",
                transition: "150"
            },
            {
                title: "Personnalité",
                description: "Des entrepreneurs volontaires, visionnaires, aux idées sortant des cadres conventionnels.",
                transition: "250"
            },
            {
                title: "Évaluation",
                description: "Nous investissons dans des startups en phase de démarrage, de pré-amorçage, d’amorçage ou Serie A. Nous vous accompagnons tout au long du cycle de développement.",
                transition: "350"
            },
            {
                title: "Géographie",
                description: "Nous nous concentrons sur des entreprises situées essentiellement en Suisse, en Israël et en Europe occidentale.",
                transition: "450"
            }
        ],
        timeline: [
            {date: "Octobre 2021 ", text: "Véhicules autonomes"},
            {date: "Novembre 2021 ", text: "Agritech"},
            {date: "Janvier 2022 ", text: "Medtech"},
            {date: "Janvier 2022 ", text: "Fintech"},
            {date: "Mars 2022 ", text: "Biotechnologies"},
            {date: "Avril 2022 ", text: "Analyse des données"},
        ],
        members: {
            header: "PBFS est un member de :"
        }
    },
    aboutPageInfo: {
        text1: 'Il existe une forme d’ordre dans l’univers, et nous aimons à dire que les  ',
        text2: 'mathématiques',
        text3: ' en facilitent la compréhension',
        text4: ['La science et la technologie sont au cœur de notre activité.'],
    },
    aboutPageCoreValue: {
        text1: 'Nos valeurs fondamentales nous rassemblent',
        text2: 'Nos valeurs fondamentales sont les piliers sur lesquelles nous avons construit notre identité et nos aspirations. ',
        textList: [
            {
                boldText: 'Performance, efficience et fiabilité',
                text: 'Nos projets rassemblent les intérêts de personnes et d’entreprises ayant des origines et des objectifs divers. Nous travaillons chaque jour pour faire en sorte que nos décisions soient les meilleures possibles et apportent une valeur ajoutée à nos partenaires sur le long terme. ',
                transition: '50',
            },
            {
                boldText: 'Engagement pour l’innovation et l’excellence',
                text: 'Chez PBFS, nous sommes convaincus que la recherche fondamentale et l’expérimentation sont les moteurs de la croissance et des sources d’opportunités nouvelles. Nous faisons toujours en sorte de soutenir les idées innovantes viables, et avons pour but de créer une vaste communauté d’investisseurs et de partenaires qui feront ces décisions d’innovation une réalité.',
                transition: '150',
            },
            {
                boldText: 'Respect et soutien mutuel',
                text: 'Nous traitons tous nos collaborateurs et nos partenaires avec professionnalisme, dignité et respect, afin de créer un environnement où les individus peuvent travailler de concert, entraîner le changement et devenir meilleurs. Nous montrons notre appréciation à nos collaborateurs, nous les soutenons et nous sommes prêts à les aider à prendre des initiatives et à les faire progresser dans leur carrière.',
                transition: '250',
            },
        ],
    },
    projectInspire: {
        text1: 'Nos ',
        text2: 'projets',
        text3: ' nous inspirent',
        text4: 'Notre stratégie consiste à investir dans un avenir où les décisions pertinentes, la gestion efficiente des ressources, et des vies allongées et en pleine santé seront universelles. Nous voulons aborder l’année 2035 avec un large portefeuille de projets allant de pair avec nos connaissances et les résultats de nos recherches.',
        blocks: [
            {
                delay: '0',
                header: 'Recherche, analyses et valorisation',
                contents: [
                    {
                        type: 'text',
                        value: 'Nos recherches et analyses sont fondées sur la collecte automatique de données, l’analyse et la prédiction des interrelations entre les données par le biais d’outils d’intelligence artificielle.'
                    },
                    {type: 'strongText', value: 'Nous utilisons des algorithmes uniques pour pratiquer:'},
                    {
                        type: 'list', value: [
                            'De la recherche industrielle et économique;',
                            'Des analyses et de la recherche technique, notamment par la modélisation de tendances et de prévisions;',
                            'Les fondamentaux comme la valorisation d’une société, ses résultats et ratios, son indice de risque de faillite, son indice ESG;',
                            'La création d’un modèle précis de mesure du risque qui peut être appliqué à toute entreprise dans un secteur particulier.'
                        ]
                    },
                    {
                        type: 'footer',
                        value: 'PBFS a développé en interne des modèles d’intelligence artificielle capables d’analyser de manière impartiale les marchés et les techniques de valorisation. Le modèle Real PRAITIKS Value (RPV) et un modèle p-bankruptcy ont en effet déjà été établis pour identifier respectivement la liquidité nette d’une entreprise et son indice de risque de faillite.'
                    }
                ]
            },
            {
                delay: '25',
                header: 'Filtrage d’articles de presse',
                contents: [
                    {
                        type: 'text',
                        value: 'Nous collectons et analysons des données issues de différents médias d’actualité pour générer des éclairages utiles à la prise de décision. Nous effectuons une analyse de sentiment et filtrons les articles afin de permettre des analyses par secteur et par entreprise, et pour comprendre les tendances futures de secteurs spécifiques.'
                    },
                    {
                        type: 'list', value: [
                            'Utilisation d’analyses d’articles de presse dans les modèles d’intelligence artificielle afin d’affiner les modèles prévisionnels;',
                            'Utilisation des dernières techniques de machine learning pour obtenir des informations plus rapidement et plus précisément, et pour distinguer les informations pertinentes et vérifiées;',
                            'Création de contenus impartiaux grâce à la création d’un système d’assistant d’actualité basé sur l’intelligence artificielle, et ce pour alimenter ces mêmes modèles d’intelligence artificielle;',
                            'Création de flux d’actualité personnalisés pour créer une expérience de consommation d’actualités sans friction et avec du sens.'
                        ]
                    },
                ]
            },
            {
                delay: '50',
                header: 'Financement en capital-risque et en fonds propres',
                contents: [
                    {
                        type: 'text',
                        value: 'PBFS est constamment à l’affût des dernières innovations techniques et des technologies disruptives de demain. Nos décisions d’investissement en fonds propres seront basées sur une analyse attentive des investissements potentiels, de leurs perspectives de part de marché, des capacités des équipes, et de la cohérence avec nos objectifs d’investissement. Nos axes d’investissement principaux comprennent:'
                    },
                    {
                        type: 'list', value: [
                            'Les neuromathématiques et la neurobiologie',
                            'Les médicaments biologiques',
                            'Les écosystèmes de données et la Cybersécurité',
                            'Les véhicules autonomes',
                            'La production et le stockage d’énergie',
                            'La réalité augmentée',
                            'La FinTech',
                            'La EdTech',
                            'Le Gaming',
                        ]
                    },
                    {
                        type: 'footer', value: {
                            text: "PBFS est un member de :",
                            imgs: [
                                {
                                    svg: <SecaLogo/>,
                                    link: "https://www.seca.ch/"
                                },
                                {
                                    svg: <SicticLogoWhite/>,
                                    link: "https://www.sictic.ch/"
                                },
                            ]
                        }
                    }
                ]
            },
            {
                delay: '75',
                header: 'Responsabilité Sociale des Entreprises et Investissement durable',
                contents: [
                    {
                        type: 'text',
                        value: 'Dans le cadre de son engagement permanent en faveur d’un comportement d’entreprise responsable, PBFS a créé la 𝘱 Foundation, une organisation caritative à but non lucratif. Notre approche en matière de dons philanthropiques et du parrainage non-commercial reflète notre modèle d\'entreprise et notre culture d\'innovation.'
                    },
                    {
                        type: 'text',
                        value: 'Nous concentrons nos efforts et ressources sur un nombre limité de projets qui, grâce à nos contributions, peuvent avoir un impact important sur la société et offrir des avantages considérables. Nous privilégions les projets innovants et de qualité dans les domaines suivants :'
                    },
                    {
                        type: 'list', value: [
                            'Éducation',
                            'Sécurité alimentaire',
                            'Recherche médicale',
                            'Environnement',
                            'Aide humanitaire',
                        ]
                    },
                    {
                        type: 'text',
                        value: 'Les projets que nous finançons sont encadrés par les Objectifs de Développement Durable (ODD). Au cours du processus de sélection, nous démontrons notre engagement en faveur des activités  responsables sur le plan environnemental et social.',
                    },
                    {
                        type: 'footer', value: {
                            text: "En savoir plus:",
                            imgs: [
                                {
                                    svg: <FoundationLight/>,
                                    link: "/fr/foundation"
                                }
                            ]
                        }
                    }
                ]
            },
        ],
    },
    timeLineVision: {
        text1: 'Notre ',
        text2: 'vision',
        text3: ', sous forme chronologique',
        text4: 'Le temps file, et nous imaginons un avenir dans lequel les technologies disruptives sont au premier plan.',
        list: [
            {
                bold: 'Mars 2018',
                text: 'PBFS SA est née',
            },
            {
                bold: 'Avril-Décembre 2018',
                text: 'Composition de l’équipe avec des collaborateurs motivés et pleins d’énergie',
            },
            {
                bold: 'Août 2018',
                text: 'PBFS Vision 2035, ses objectifs et ses axes d’investissement principaux sont définis',
            },
            {
                bold: 'Janvier 2019',
                text: 'Notre premier produit de FinTech est lancé',
            },
            {
                bold: 'Février 2019',
                text: 'Début du développement de PRAITIKS, un outil d’intelligence artificielle pour la systématisation des données et l’analyse financière des entreprises, des industries et des pays',
            },
            {
                bold: 'Mai 2019',
                text: 'Le projet de filtrage d’articles de presse est démarré',
            },
            {
                bold: 'Juillet-Décembre 2019',
                text: 'Recherche en interne d’un modèle sur les faillites, développement et tests',
            },
            {
                bold: 'Novembre 2020',
                text: 'Le développement d’un jeu mobile avec des aspects éducatifs a été lancé',
            },
            {
                bold: 'Janvier 2021 - Avril 2021',
                text: 'Des projets liés aux énergies renouvelables et à la gestion des déchets ont été lancés',
            },
            {
                bold: 'Juillet 2022',
                text: "Nous avons créé PBFS Israel et un centre de recherche et développement local",
            },
            {
                bold: 'Août 2022 - suite',
                text: 'Nous n’abandonnerons pas tout le secret, mais bien plus est encore à venir',
            },
            {
                bold: '2035',
                text: 'La vision de PBFS devient réalité',
            },
        ]
    },
    careersInfoBlock: {
        text1: 'Rejoignez',
        text2: 'Notre équipe est constituée de professionnels passionnés et unis pour concevoir des idées innovantes et développer des solutions d’excellence. Ensemble, nous menons des réflexions, nous travaillons intensément, nous nous soutenons dans les moments difficiles et célébrons nos succès. Ensemble, nous façonnons l’avenir afin qu’il devienne meilleur.',
    },
    careersPositions: careerPositions(),
    careerPage: {
        openedPosition: 'Postes ouverts',
        filters: [
            'Job Function',
            'Project',
            'Direction',
        ],
        clearFilter: 'Effacer le filtre',
        filterOpenedPositionText: 'Filtrer les carrières ouvertes'
    },
    contactForm: {
        title: 'Appliquer maintenant',
        email: 'Votre email',
        yourName: 'Votre nom',
        firstName: 'Entrez votre prénom',
        secondName: 'Entrez votre nom',
        phone: 'Entrez votre téléphone',
        enterMessage: 'Entrez votre message',
        message: 'Message au responsable du recrutement',
        cv: 'Joindre CV et lettre de motivation',
        terms1: `J'accepte les `,
        terms2: 'termes et conditions',
        termsLink: '/fr/conditions-generales',
        sendMail: 'Envoyer la demande',
        sendMessage: 'Envoyer le message',
    },
    contactPage: {
        defaultSubjects: [
            {title: 'Choisissez un sujet', selected: true},
            {title: 'Domaines', selected: false},
            {title: 'Projets', selected: false},
            {title: 'Plaintes', selected: false},
            {title: 'Autre', selected: false},
        ],
        text1: 'Êtes-vous ',
        text2: 'passionné(e)',
        text3: ' par votre sujet de recherche, êtes à la tête d’une startup et recherchez des investisseurs?',
        text4: 'Nous parlons généralement pour être écoutés, pas simplement entendus. Chez PBFS, nous comprenons que la communication doit aller dans les deux sens et nous faisons donc tout pour créer une culture où chaque partenaire est écouté.',
        banner: {
            text1: 'Adresse: ',
            text2: '4-6 Place de l’Université, 1205 Genève, Suisse',
            text3: 'Téléphone: ',
        }
    },
    termsAndConditionsPage: {
        title: "Conditions générales",
        texts: [
            {
                p: [
                    "Conditions générales d’utilisation du site Web de PBFS :",
                    "Bienvenue sur le site Web de PBFS. Les présentes conditions générales du site Web PBFS (ci-après, les « Conditions générales») régissent son utilisation, accessible à l'adresse #https://pbfs.swiss/# (ci-après, le « Site Web »), quel que soit le mode d'accès.",
                    "Ces Conditions générales seront intégralement appliquées et affecteront l'utilisation de ce Site Web. Veuillez lire attentivement ces Conditions générales avant d'accéder à tout matériel sur ce Site Web. En accédant à ce Site Web et en l’utilisant, vous reconnaissez avoir lu et acceptez d'être lié par toutes les conditions énoncées ici."
                ]
            },
            {
                title: "Droits de Propriété Intellectuelle",
                p: [
                    "En vertu des présentes Conditions générales, PBFS SA et/ou ses sociétés affiliées et/ou concédants de licence détiennent tous les droits de propriété intellectuelle et les éléments figurant dans le présent Site Web, y compris, mais sans s'y limiter, les informations, le contenu, le logo, les produits et autres médias, les graphiques, les dessins et /ou les marques déposées (ci-après - le \"Contenu\"). Des droits limités vous sont accordés exclusivement à des fins de consultation strictement privée et personnelle du Contenu de ce Site Web. Vous n'acquérez aucun droit en reproduisant, copiant ou téléchargeant de quelque manière que ce soit le contenu disponible sur ce Site Web ; tous les droits sont conservés par PBFS SA et/ou ses sociétés affiliées et/ou concédants de licence.",
                    "Les matériaux affichés sur ce Site Web ne peuvent être utilisés d'aucune autre manière sans le consentement écrit préalable de PBFS SA.",
                    "il vous est expressément interdit d’utiliser les éléments suivants :"
                ],
                ul: [
                    "publier tout contenu du Site Web dans tout autre média;",
                    "modifier le Contenu ou l'utiliser à d'autres fins, ou l'utiliser sur tout autre site Internet ou réseau informatique sans le consentement écrit préalable de PBFS SA;",
                    "vendre, sous-licencier et/ou commercialiser de toute autre manière le contenu du Site Web;",
                    "exécuter publiquement et/ou montrer tout contenu du Site Web, à des fins commerciales;",
                    "utiliser ce Site Web d'une manière qui est ou peut lui être préjudiciable;",
                    "utiliser ce Site Web d'une manière qui affecte l'accès des ses utilisateurs;",
                    "utiliser ce Site Web en violation des lois et réglementations applicables, ou d'une manière susceptible de nuire au Site Web ou à d’autres utilisateurs.",
                    "s'engager dans une exploration de données, une extraction de données ou toute autre activité similaire en relation avec ce Site Web;",
                    "utiliser ce Site Web pour faire de la publicité ou du marketing."
                ]
            },
            {
                title: "Contenu du Site Web",
                p: [
                    "L'accès à certaines parties de ce Site Web est restreint, et PBFS SA peut restreindre davantage l'accès à toutes les parties de ce Site Web, à tout moment, à son entière discrétion. Toutes les dispositions des Conditions générales survivent à la résiliation de l'accès, y compris, sans s'y limiter, les droits de propriété intellectuelle, les exclusions de garantie et les limitations de responsabilité.",
                    "Ce Site Web, ainsi que l’ensemble du contenu, des informations et des documents qu'il contient, sont fourni \"tel quel\" avec tous les défauts, le cas échéant, et PBFS SA n'exprime aucune déclaration et n’offre aucune garantie, de quelque nature que ce soit, en ce qui concerne le présent Site Web ou  son contenu. En outre, aucun contenu élément contenu dans ce Site Web ne doit être interprété comme un conseil à quiconque ni constituer une sollicitation ou une offre. L’ensemble du contenu, y compris les informations et documents publiés ou mis à disposition sur le Site Web sont fournis à titre informatif, exclusivement pour un usage non commercial, strictement privé et personnel.",
                    "PBFS SA se réserve le droit de changer, modifier ou supprimer tout ou partie du Contenu du Site Web à tout moment ou pour quelque raison que ce soit, à la seule discrétion de PBFS SA, sans préavis. Cependant, PBFS SA n'a aucune obligation de mettre à jour les informations sur le Site Web. Il vous incombe de consulter régulièrement ces Conditions générales afin de prendre connaissance de toute modification qui y sera apportée. L'accès continu au Site Web après toute modification des présentes Conditions générales constitue votre acceptation des conditions telles que modifiées. Si vous n'acceptez pas ces conditions, vous ne pouvez pas utiliser le Site Web.",
                    "PBFS SA se réserve également le droit de modifier ou d'interrompre tout ou partie du Site Web sans préavis et à tout moment. PBFS SA ne sera pas responsable envers vous ou tout tiers pour toute modification, suspension ou interruption du Site Web.",
                    "Le Site Web peut contenir des informations contenant des erreurs typographiques, des inexactitudes ou des omissions, y compris des descriptions et diverses autres informations. PBFS SA se réserve le droit de corriger toute erreur, omission ou inexactitude et de modifier et mettre à jour les informations sur le Site Web à tout moment, sans préavis. PBFS SA ne garantit pas l'exactitude, l'actualité, l'exhaustivité, la sécurité ou la fiabilité du Site Web ou du contenu, y compris les informations ou les résultats obtenus par l'utilisation du Site Web. PBFS SA ne sera en aucun cas responsable des dommages, quels qu’ils soient, résultant de l'utilisation du Contenu du Site Web. PBFS SA décline également toute responsabilité si l'utilisation de ces informations contrevenait à un brevet, un droit d'auteur ou une marque déposée.",
                    "PBFS SA ne peut garantir que le Site Web sera disponible à tout moment. PBFS SA peut rencontrer des problèmes matériels, de logiciel ou autres, devoir procéder à la maintenance du Site Web, entraînant des interruptions, des retards ou des erreurs. Vous acceptez que PBFS SA n'assume aucune responsabilité pour toute perte, dommage ou inconvénient causé par votre incapacité à accéder ou à utiliser le Site Web pendant un temps d’arrêt ou une interruption du Site Web. Aucune disposition des présentes Conditions générales ne peut être interprétée comme obligeant PBFS SA à maintenir et à soutenir le Site Web ou à fournir des corrections, des mises à jour ou des communiqués à cet égard."
                ]
            },
            {
                title: "Exclusion de Responsabilité",
                p: [
                    "En aucun cas, PBFS SA, ni aucun de ses actionnaires, dirigeants, agents, administrateurs et employés, ne pourront être tenus responsables de quoi que ce soit découlant ou lié de quelque manière que ce soit à l’utilisation et à l’accès à ce Site Web, y compris son contenu, à l'utilisation de informations et services disponibles sur le Site Web, ou l'impossibilité d'utiliser ou d'accéder au Site Web ou à toute information ou service disponible sur le Site Web. Votre utilisation du Site Web est à vos propres risques. PBFS SA, y compris ses actionnaires, dirigeants, agents, administrateurs et employés, ne peuvent être tenus responsables de toute réclamation ou dommage direct ou indirect, consécutif ou spécial, de quelque nature que ce soit, contractuel ou délictuel, y compris la négligence, découlant de l'utilisation de ce Site Web ou y étant lié de quelque manière que ce soit, ou de tout lien vers des sites web tiers.",
                    "Vous acceptez et reconnaissez que les transmissions par Internet ne peuvent pas être complètement privées ou sécurisées. Vous comprenez que tout message ou information que vous envoyez au Site Web peut être lu ou intercepté par d'autres, même si la protection des informations qui vous identifient personnellement est de la plus haute importance pour PBFS SA. PBFS SA ne garantit ni ne déclare que l'utilisation du Site Web est protégée contre les virus, les menaces de sécurité ou d'autres vulnérabilités."
                ]
            },
            {
                title: "Liens",
                p: [
                    "PBFS SA décline formellement toute responsabilité quant aux sites web de tiers et à leur contenu qui sont liés au présent Site Web. Ces contenus et liens sont fournis uniquement pour votre commodité et votre information. PBFS SA n’exerce aucun contrôle, n'assume aucune responsabilité et ne donne aucune garantie ni ne fait aucune déclaration concernant les sites web de tiers, y compris, mais sans s'y limiter, l'exactitude, le sujet, la qualité ou la légalité du contenu de ces sites web. La décision d'activer ces liens vous appartient exclusivement. En activant ces liens, vous êtes responsable de la lecture et du respect des conditions générales et des politiques de confidentialité des sites web tiers."
                ]
            },
            {
                title: "Politique de Confidentialité",
                p: [
                    "PBFS SA a établi une politique de confidentialité, telle que modifiée de temps à autre, qui explique comment les données personnelles sont collectées sur le présent Site Web et comment elles sont utilisées. La politique de confidentialité fait partie des présentes Conditions générales et y est incorporée par renvoi. #https://pbfs.swiss/privacy-policy#."
                ]
            },
            {
                title: "Divisibilité",
                p: [
                    "Si une disposition de présentes Conditions générales est jugée non valide, nulle, annulée ou autrement inapplicable, cette disposition sera supprimée sans affecter ou compromettre les autres dispositions des présentes Conditions générales."
                ]
            },
            {
                title: "Révision",
                p: [
                    "PBFS SA peut réviser ces Conditions générales en tout temps si elle le juge approprié. En utilisant le présent Site Web, vous êtes tenu de régulièrement consulter ces Conditions générales."
                ]
            },
            {
                title: "Cession",
                p: [
                    "PBFS SA est autorisée à céder, transférer et sous-traiter ses droits et/ou obligations en vertu des présentes Conditions générales sans aucune notification."
                ]
            },
            {
                title: "Droit applicable et juridiction",
                p: [
                    "Les présentes Conditions générales sont régies et interprétées conformément au droit suisse. Le seul lieu de juridiction pour tous les litiges découlant de ou en relation avec les Conditions générales, la Politique de confidentialité, faisant partie des présentes Conditions générales, et l'utilisation du Site Web est Genève, Suisse. PBFS SA se réserve le droit d'engager des poursuites contre vous devant le tribunal compétent de votre domicile ou toute autre autorité compétente."
                ]
            }
        ]
    },
    privacyPolicyPage: {
        title: 'Politique de Confidentialité',
        texts: [
            {
                p: [
                    "Cette politique de confidentialité est conçue pour expliquer aux visiteurs de notre site quelles informations nous recueillons à leur sujet, l’usage que nous en faisons et avec qui nous partageons ces informations, conformément à la législation suisse, européenne et à toute autre loi applicable en matière de protection des données. Elle définit également leurs droits par rapport à ces informations et indique qui contacter pour plus d'informations ou de questions.",
                    "La présente Politique de confidentialité s'applique à PBFS SA (ci-après dénommée la « Société »).",
                    "PBFS SA prend au sérieux la confidentialité des visiteurs de son site. NOUS N'UTILISERONS NI NE PARTAGERONS AUCUNE INFORMATION PERSONNELLE AVEC QUICONQUE, SAUF COMME DÉCRIT DANS LA PRÉSENTE POLITIQUE DE CONFIDENTIALITÉ.",
                    "Les mentions telles que « note site Web » ou « le site Web » dans cette Politique de confidentialité se réfèrent aux pages Web spécifiques de la société : #https://pbfs.swiss/#.En visitant ce site Web, vous acceptez et consentez aux pratiques décrites dans la présente Politique de confidentialité. Ce site Web peut contenir des liens vers des sites web tiers, auxquels la présente politique de confidentialité ne s'applique pas.",
                ]
            },
            {
                title: "Types d’Informations Personnelles Collectées",
                p: [
                    "La Société peut collecter et stocker toute information que nos visiteurs nous fournissent explicitement et sciemment ou à laquelle ils nous permettent d'accéder, que ce soit via le Site Web, des logiciels mis à disposition pour être utilisés en relation avec le Site Web (ci-après dénommé « Logiciel »), ou par communication directe (par exemple, en remplissant des formulaires, en soumettant des demandes de renseignements ou en s’abonnant à notre newsletter), y compris, par exemple, des informations qui identifient nos visiteurs ou les concernent personnellement, telles que le nom, l’âge, l’adresse e-mail et/ou les intérêts professionnels, et autres (ci-après dénommés les « Informations Personnelles »), et d'autres sources, y compris des sources commercialement disponibles, telles que des bases de données publiques (lorsque la loi l'autorise)."
                ]
            },
            {
                title: "Traitement des Informations Personnelles",
                p: [
                    "Nous collectons et traitons vos Informations Personnelles avec le plus grand soin et dans le respect des dispositions légales applicables. Le traitement est limité au strict nécessaire et uniquement aux fins décrites dans la présente Politique de confidentialité. Pour fournir nos services, nous ne conserverons vos Informations Personnelles que pendant la durée nécessaire aux fins pour lesquelles elles ont été traitées et dans les limites de la loi.",
                    "Nous prenons les mesures de sécurité techniques et organisationnelles appropriées pour protéger nos bases de données contre les intrusions externes, les accès non autorisés ou les utilisations abusives, les pertes et les falsifications, en élaborant des directives internes, en formant notre personnel et en mettant en place des contrôles de sécurité du réseau.",
                    "Nous pouvons partager tout ou partie des Informations Personnelles avec nos autres sociétés liées et/ou sociétés affiliées que nous avons ou pourrions avoir à l'avenir, en concluant un contrat écrit, à condition qu'une telle société s'engage à protéger la confidentialité de nos visiteurs aux mêmes conditions que prévues dans la présente Politique et qu’elle mette en place des mesures de sécurité appropriées pour protéger ces Informations Personnelles, et ce pour les raisons suivantes :"
                ]
            },
            {
                ul: [
                    "Dans le cadre de l'hébergement et de l'exploitation de notre site Web, des systèmes informatiques, de l'assistance et de la maintenance;",
                    "Pour obtenir des autorisations/approbations avec les responsables concernés;",
                    "Pour répondre aux besoins de nos clients lors de la prestation de services;",
                    "À des fins d’établissement de rapports;",
                    "Pour répondre aux exigences réglementaires."
                ]
            },
            {
                p: [
                    "Nous veillons à ce que ces sociétés liées et/ou sociétés affiliées aient mis en place des normes de sécurité appropriées pour protéger vos Informations Personnelles.",
                    "Nous pouvons conclure un accord écrit avec des fournisseurs de services tiers pour exécuter certaines fonctions commerciales pour nous. Ces tiers traiteront vos Informations Personnelles en notre nom, en tant que sous-traitant. Dans ce cas, vos Informations personnelles leur seront divulguées et nous veillerons à ce que ces tiers aient mis en place des mesures de sécurité appropriées.",
                    "Autrement, nous ne divulguerons pas vos Informations Personnelles, sauf dans les cas suivants :"
                ]
            },
            {
                ul: [
                    "Dans le cadre de toute obligation légale ou procédure judiciaire menée entre nos visiteurs et la Société;",
                    "Par application de la loi, d’une décision de justice ou à la demande des autorités de surveillance;",
                    "En cas de transfert, d’achat ou de vente de l’ensemble des activités du site Web à un tiers, à condition que ce tiers s’engage à protéger les données personnelles conformément aux conditions énoncées dans la présente politique. Vous serez informé de tout transfert et pourrez vous y opposer dans le délai prévu. Vous serez également informé si le tiers décide de modifier les finalités du traitement des Informations Personnelles."
                ]
            },
            {
                p: [
                    "La Société utilise les Informations Personnelles car elles sont nécessaires :"
                ]
            },
            {
                ul: [
                    "Pour traiter les demandes de nos visiteurs, répondre à leurs demandes d'amélioration de nos produits et services, du contenu et des publicités;",
                    "Pour assurer le bon fonctionnement et les performances de notre site Web, en surveillant en permanence son utilisation, sa fonctionnalité pour les utilisateurs, sa sécurité et son intégrité;",
                    "Pour identifier et prévenir les activités frauduleuses ou criminelles en ligne, les utilisations abusives de nos produits et services, ainsi que la sécurité de nos systèmes informatiques, de notre architecture et de nos réseaux;",
                    "Pour notre tenue générale des dossiers et la gestion des relations avec les clients, pour la bonne gouvernance et la gestion des risques;",
                    "À des fins internes telles que l'audit, l'analyse de données et la recherche, y compris pour produire des recherches statistiques et des rapports afin d'améliorer les produits et services de la Société;",
                    "Pour évaluer et vérifier toute demande d'emploi et/ou demande de renseignements que nos visiteurs soumettent via le site Web;",
                    "Pour les obligations ou demandes légales et/ou réglementaires auxquelles la Société est soumise, y compris, mais sans s'y limiter, pour la tenue de registres à des fins fiscales ou la fourniture d'informations à un organisme public ou à un organisme chargé de l'application de la loi;",
                    "Pour la conclusion d'un contrat à la demande de nos visiteurs ou pour exécuter des obligations contractuelles que nous avons envers nos visiteurs."
                ]
            },
            {
                p: [
                    "Si un visiteur est un candidat potentiel à un emploi au sein de la Société, il se peut que nous ayons reçu ses Informations Personnelles de la part de tiers tels que des sites web externes, des médias sociaux ou des recruteurs. Nous utiliserons les Informations Personnelles que nous recevons pour contacter le visiteur au sujet d'une potentielle opportunité."
                ]
            },
            {
                title: "Stockage des Informations Personnelles",
                p: [
                    "Les Informations Personnelles que nous collectons auprès de vous peuvent être traitées en dehors de la Suisse et de l'Espace économique européen (« EEE »). Dans un tel cas, nous n'effectuerons le transfert des Informations Personnelles que si :"
                ],
                ul: [
                    "le pays vers lequel les Informations Personnelles doivent être transférées assure un niveau adéquat de protection;",
                    "nous avons mis en place des garanties appropriées pour protéger vos Informations Personnelles, telles qu'un contrat approuvé approprié avec le destinataire;",
                    "le transfert est nécessaire pour l'une des raisons spécifiées dans la législation sur la protection des données;",
                    "vous consentez explicitement au transfert."
                ]
            },
            {
                title: "Traitement des Informations Non Identifiantes",
                p: [
                    "La Société recueille et regroupe des informations non identifiantes concernant l'utilisation du site Web, y compris, mais sans s'y limiter, la profession, la langue, le code postal, l'indicatif régional, le fuseau horaire, l'identifiant unique de l'appareil, le type d'appareil par lequel le site Web est consulté, les pages parcourues, les requêtes de recherche, le type de navigateur utilisé par nos visiteurs et ses paramètres, l'adresse IP (Internet Protocol), l'URL et le temps passé sur l’URL, le contenu des cookies que nous avons installés ( comme détaillé ci-dessous) et votre type de domaine et votre serveur (\"Informations non identifiantes\").",
                    "Veuillez noter que les informations non identifiantes peuvent être agrégées séparément pour chaque adresse IP spécifique. Cela dit, les adresses IP, tout en identifiant un appareil connecté à Internet et son emplacement général, sont en elles-mêmes anonymes et ne contiennent aucune information concernant l'identité de la personne qui utilise l'appareil - cette identité ne doit être connue que de votre fournisseur d’accès à Internet.",
                    "Les informations non identifiantes doivent être utilisées pour assurer le bon fonctionnement du site Web, l’améliorer, le modifier, l’enrichir ou l’ajuster, en général ou dans un domaine d'intérêt spécifique de nos visiteurs.",
                    "La Société se réserve le droit d'utiliser et de divulguer des Informations non identifiantes (sous la forme collectée) à des tiers, y compris, par exemple, des partenaires commerciaux potentiels et réels, des sociétés affiliées et des consultants, à notre seule discrétion, à des fins d’établissement des rapports statistiques.",
                    "La combinaison d’informations non identifiantes avec des Informations Personnelles sera traitée comme des Informations Personnelles et ne sera ni divulguée ni partagée à des tiers. Par ailleurs, nous ne vous identifierons pas à partir d'identifiants en ligne tels que votre adresse IP."
                ]
            },
            {
                title: "À Propos des Cookies et des Informations Personnelles Collectées Automatiquement",
                p: [
                    "Dans certains cas, la Société et nos fournisseurs de contenu tierces utilisent des cookies, des balises Web et d'autres technologies de traçage pour collecter automatiquement certains types d'informations lorsque vous visitez ce site Web, ainsi que par le biais d'e-mails que nous pouvons échanger. Des exemples de ce type d'informations incluent, mais ne sont pas limités à :"
                ],
                ul: [
                    "Des identifiants, tels que votre adresse IP, votre type de navigateur, votre système d'exploitation ou votre nom de domaine;",
                    "Des informations relatives à l'activité sur Internet ou d'autres réseaux électroniques, telles que la date et heure de votre utilisation du site Web, la voie par laquelle vous choisissez d'y accéder et votre utilisation de tout lien hypertexte ou contenu téléchargeable disponible sur ce site Web."
                ]
            },
            {
                p: [
                    "Nous collectons ces informations à l'aide de cookies. Cette collecte d'informations nous aide à personnaliser votre expérience en ligne, à améliorer les performances, la convivialité et l'efficacité de notre site Web.",
                    "Les cookies sont de petits fichiers de données téléchargés et stockés sur l'appareil d'un utilisateur lors de la navigation sur un site Web, souvent utilisés pour enregistrer les actions entreprises sur ce site Web ou suivre l'activité de navigation associée. Lorsque des cookies sont utilisés, une notification sera envoyée à votre navigateur expliquant l'utilisation des cookies. Pour plus d'informations sur les cookies et la manière de les contrôler, veuillez consulter le fichier d'aide de votre navigateur ou visiter #https://www.aboutcookies.org/#.",
                    "Les cookies essentiels sont indispensables au bon fonctionnement du site Web. Ils ne sont utilisés que le temps d'une session et sont supprimés dès que vous quittez le Site.",
                    "Les cookies analytiques ne sont utilisés qu'avec votre consentement. Ces cookies nous permettent, sur la base de données anonymes, de mieux comprendre comment vous utilisez notre site Web afin de l'optimiser en permanence. Vous pouvez accepter ou refuser l'utilisation de cookies analytiques dans le bandeau cookies de la page d'accueil du site Web et modifier vos préférences via les paramètres du site Web.",
                    "Un bannière de notification apparaît sur le site Web demandant votre consentement à la collecte des cookies. Veuillez noter que les navigateurs modernes vous permettent de désactiver les cookies (si vous ne savez pas comment faire, consultez le fichier d'aide de votre navigateur), cependant, cela peut interférer avec votre utilisation du site Web et/ou de ses services.",
                    "À moins que vous ne désactiviez les cookies, en utilisant ou en naviguant sur le site Web, vous consentez explicitement au stockage et à l'installation de cookies et à l'accès aux informations sur votre appareil par la Société et les sociétés de contenu tierces.",
                    "Vous avez la possibilité de rendre certaines balises Web inutilisables en rejetant les cookies qui leur sont associés. La balise Web peut encore enregistrer une visite anonyme à partir de votre adresse IP, mais les informations relatives aux cookies ne seront pas enregistrées."
                ],
                ul: [
                    "Nous utilisons des cookies afin d'assurer le bon fonctionnement du site Web, afin de collecter des informations non identifiantes, de vérifier les informations, d’adapter le site Web à vos préférences spécifiques et d’assurer la sécurité des informations;",
                    "En outre, nous pouvons faire appel à des sociétés tierces de création de contenu afin de présenter du contenu sur notre site Web. Ces parties peuvent utiliser des cookies placés sur votre ordinateur afin d'ajuster ou de suivre le contenu présenté sur le site Web;",
                    "En ce qui concerne l'utilisation de cookies par des sociétés de contenu tierces, veuillez noter que cette utilisation est soumise à la politique de confidentialité desdites sociétés et non pas à la présente Politique de confidentialité."
                ]
            },
            {
                p: [
                    "Les Cookies suivants sont utilisés en relation avec le Site :"
                ],
                ul: [
                    "Cookies placés par la Société – utilisés pour maintenir une session active entre les redémarrages du navigateur, pour faciliter la navigation et l’utilisation du site Web, pour gérer les droits d'accès et mémoriser les paramètres personnels;",
                    "Cookies placés par Google Analytics - utilisés pour suivre les utilisateurs lorsqu'ils naviguent sur le site Web et aider à améliorer la convivialité du site Web."
                ]
            },
            {
                p: [
                    "Les cookies en eux-mêmes ne révèlent pas votre adresse e-mail ni ne permettent de vous identifier personnellement."
                ]
            },
            {
                title: "Vos Droits",
                p: [
                    "Conformément à la loi, vous disposez d'un droit d'accès, de consultation et de demande de copie physique ou électronique des informations vous concernant. Vous avez également le droit de demander des informations sur la source de vos Informations Personnelles.",
                    "a) Droit à l'information :",
                    "Vous avez droit aux informations suivantes que nous détenons, en tant que responsable du traitement, concernant le traitement de vos Informations Personnelles :"
                ],
                ul: [
                    "Les Informations Personnelles spécifiques traitées à votre sujet en tant que personne concernée;",
                    "Les finalités de traitement associées à vos Informations Personnelles;",
                    "Les catégories d'Informations Personnelles concernées par nos opérations de traitement;",
                    "Les destinataires éventuels de ces données, et au moins les catégories de destinataires concernés;",
                    "La durée de conservation des données, et au moins les critères de détermination de cette durée;",
                    "Les informations disponibles sur l'origine des Informations Personnelles, dans la mesure où ces Informations Personnelles n'ont pas été collectées auprès du responsable du traitement."
                ]
            },
            {
                p: [
                    "b) Autres droits :",
                    "Vous avez également le droit de corriger, de réviser ou d'effacer les Informations Personnelles vous concernant, de restreindre ou de vous opposer au traitement de ces Informations Personnelles. Vous pouvez également révoquer à tout moment votre consentement au traitement des Informations Personnelles. Dès que nous recevrons votre notification que vous révoquez votre consentement, nous ne traiterons plus les Informations Personnelles aux fins auxquelles vous avez initialement consenti.",
                    "Ces droits peuvent être exercés en nous envoyant un e-mail à legal@pbfs.swiss, en joignant une copie de votre pièce d'identité ou des informations équivalentes (à notre demande et dans la mesure permise par la loi)."
                ]
            },
            {
                title: "Newsletter",
                p: [
                    "Dans certaines de nos newsletters et autres communications, nous pouvons surveiller les actions des destinataires telles que le taux d'ouverture des e-mails via les liens intégrés dans les messages. Nous recueillons ces informations pour évaluer l'intérêt des utilisateurs et pour améliorer les futures expériences des utilisateurs. Nous ne vous enverrons une newsletter que si vous y êtes abonné. Vous pouvez également retirer votre consentement à ne plus recevoir d'e-mails de la liste marketing de PBFS SA en cliquant sur le lien \"se désinscrire\" dans l'e-mail que vous avez reçu."
                ]
            },
            {
                title: "Mises à Jour de la Politique de Confidentialité",
                p: [
                    "Cette politique de confidentialité fait partie des conditions générales de ce site Web #https://pbfs.swiss/#",
                    "La Société peut modifier cette Politique de confidentialité de temps à autre. Ces modifications entreront en vigueur dès leur publication. Nous vous invitons à consulter régulièrement cette page pour prendre connaissance des éventuelles modifications apportées à la présente Politique de confidentialité."
                ]
            },
            {
                title: "Contactez-nous",
                p: [
                    "Si vous avez des plaintes concernant le traitement de vos Informations Personnelles par PBFS SA, vous pouvez avoir le droit de contacter l'autorité de contrôle compétente. toutefois, nous apprécierions d’avoir la possibilité de traiter vos préoccupations et de résoudre tout problème éventuel avant que vous ne adressiez à cette autorité. Nous vous prions de nous contacter d'abord à legal@pbfs.swiss.",
                    "Si vous avez des questions, veuillez nous contacter par e-mail legal@pbfs.swiss.",
                    "Dernière mise à jour : juillet 2023"
                ]
            }
        ],

        // linkToCookies1: 'Les Cookies sont de petits fichiers de données téléchargés et stockés sur l’appareil d’un utilisateur lorsque celui-ci navigue sur un Site Web, et sont souvent utilisés pour enregistrer les actions effectuées sur ce Site Web ou pour suivre les activités de navigation connexes. Pour plus d’informations sur les Cookies et la façon dont vous pouvez les contrôler, veuillez consulter le fichier d’aide de votre navigateur ou visitez',
        // linkToCookies2: 'http://www.aboutCookies.org',
    },
    projects: {
        neuromathematics: {
            returnTo: '/fr/investissement',
            title: 'Neuromathématiques et Neurobiologie',
            directionSvg: <DirectionsSvgFr/>,
            shortDescribe: 'Le développement de l’intelligence artificielle (IA) n’a pas pour objectif de mettre fin à l’anthropocène. Les avancées dans ce domaine accélèrent les progrès technologiques, travaillent de concert avec l’intelligence humaine et l’améliorent, et ont un effet de transformation profond sur le monde.',
            bannerImg: neuromathematicksBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Les réseaux neuronaux sont des systèmes informatiques dont la conception s’inspire des principes structurels et fonctionnels des réseaux de neurones que l’on retrouve dans la nature. Les réseaux neuronaux apprennent par observation, en se basant sur des exemples, et constituent la base de toute intelligence artificielle.',
                        delay: 0,
                    },
                    {
                        text: 'Les recherches se concentrent sur les sciences cognitives et l’étude du cerveau humain, et ce en suivant et en combinant les trois domaines des neurosciences, de la biologie et de l’informatique:',
                        delay: 25,
                    },
                ],
                listOfLi: [
                    'Les fonctions cognitives complexes du cerveau servent de source d’inspiration pour le développement de technologies informatiques,',
                    'Les recherches concernant le fonctionnement de la mémoire servent de base à de nouvelles méthodes de stockage et de traitement des données,',
                    'L’activité énergétique se produisant dans le cerveau inspire le développement de technologies de pointe dans le domaine de la production, du stockage et de la consommation d’énergie.'
                ],
                listOfP2: [
                    {
                        text: 'De nombreux algorithmes et applications de deep learning ont déjà un effet concret sur nos vies, et continueront à évoluer en ce sens à l’avenir. Les principaux domaines de recherche et d’investissement de notre investissement comprennent:',
                        delay: 0,
                    }
                ],
                listOfLi2: [
                    'La business intelligence et l’analyse de données, notamment les analyses prévisionnelles et celles des risques,',
                    'La création de bases de données servant de support au deep learning, et les logiciels capables de se modifier eux-mêmes,',
                    'La reconnaissance d’images, de vidéos et d’écriture manuscrite',
                    'Les assistants virtuels en ligne,',
                    'Le diagnostic médical et la médecine de précision.',
                ]
            },
            sliderImg: [neuromathematicksSlide1, neuromathematicksSlide2, neuromathematicksSlide3],
            viewCareerTitle: `Rejoignez l'équipe de base`,
            viewCareerSvg: <ViewCareerFr/>,
            viewCareerText: 'Voir les opportunités de carrière',
            linkToCareer: '/fr/carrieres',
            nextDirectionTitle: 'Biopharma',
            nextDirectionSvg: <NextDirection/>,
            nextDirectionText: 'Voir la domaine suivante',
            nextLink: '/fr/investissement/biopharma',
            fr: true,
        },
        biopharma: {
            returnTo: '/fr/investissement',
            title: 'Biopharma',
            directionSvg: <DirectionsSvgFr/>,
            shortDescribe: '«La médecine est la science de l’incertitude, et l’art des probabilités» – William Osler, Médecin Canadien.',
            bannerImg: biopharmaBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Les médicaments biologiques tirent parti de procédés et de mécanismes issus de la nature pour traiter les maladies. Ils se basent sur et considèrent les sources biologiques comme étant le mode d’emploi du développement, du fonctionnement, de la croissance, de la reproduction et de la guérison des cellules du corps humain.',
                        delay: 0,
                    },
                    {
                        text: 'Les médicaments biologiques ont eu par le passé un impact immense sur la détection précoce des maladies, et le secteur a connu une croissance importante ces dernières années. On peut citer en particulier des domaines tels que l’ADN, les thérapies géniques et cellulaires. Cette évolution récente a permis d’amener l’humanité sur le seuil de découvertes capitales. PBFS a notamment identifié les domaines suivants:',
                        delay: 25,
                    },
                ],
                listOfLi: [
                    'Les médicaments biologiques bio-similaires,',
                    'L’évolution des technologies biologiques de traitement,',
                    'Le traitement personnalisé de groupes de patients en fonction de recherches sur leur génome.'
                ],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [biopharmaSlide1, biopharmaSlide2, biopharmaSlide3],
            viewCareerTitle: `Rejoignez l'équipe de base`,
            viewCareerSvg: <ViewCareerFr/>,
            viewCareerText: 'Voir les opportunités de carrière',
            nextDirectionTitle: 'Fintech',
            nextDirectionSvg: <DirectionsSvgFr/>,
            nextDirectionText: 'Voir la domaine suivante',
            linkToCareer: '/fr/carrieres',
            nextLink: '/fr/investissement/fintech',
            fr: true,
        },
        fintech: {
            returnTo: '/fr/investissement',
            title: 'FinTech',
            directionSvg: <DirectionsSvgFr/>,
            shortDescribe: '“Personne ne possède le monopole des idées brillantes. C’est une bonne raison pour conclure des partenariats avec des fintechs"',
            bannerImg: fintechBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'La FinTech a trouvé sa place dans l’économie innovante grâce au lancement d’applications de services financiers, à l’émergence d’incubateurs numériques, et à la montée en flèche du capital-risque privé.',
                        delay: 0,
                    },
                    {
                        text: 'L’enterprise peut compter sur une équipe talentueuse et enthousiaste composée d’experts en mathématiques et en informatique, qui ont su combiner avec succès recherche scientifique et applications pratiques de leurs modèles dans des situations économiques réelles. Le résultat de notre travail est matérialisé dans des modèles développés en interne et testés dans des situations réelles, qui permettent d’évaluer des instruments financiers et des entreprises pour prédire leur valeur, leur croissance, ou leur risque de défaillance, et ce de manière très fiable.',
                        delay: 25,
                    },
                    {
                        text: 'En termes d’application de ses recherches, l’enterprise considère que l’avenir se trouve dans les services financiers basés sur des technologies permettant de totalement réinventer un système financier de manière efficace.',
                        delay: 50,
                    },
                ],
                listOfLi: [],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [fintechSlide1, fintechSlide2, fintechSlide3],
            viewCareerTitle: `Rejoignez l'équipe de base`,
            viewCareerSvg: <ViewCareerFr/>,
            viewCareerText: 'Voir les opportunités de carrière',
            nextDirectionTitle: 'Écosystèmes de données et Cybersécurité',
            nextDirectionSvg: <DirectionsSvgFr/>,
            nextDirectionText: 'Voir la domaine suivante',
            linkToCareer: '/fr/carrieres',
            nextLink: '/fr/investissement/data-ecosystems-and-cybersecurity',
            fr: true,
        },
        dataEcosystems: {
            returnTo: '/fr/investissement',
            title: 'Écosystèmes de données et Cybersécurité',
            directionSvg: <DirectionsSvgFr/>,
            shortDescribe: 'Bâtir la réputation de votre société est une entreprise de longue haleine, et un seul incident de cybersécurité peut suffire à la détruire.',
            bannerImg: dataEcosystemBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Un écosystème de données est un regroupement d’infrastructures, d’analyses et d’applications utilisées pour capturer, stocker et analyser des données. Ces écosystèmes offrent aux entreprises les données indispensables pour gérer leurs tarifs, leurs activités, et leur stratégie de marketing. Nouse considéros que les écosystèmes de données sont comparables à leurs pendants naturels qui évoluent dans le temps. Notre objectif est d’approfondir les sujets suivants :',
                        delay: 0,
                    }
                ],
                listOfLi: [
                    'La technologie de la blockchain qui permet une meilleure transparence des données, une distribution sécurisée et des garanties d’authenticité,',
                    'La collecte d’informations biométriques pour l’identification des êtres humains,',
                    'Le stockage de données concernant l’ADN',
                ],
                listOfP2: [
                    {
                        text: 'Une tendance inquiétante qui va de pair avec l’essor de l’économie numérique est l’augmentation permanente de la cybercriminalité. Il est donc indispensable pour les organisations et les individus de bâtir de nouvelles parades pour se prémunir contre les cyber-attaques. Le développement de réseaux neuronaux de deep learning pour identifier les anomalies et les tentatives de piratage peut être une excellente stratégie de sécurisation de l’avenir pour les secteurs de la banque, de la Défense, de la santé, et pour la communication entre objets connectés (que l’on appelle l’internet des objets).',
                        delay: 0
                    }
                ],
                listOfLi2: []
            },
            sliderImg: [dataEcosystemSlide1, dataEcosystemSlide2, dataEcosystemSlide3],
            viewCareerTitle: `Rejoignez l'équipe de base`,
            viewCareerSvg: <ViewCareerFr/>,
            viewCareerText: 'Voir les opportunités de carrière',
            nextDirectionTitle: 'Véhicules autonomes',
            nextDirectionSvg: <DirectionsSvgFr/>,
            nextDirectionText: 'Voir la domaine suivante',
            linkToCareer: '/fr/carrieres',
            nextLink: '/fr/investissement/autonomous-vehicles',
            fr: true,
        },
        vehicles: {
            returnTo: '/fr/investissement',
            title: 'Véhicules autonomes',
            directionSvg: <DirectionsSvgFr/>,
            shortDescribe: 'Nous avons pour objectif de contribuer à la réduction de la pollution automobile et à rendre les transports durables.',
            bannerImg: vehilesBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Les systèmes intelligents deviennent une composante essentielle des infrastructures de transport, que ce soit en termes de gestion du trafic ou de contrôle des véhicules. La logistique est également de plus en plus automatisée. Outre les véhicules autonomes déjà présents sur nos routes, PBFS mène des recherches sur les avancées suivantes:',
                        delay: 0,
                    }
                ],
                listOfLi: [
                    'Les voitures avec batteries capables de se recharger elles-mêmes, que ce soit par électrification de la route ou en générant de l’énergie par la friction de l’air et de la chaussée,',
                    'Les transports urbains par les airs,',
                    'Les industries connexes telles que la production de capteurs embarqués, de logiciels, de systèmes d’automatisation, de cartographie, de simulation et de reconnaissance d’image, d’analyse du comportement des piétons, ou encore d’outils de communication.',
                ],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [vehilesSlide1, vehilesSlide2, vehilesSlide3],
            viewCareerTitle: `Rejoignez l'équipe de base`,
            viewCareerSvg: <ViewCareerFr/>,
            viewCareerText: 'Voir les opportunités de carrière',
            nextDirectionTitle: 'Production et stockage d’énergie',
            nextDirectionSvg: <DirectionsSvgFr/>,
            nextDirectionText: 'Voir la domaine suivante',
            linkToCareer: '/fr/carrieres',
            nextLink: '/fr/investissement/energy-generation-and-storage',
            fr: true,
        },
        energy: {
            returnTo: '/fr/investissement',
            title: 'Production et stockage d’énergie',
            directionSvg: <DirectionsSvgFr/>,
            shortDescribe: 'Nous avons pour objectif de toujours rechercher de nouveaux moyens plus efficaces de production et de stockage d’énergie.',
            bannerImg: energyBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Les sources d’énergie alternatives privées (micro-éoliennes, panneaux solaires, etc.) vont continuer à se développer. Les processus de production, de transport et de stockage d’énergie seront de plus en plus automatisés et ne nécessiteront plus, ou presque plus, d’intervention humaine.',
                        delay: 0,
                    }
                ],
                listOfLi: [
                    'Les nouvelles technologies de stockage et de transport d’énergie, tout comme l’alimentation en énergie des appareils mobiles, qui font désormais partie de notre vie quotidienne, va changer, avec une grande part de l’énergie nécessaire qui pourra être captée directement depuis le corps de l’utilisateur par le moyen de générateurs cousus dans les vêtements ou les chaussures.',
                    'Les sources d’énergie sans fil et les moyens de recharge qui peuvent être intégrés dans divers supports présents dans l’espace public, tels que des tables de recharge sans fil pour les restaurants, les hôtels et les bureaux, des meubles conçus spécifiquement pour la recharge sans fil, ou encore des surfaces industrielles comme des comptoirs permettant la recharge sans fil.',
                ],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [energySlide1, energySlide2, energySlide3],
            viewCareerTitle: `Rejoignez l'équipe de base`,
            viewCareerSvg: <ViewCareerFr/>,
            viewCareerText: 'Voir les opportunités de carrière',
            nextDirectionTitle: 'Réalité augmentée',
            nextDirectionSvg: <DirectionsSvgFr/>,
            nextDirectionText: 'Voir la domaine suivante',
            linkToCareer: '/fr/carrieres',
            nextLink: '/fr/investissement/augmented-reality',
            fr: true,
        },
        augmentedReality: {
            returnTo: '/fr/investissement',
            title: 'Réalité augmentée',
            directionSvg: <DirectionsSvgFr/>,
            shortDescribe: 'Nous sommes persuadés que la réalité augmentée est l’une des révolutions technologiques majeures s’inscrivant dans la transition numérique de l’industrie, car elle va modifier en profondeur la façon dont on pourra visualiser les choses.',
            bannerImg: augmentedRealityBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'Aujourd’hui, la réalité augmentée permet déjà de superposer des couches d’informations contextuelles ou des effets spéciaux sur une vue réelle, via un smartphone. De plus, on s’attend à ce que la réalité augmentée et la réalité virtuelle atteignent leur plein potentiel dans de nombreux domaines outre celui des jeux:',
                        delay: 0,
                    }
                ],
                listOfLi: [
                    'Médecine – projection de la position des organes,',
                    'Immobilier et architecture d’intérieur – visualisation de biens,',
                    'E-commerce – miroirs à mémoire, conception virtualisée et cabines d’essayages virtuelles pour mieux se représenter un intérieur ou une tenue,',
                    'Tourisme – fourniture d’itinéraires et de recommandations de restaurants ou de points d’intérêt à l’écran, l’accompagnement d’excursions à travers le monde,',
                    'Compagnons sous forme d’avatars – notamment pour les personnes handicapées.',
                ],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [augmentedRealitySlide1, augmentedRealitySlide2, augmentedRealitySlide3],
            viewCareerTitle: `Rejoignez l'équipe de base`,
            viewCareerSvg: <ViewCareerFr/>,
            viewCareerText: 'Voir les opportunités de carrière',
            nextDirectionTitle: 'EdTech',
            nextDirectionSvg: <DirectionsSvgFr/>,
            nextDirectionText: 'Voir la domaine suivante',
            linkToCareer: '/fr/carrieres',
            nextLink: '/fr/investissement/edtech',
            fr: true,
        },
        edTech: {
            returnTo: '/fr/investissement',
            title: 'EdTech',
            directionSvg: <DirectionsSvgFr/>,
            shortDescribe: '"La technologie ne remplacera jamais les grands enseignants, mais la technologie entre les mains de grands enseignants est transformationnelle", - George Couros.',
            bannerImg: edTechBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'L’éducation est aussi vieille que l’humanité elle-même. Nos ancêtres ont été éduqués au sein de la famille ou de la tribu, jusqu’à ce que les Grecs établissent les bases du mentorat philosophique. Au XXIe siècle, la numérisation touche de plus en plus de domaines de notre vie quotidienne, y compris celui de l’éducation.',
                        delay: 0,
                    },
                    {
                        text: 'EdTech est la combinaison d’outils informatiques et de pratiques éducatives visant à faciliter et à réformer l’apprentissage. EdTech contribue à façonner le monde moderne et son développement futur. L’utilisation de la technologie affecte les capacités cognitives de l’homme, car les outils que nous utilisons définissent et façonnent notre pensée.',
                        delay: 50,
                    },
                    {
                        text: 'Différentes organisations profitent de la technologie pour dispenser l’enseignement là où il est le plus nécessaire. Cependant, de nombreux établissements d’enseignement traditionnels hésitent encore à utiliser ou à expérimenter des cours en ligne. L’objectif de la société est de contribuer au développement des technologies de l’éducation, d’offrir une fenêtre sur le monde à de nombreux étudiants talentueux et de leur donner accès aux ressources et aux meilleures pratiques d’apprentissage.',
                        delay: 100,
                    },
                    {
                        text: 'Nous reconnaissons que si EdTech englobe de nombreuses technologies de pointe différentes, allant des logiciels en nuage, des plateformes de réseau scientifique ainsi qu’un spectre qui s’étend des jeux aux domaines plus techniques comme les plans de cours par RA; il reste encore du chemin à parcourir pour des solutions EdTech plus complexes. Notre objectif est de créer un mélange de technologies d’apprentissage, d’éléments de jeu et de méthodologies pédagogiques classiques de premier ordre pour donner aux étudiants du monde entier la possibilité d’acquérir de solides connaissances et des compétences d’apprentissage dans le cadre de cours interactifs. Nous donnerons aux étudiants la possibilité de faire des découvertes dans différents domaines des sciences exactes et naturelles, et aux éducateurs la possibilité d’amplifier leurs outils et d’étendre leur champ d’action à l’échelle mondiale.',
                        delay: 150,
                    },
                ],
                listOfLi: [],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [edTechSlide1, edTechSlide2, edTechSlide3],
            viewCareerTitle: `Rejoignez l'équipe de base`,
            viewCareerSvg: <ViewCareerFr/>,
            viewCareerText: 'Voir les opportunités de carrière',
            nextDirectionTitle: 'Gaming',
            nextDirectionSvg: <DirectionsSvgFr/>,
            nextDirectionText: 'Voir la domaine suivante',
            linkToCareer: '/fr/carrieres',
            nextLink: '/fr/investissement/gaming',
            fr: true,
        },
        gaming: {
            returnTo: '/fr/investissement',
            title: 'Gaming',
            directionSvg: <DirectionsSvgFr/>,
            shortDescribe: `"Nous n'arrêtons pas de jouer parce que nous vieillissons. Nous vieillissons parce que nous arrêtons de jouer"? - Benjamin Franklin`,
            bannerImg: gamingBanner,
            introduction: {
                title: 'Introduction',
                listOfP: [
                    {
                        text: 'L’industrie des jeux vidéos est l’une des plus dynamique du secteur de la technologie informatique et du divertissement.',
                        delay: 0,
                    },
                    {
                        text: 'Les jeux sont devenus des phénomènes culturels et leur développement est considéré aujourd’hui comme une forme d’art. Le e-sport est aujourd’hui reconnu comme un véritable sport par divers pays. Avec des joueurs de plus en plus nombreux, développer des jeux vidéos est devenu un métier à haut revenu.',
                        delay: 50,
                    },
                    {
                        text: 'L’industrie du jeu devient un vecteur d’éducation moderne, de créativité et d’innovation. La combinaison de la technologie informatique et de l’éducation crée une nouvelle réalité dans laquelle les accomplissements dans les jeux PC se transforment en une métrique d’évaluation des capacités.',
                        delay: 100,
                    },
                    {
                        text: 'Les technologies immersives (VR, AR, MR) changent également la donne lorsqu’il s’agit d’enseigner aux étudiants des compétences pour l’avenir, une réflexion critique, une communication et une créativité étincelantes, garantissant une concentration et une implication maximale dans le processus, améliorant ainsi l’efficacité et garantissant la sécurité.',
                        delay: 150,
                    },
                ],
                listOfLi: [],
                listOfP2: [],
                listOfLi2: []
            },
            sliderImg: [gamingSlide1, gamingSlide2, gamingSlide3],
            viewCareerTitle: `Rejoignez l'équipe de base`,
            viewCareerSvg: <ViewCareerFr/>,
            viewCareerText: 'Voir les opportunités de carrière',
            nextDirectionTitle: undefined,
            nextDirectionSvg: undefined,
            nextDirectionText: undefined,
            linkToCareer: '/fr/carrieres',
            nextLink: undefined,
            fr: true,
        },
    },
    errorMessages: {
        text: 'Сe champ est obligatoire.',
        email: `L'adresse e-mail saisie n'est pas valide.`,
        phoneNumber: 'Le numéro de téléphone n\'est pas valide.',
        terms: 'Vous devez accepter les conditions générales avant d\'envoyer votre message.',
        successfully: 'Envoyé avec succès'
    },
    cookie: {
        text: 'Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site Web. Si vous continuez à utiliser ce site, nous supposerons que vous en êtes satisfait.',
        button: 'Politique de confidentialité'
    },
    notFoundPage: {
        head1: 'Ette page doit sans doute jouer à cache-cache.',
        head2: 'Où s’est-elle perdue?',
        text: `La curiosité. Il n'y a rien de plus stimulant que d'être confronté à une question dont on ignore la réponse pour se mettre à la recherche de celle-ci.`,
        link: '/fr/investissement',
        linkText: 'Découvrez les axes d’investissement de PBFS',
    }
};