import {Link} from "react-router-dom";

const openLink = (e, url) => {
    e.preventDefault();
    window.open(url, "_blank", "noreferrer");
}

export const createPBlock = (item, idx, key) => {
    if (item.includes("https:")) {
        const split = item.split("#");

        return <p key={key + idx}
                  className="p1">
                    <span className="s1">
                        {split[0]}
                    </span>
            <span className="s1">
                        <Link
                            to="/#"
                            onClick={(e) => openLink(e, split[1])}
                        >
                            {split[1]}
                        </Link>
                    </span>
            <span className="s1">
                    {split[2]}
                 </span>
        </p>
    } else {
        return <p key={key + idx}
                  className="p1">
                    <span className="s1">
                        {item}
                    </span>
        </p>
    }
};

export const createLiBlock = (item, idx, key) => {
    return <li key={key + idx}
               className="li2">
                        <span className="s1">
                            {item}
                        </span>
    </li>
};

export const isEmptyObj = (obj) => {
    if (obj === undefined || obj === null)
        return true;

    if (typeof obj === 'string')
        obj = JSON.parse(obj);

    for (let key in obj)
        return false;

    return true;
};

export const stringToBoolean = (val) => {
    if (!val || val.length === 0)
        return false;

    if (val.toLowerCase().trim() === "true")
        return true;

    if (val.toLowerCase().trim() === "false")
        return false;
};

export const getFuturesDate = (plusDays) => {
    const date = new Date();
    return new Date(date.setDate(date.getDate() + plusDays));
};