import React from "react";
import * as ScrollMagic from "scrollmagic";
import {TweenMax, TimelineMax} from "gsap";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

class WrapperLine extends React.Component {
    constructor(props) {
        super(props);
        this.controller = new ScrollMagic.Controller();
    }

    componentDidMount() {
        const {idx} = this.props;

        new ScrollMagic.Scene({
            triggerElement: `#${idx}`,
            duration: 700,
            offset: -500
        })
            .setTween(`#${idx}`, {
                opacity: 1,
                transform: 'translate(0%, 0%)',
            })
            .addTo(this.controller);

        new ScrollMagic.Scene({
            triggerElement: `#${idx}`,
            duration: 600,
            offset: -350
        })
            .setTween(`#${idx}circle`, {
                opacity: 1,
                transform: 'matrix(1, 0, 0, 1, -10.6, -10.6)',
            })
            .addTo(this.controller);
    }

    render() {
        const {data, idx, translate} = this.props;

        return <li>
                <div id={idx}
                     className="wrap"
                     style={{opacity: 0, transform: `translate(${translate}%, 0%)`}}>
                    <b>{data.bold}</b>
                    <p>{data.text}</p>
                </div>
                <div id={idx + 'circle'} className="circle" style={{opacity: 1, transform: 'matrix(0, 0, 0, 0, -10.6, -10.6)'}}/>
            </li>
        ;
    }
}

export default WrapperLine;
