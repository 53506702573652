import React from 'react';

const FoundationSVG = () => {
    return <svg width="566" height="60" viewBox="0 0 566 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.6 27.2H39.52V37.6H13.6V58H0.640015V2.00001H42.96V12.4H13.6V27.2ZM14.1
        12.9H43.46V1.50001H0.140015V58.5H14.1V38.1H40.02V26.7H14.1V12.9ZM65.8892 57.2674L65.887
        57.2665C62.0962 55.8044 58.7868 53.7439 55.964 51.0839L55.9605 51.0806C53.1924 48.421 51.0216
        45.2999 49.4491 41.7211L49.4466 41.7153C47.9248 38.1283 47.1669 34.2212 47.1669 30C47.1669
        25.7789 47.9248 21.8718 49.4466 18.2847L49.4491 18.2789C51.0225 14.698 53.2214 11.5759 56.044
        8.91611C58.8668 6.25613 62.1762 4.19564 65.967 2.7335C69.7628 1.26939 73.8778 0.540009 78.3069
        0.540009C82.7878 0.540009 86.9034 1.26891 90.6476 2.7338C94.4381 4.19596 97.7216 6.25679 100.492
         8.91803C103.313 11.5767 105.511 14.6974 107.084 18.2763C108.662 21.8138 109.447 25.7242 109.447
          30C109.447 34.2236 108.661 38.1584 107.086 41.7986L107.085 41.8011C105.511 45.382 103.312 48.5041
           100.49 51.1639C97.7198 53.7709 94.4371 55.8043 90.6485 57.2659C86.9047 58.7307 82.8157 59.46
           78.3869 59.46C73.9051 59.46 69.7376 58.7309 65.8892 57.2674ZM106.627 18.48C105.08 14.96 102.92
            11.8933 100.147 9.28001C97.4269 6.66667 94.2002 4.64001 90.4669 3.2C86.7869 1.76001 82.7336
             1.04001 78.3069 1.04001C73.9336 1.04001 69.8802 1.76001 66.1469 3.2C62.4136 4.64001 59.1602
             6.66667 56.3869 9.28001C53.6136 11.8933 51.4536 14.96 49.9069 18.48C48.4136 22 47.6669 25.84
              47.6669 30C47.6669 34.16 48.4136 38 49.9069 41.52C51.4536 45.04 53.5869 48.1067 56.3069 50.72C59.0802
               53.3333 62.3336 55.36 66.0669 56.8C69.8536 58.24 73.9602 58.96 78.3869 58.96C82.7602 58.96 86.7869
                58.24 90.4669 56.8C94.2002 55.36 97.4269 53.36 100.147 50.8C102.92 48.1867 105.08 45.12 106.627
                41.6C108.174 38.0267 108.947 34.16 108.947 30C108.947 25.7867 108.174 21.9467 106.627
                18.48ZM84.9971 46.1774L85.0012 46.1758C87.0727 45.3472 88.882 44.1578 90.4333 42.6065C91.9826
                 41.0572 93.1719 39.223 94.0011 37.0982L94.0049 37.0885C94.8834 34.9697 95.3269 32.6092 95.3269
                 30C95.3269 27.3908 94.8835 25.0303 94.005 22.9115L94.001 22.9018C93.1718 20.7771 91.9826 18.9428
                 90.4333 17.3936L90.4273 17.3876C88.9313 15.84 87.1512 14.6522 85.0812 13.8242C83.0121 12.9966
                  80.7558 12.58 78.3069 12.58C75.8588 12.58 73.603 12.9964 71.5344 13.8235C69.517 14.6515 67.7334
                   15.8407 66.1804 17.3936C64.6301 18.9439 63.4133 20.7799 62.5307 22.9068C61.7042 25.0269
                    61.2869 27.3892 61.2869 30C61.2869 32.5553 61.7035 34.9188 62.5315 37.0952C63.4148 39.2233
                    64.606 41.0595 66.1033 42.6093C67.654 44.1593 69.4624 45.3477 71.5326 46.1758C73.6017 47.0034
                     75.858 47.42 78.3069 47.42C80.7559 47.42 82.9839 47.0034 84.9971 46.1774ZM62.0669 22.72C62.9736
                     20.5333 64.2269 18.64 65.8269 17.04C67.4269 15.44 69.2669 14.2133 71.3469 13.36C73.4802 12.5067
                     75.8002 12.08 78.3069 12.08C80.8136 12.08 83.1336 12.5067 85.2669 13.36C87.4002 14.2133 89.2402
                     15.44 90.7869 17.04C92.3869 18.64 93.6136 20.5333 94.4669 22.72C95.3736 24.9067 95.8269 27.3333
                     95.8269 30C95.8269 32.6667 95.3736 35.0933 94.4669 37.28C93.6136 39.4667 92.3869 41.36 90.7869
                      42.96C89.1869 44.56 87.3202 45.7867 85.1869 46.64C83.1069 47.4933 80.8136 47.92 78.3069
                      47.92C75.8002 47.92 73.4802 47.4933 71.3469 46.64C69.2136 45.7867 67.3469 44.56 65.7469
                      42.96C64.2002 41.36 62.9736 39.4667 62.0669 37.28C61.2136 35.04 60.7869 32.6133 60.7869
                      30C60.7869 27.3333 61.2136 24.9067 62.0669 22.72ZM117.707 1.50001H131.667V32.88C131.667
                      38.1654 132.754 41.8498 134.813 44.0591C136.884 46.2817 139.805 47.42 143.647 47.42C147.489
                      47.42 150.41 46.2817 152.481 44.0591C154.54 41.8498 155.627 38.1654 155.627
                      32.88V1.50001H169.427V33.36C169.427 41.6646 167.16 48.129 162.558 52.6757C157.962 57.2175
                      151.609 59.46 143.567 59.46C135.525 59.46 129.172 57.2175 124.575 52.6757C119.974
                       48.129 117.707 41.6646 117.707 33.36V1.50001ZM181.234 58.5V1.50001H192.69L219.794
                       34.5752V1.50001H233.594V58.5H222.217L195.034 25.4069V58.5H181.234ZM194.534 24.0104L222.454
                        58H233.094V2.00001H220.294V35.9742L192.454 2.00001H181.734V58H194.534V24.0104ZM245.843
                        58.5V1.50001H271.783C277.927 1.50001 283.366 2.68574 288.086 5.07229C292.803 7.40424
                        296.502 10.6918 299.167 14.934C301.838 19.1859 303.163 24.2147 303.163 30C303.163
                         35.7331 301.837 40.7604 299.168 45.0636L299.167 45.066C296.502 49.3074 292.805 52.621
                          288.089 55.0062L288.085 55.0083C283.365 57.3411 277.927 58.5 271.783 58.5H245.843ZM287.863
                          5.52001C283.223 3.17334 277.863 2.00001 271.783 2.00001H246.343V58H271.783C277.863 58
                          283.223 56.8533 287.863 54.56C292.503 52.2133 296.13 48.96 298.743 44.8C301.356 40.5867
                          302.663 35.6533 302.663 30C302.663 24.2933 301.356 19.36 298.743 15.2C296.13 11.04
                           292.503 7.81334 287.863 5.52001ZM286.789 38.9519L286.793 38.9453C288.287 36.4207 289.043
                           33.4449 289.043 30C289.043 26.5 288.286 23.5266 286.795 21.0586L286.789 21.0482C285.346
                           18.5231 283.287 16.5912 280.6 15.2472L280.589 15.242C277.955 13.8474 274.812 13.14 271.143
                           13.14H259.803V46.86H271.143C274.814 46.86 277.958 46.1787 280.593 44.836C283.284 43.4385
                           285.345 41.4783 286.789 38.9519ZM280.823 45.28C278.103 46.6667 274.876 47.36 271.143
                           47.36H259.303V12.64H271.143C274.876 12.64 278.103 13.36 280.823 14.8C283.596 16.1867
                           285.73 18.1867 287.223 20.8C288.77 23.36 289.543 26.4267 289.543 30C289.543 33.52 288.77
                            36.5867 287.223 39.2C285.73 41.8133 283.596 43.84 280.823 45.28ZM303.525 58.5L328.931
                            1.50001H342.38L367.867 58.5H353.162L348.191 46.5H322.9L317.909 58.5H303.525ZM322.566
                             46H348.525L353.496 58H367.096L342.056 2.00001H329.256L304.296 58H317.576L322.566
                             46ZM335.571 14.728L326.658 36.16H344.449L335.571 14.728ZM343.7 35.66L335.57
                             16.0323L327.407 35.66H343.7ZM381.193 58.5V13.06H363.273V1.50001H413.073V13.06H395.153V58.5H381.193ZM394.653
                             12.56H412.573V2.00001H363.773V12.56H381.693V58H394.653V12.56ZM419.046
                             58.5V1.50001H433.006V58.5H419.046ZM460.499 57.2674L460.496 57.2665C456.706 55.8044
                              453.396 53.7439 450.573 51.0839L450.57 51.0806C447.802 48.421 445.631 45.2999
                               444.059 41.7211L444.056 41.7153C442.534 38.1283 441.776 34.2212 441.776
                               30C441.776 25.7789 442.534 21.8718 444.056 18.2847L444.058 18.2789C445.632
                               14.698 447.831 11.5759 450.653 8.91611C453.476 6.25613 456.786 4.19564
                               460.576 2.7335C464.372 1.26939 468.487 0.540009 472.916 0.540009C477.397 0.540009
                               481.513 1.26891
                               485.257 2.7338L494.756 8.5338L494.756 8.91611M494.756 8.91611C497.53 11.5294
                               499.69 14.96 501.236 18.48C502.783 21.9467 503.556 25.7867 503.556 30C503.556
                               34.16 502.783 38.0267 501.236 41.6C499.69 45.12 497.53 48.1867 494.756
                               50.8C492.036 53.36 488.81 55.36 485.076 56.8C481.396 58.24 477.37 58.96
                               472.996 58.96C468.57 58.96 464.463 58.24 460.676 56.8C456.943 55.36 453.69 53.3333
                               450.916 50.72C448.196 48.1067 446.063 45.04 444.516 41.52C443.023 38 442.276 34.16
                                442.276 30C442.276 25.84 443.023 22 444.516 18.48M485.257 57.2661C481.514 58.7309
                                 477.425 59.46 472.996 59.46C468.514 59.46 464.347 58.7309 460.499 57.2674M479.606
                                 46.1774L479.611 46.1758C481.682 45.3472 483.491 44.1578 485.043 42.6065C486.592
                                  41.0572 487.781 39.223 488.61 37.0982L488.614 37.0885C489.493 34.9698 489.936
                                  32.6092 489.936 30C489.936 27.3908 489.493 25.0303 488.614
                                   22.9115L488.61 22.9018C487.781 20.7771 486.592 18.9428 485.043
                                   17.3936L485.037 17.3876C483.541 15.8399 481.761 14.6522 479.691
                                   13.8242C477.621 12.9966 475.365 12.58 472.916 12.58C470.468 12.58 468.213
                                   12.9963 466.144 13.8234C464.127 14.6514 462.343 15.8406 460.79
                                   17.3936C459.239 18.944 458.023 20.78 457.14 22.907C456.314 25.0271
                                    455.896 27.3893 455.896 30C455.896 32.5553 456.313 34.9188 457.141
                                    37.0952C458.024 39.2235 459.216 41.0599 460.713 42.6097C462.264 44.1594
                                    464.072 45.3478 466.142 46.1758C468.211 47.0034 470.467 47.42 472.916
                                     47.42C475.365 47.42 477.593 47.0034 479.606 46.1774ZM456.676 37.28C455.823
                                      35.04 455.396 32.6133 455.396 30C455.396 27.3333 455.823 24.9067 456.676
                                      22.72C457.583 20.5333 458.836 18.64 460.436 17.04C462.036 15.44 463.876
                                      14.2133 465.956 13.36C468.09 12.5067 470.41 12.08 472.916 12.08C475.423
                                       12.08 477.743 12.5067 479.876 13.36C482.01 14.2133 483.85 15.44 485.396
                                       17.04C486.996 18.64 488.223 20.5333 489.076 22.72C489.983 24.9067
                                        490.436 27.3333 490.436 30C490.436 32.6667 489.983 35.0933 489.076
                                         37.28C488.223 39.4667 486.996 41.36 485.396 42.96C483.796 44.56
                                          481.93 45.7867 479.796 46.64C477.716 47.4933 475.423 47.92 472.916
                                           47.92C470.41 47.92 468.09 47.4933 465.956 46.64C463.823 45.7867
                                            461.956 44.56 460.356 42.96C458.81 41.36 457.583 39.4667 456.676
                                            37.28ZM512.796 58.5V1.50001H524.253L551.356
                                             34.5752V1.50001H565.156V58.5H553.78L526.596 25.4069V58.5H512.796ZM526.096
                                              24.0105L554.016 58H564.656V2.00001H551.856V35.9742L524.016
                                              2.00001H513.296V58H526.096V24.0105ZM124.927 52.32C129.407 56.7467
                                               135.62 58.96 143.567 58.96C151.514 58.96 157.727 56.7467 162.207
                                                52.32C166.687 47.8933 168.927 41.5733 168.927
                                                33.36V2.00001H156.127V32.88C156.127 38.2133 155.034 42.0533
                                                 152.847 44.4C150.66 46.7467 147.594 47.92 143.647 47.92C139.7
                                                 47.92 136.634 46.7467 134.447 44.4C132.26 42.0533 131.167 38.2133
                                                 131.167 32.88V2.00001H118.207V33.36C118.207 41.5733 120.447
                                                 47.8933 124.927 52.32ZM419.546 58H432.506V2.00001H419.546V58Z"/>
        </g>
        </svg>

};

export default FoundationSVG;

