import React from 'react';

const ContactSVG = () => {
    return <svg width="466" height="79" xmlns="http://www.w3.org/2000/svg"
                x="0px" y="0px" viewBox="0 0 267.8 43.8"
                style={{enableBackground: 'new 0 0 267.8 43.8'}} xmlSpace="preserve">
        <g>
            <path d="M35.2,29c-0.2,2.2-0.6,4.2-1.5,6s-2,3.4-3.4,4.7c-1.4,1.3-3.2,2.3-5.3,3c-2.1,0.7-4.4,1.1-7,1.1c-2.7,0-5.2-0.5-7.4-1.4
    		c-2.2-0.9-4-2.3-5.5-4.1c-1.5-1.8-2.7-3.9-3.5-6.5c-0.8-2.5-1.2-5.4-1.2-8.5v-2.8c0-3.1,0.4-6,1.3-8.5c0.8-2.5,2-4.7,3.6-6.5
    		c1.5-1.8,3.4-3.1,5.6-4.1C13,0.5,15.5,0,18.2,0c2.7,0,5,0.4,7,1.1c2,0.8,3.8,1.8,5.2,3.1C31.9,5.6,33,7.2,33.8,9
    		c0.8,1.8,1.3,3.9,1.5,6h-8.8c-0.1-1.3-0.3-2.4-0.7-3.4c-0.3-1-0.8-1.8-1.5-2.5c-0.6-0.7-1.5-1.2-2.5-1.5c-1-0.3-2.2-0.5-3.7-0.5
    		c-3,0-5.2,1.1-6.6,3.2c-1.5,2.2-2.2,5.5-2.2,10v2.9c0,2.2,0.2,4.1,0.5,5.8c0.3,1.7,0.8,3,1.5,4.2c0.7,1.1,1.6,1.9,2.7,2.5
    		c1.1,0.6,2.4,0.8,4,0.8c1.4,0,2.6-0.2,3.6-0.5c1-0.3,1.8-0.8,2.5-1.4s1.2-1.4,1.6-2.4c0.4-1,0.6-2.1,0.7-3.3H35.2z"/>
            <path d="M76,22.9c0,3.3-0.4,6.2-1.3,8.8c-0.9,2.6-2.1,4.8-3.7,6.6c-1.6,1.8-3.5,3.2-5.7,4.1c-2.2,1-4.7,1.5-7.4,1.5
    		c-2.7,0-5.1-0.5-7.4-1.5s-4.1-2.3-5.8-4.1s-2.9-4-3.8-6.6c-0.9-2.6-1.3-5.5-1.3-8.8V21c0-3.2,0.4-6.2,1.3-8.8
    		c0.9-2.6,2.1-4.8,3.7-6.6c1.6-1.8,3.5-3.2,5.7-4.2S55.2,0,57.9,0c2.7,0,5.1,0.5,7.4,1.5c2.2,1,4.1,2.4,5.7,4.2
    		c1.6,1.8,2.8,4,3.7,6.6c0.9,2.6,1.3,5.5,1.3,8.8V22.9z M67.1,20.9c0-4.5-0.8-7.9-2.4-10.2c-1.6-2.3-3.9-3.5-6.9-3.5
    		c-3,0-5.3,1.2-6.9,3.5c-1.6,2.3-2.4,5.7-2.4,10.2v1.9c0,2.2,0.2,4.2,0.6,5.9c0.4,1.7,1,3.1,1.8,4.3s1.8,2,2.9,2.6
    		c1.2,0.6,2.5,0.9,4,0.9c3,0,5.2-1.2,6.8-3.5c1.6-2.3,2.4-5.8,2.4-10.2V20.9z"/>
            <path d="M117.1,43.2h-8.8L91.2,15.2v28.1h-8.8V0.6h8.8l17.1,28.1V0.6h8.8V43.2z"/>
            <path d="M156.1,7.7H143v35.5h-8.8V7.7h-12.9V0.6h34.7V7.7z"/>
            <path
                d="M181.6,34.5h-15.4l-2.9,8.8h-9.3l15.9-42.7h8.1l16,42.7h-9.3L181.6,34.5z M168.6,27.3h10.7l-5.4-16L168.6,27.3z"/>
            <path d="M230.8,29c-0.2,2.2-0.6,4.2-1.5,6s-2,3.4-3.4,4.7s-3.2,2.3-5.3,3s-4.4,1.1-7,1.1c-2.7,0-5.2-0.5-7.4-1.4s-4-2.3-5.5-4.1
    		s-2.7-3.9-3.5-6.5c-0.8-2.5-1.2-5.4-1.2-8.5v-2.8c0-3.1,0.4-6,1.3-8.5c0.8-2.5,2-4.7,3.6-6.5c1.5-1.8,3.4-3.1,5.6-4.1
    		c2.2-1,4.6-1.4,7.3-1.4c2.7,0,5,0.4,7,1.1c2,0.8,3.8,1.8,5.2,3.1c1.4,1.3,2.5,2.9,3.3,4.8s1.3,3.9,1.5,6h-8.8
    		c-0.1-1.3-0.3-2.4-0.7-3.4c-0.3-1-0.8-1.8-1.5-2.5c-0.6-0.7-1.5-1.2-2.5-1.5c-1-0.3-2.2-0.5-3.7-0.5c-3,0-5.2,1.1-6.6,3.2
    		c-1.5,2.2-2.2,5.5-2.2,10v2.9c0,2.2,0.2,4.1,0.5,5.8c0.3,1.7,0.8,3,1.5,4.2s1.6,1.9,2.7,2.5s2.4,0.8,4,0.8c1.4,0,2.6-0.2,3.6-0.5
    		s1.8-0.8,2.5-1.4s1.2-1.4,1.6-2.4s0.6-2.1,0.7-3.3H230.8z"/>
            <path d="M267.8,7.7h-13.1v35.5H246V7.7h-12.9V0.6h34.7V7.7z"/>
        </g>
    </svg>
};

export default ContactSVG;