import React from 'react';

const CareersSvgFr = () => {
    return <svg xmlns="http://www.w3.org/2000/svg"
                x="0px" y="0px"
                width="545.5px"
                height="95.8px"
                viewBox="0 0 545.5 95.8"
                style={{enableBackground: 'new 0 0 545.5 95.8'}}
                xmlSpace="preserve">
					<g>
						<path d="M60.9,69.9c-0.3,3.8-1.1,7.3-2.6,10.5s-3.4,5.9-5.9,8.2c-2.5,2.3-5.6,4.1-9.2,5.3c-3.6,1.3-7.7,1.9-12.3,1.9
							c-4.8,0-9.1-0.8-12.9-2.5c-3.8-1.7-7-4-9.7-7.2c-2.6-3.1-4.7-6.9-6.1-11.3C0.7,70.5,0,65.5,0,60v-4.9c0-5.5,0.7-10.5,2.2-14.9
							c1.5-4.4,3.5-8.2,6.2-11.3c2.7-3.1,5.9-5.5,9.8-7.2C22,20,26.3,19.1,31,19.1c4.6,0,8.8,0.7,12.3,2c3.6,1.3,6.6,3.1,9.1,5.5
							c2.5,2.3,4.4,5.1,5.8,8.4c1.4,3.2,2.3,6.7,2.7,10.5H45.6c-0.2-2.2-0.6-4.2-1.2-5.9c-0.6-1.7-1.5-3.1-2.6-4.3
							c-1.1-1.2-2.6-2-4.4-2.6c-1.8-0.6-3.9-0.9-6.5-0.9c-5.2,0-9.1,1.9-11.6,5.7c-2.5,3.8-3.8,9.6-3.8,17.6v5c0,3.9,0.3,7.2,0.8,10.2
							c0.6,2.9,1.5,5.3,2.7,7.3c1.2,1.9,2.8,3.4,4.7,4.4c1.9,1,4.3,1.5,7,1.5c2.4,0,4.5-0.3,6.3-0.8c1.8-0.5,3.2-1.4,4.4-2.5
							c1.2-1.1,2.1-2.5,2.7-4.2c0.6-1.7,1.1-3.6,1.2-5.8H60.9z"/>
                        <path d="M113.1,79.4h-27L81,94.8H64.7l27.8-74.6h14.3l27.9,74.6h-16.4L113.1,79.4z M90.3,67h18.7L99.6,39L90.3,67z"/>
                        <path d="M169.2,67.5H157v27.3h-15.4V20.1h27.7c4.3,0,8.2,0.5,11.6,1.5c3.4,1,6.3,2.4,8.7,4.3c2.4,1.9,4.2,4.2,5.4,7
							c1.3,2.8,1.9,6,1.9,9.7c0,2.7-0.3,5.2-0.9,7.4c-0.6,2.2-1.5,4.1-2.7,5.8c-1.2,1.7-2.6,3.2-4.2,4.4s-3.5,2.4-5.5,3.3l16.2,30.5v0.7
							h-16.5L169.2,67.5z M157,55h12.4c2,0,3.8-0.3,5.3-0.8c1.5-0.5,2.8-1.3,3.8-2.3c1-1,1.8-2.1,2.3-3.5c0.5-1.3,0.8-2.9,0.8-4.5
							c0-3.5-1-6.3-3-8.3c-2-2-5.1-3-9.2-3H157V55z"/>
                        <path d="M236.2,67.5H224v27.3h-15.4V20.1h27.7c4.3,0,8.2,0.5,11.6,1.5c3.4,1,6.3,2.4,8.7,4.3c2.4,1.9,4.2,4.2,5.4,7
							c1.3,2.8,1.9,6,1.9,9.7c0,2.7-0.3,5.2-0.9,7.4c-0.6,2.2-1.5,4.1-2.7,5.8c-1.2,1.7-2.6,3.2-4.2,4.4s-3.5,2.4-5.5,3.3l16.2,30.5v0.7
							h-16.5L236.2,67.5z M224,55h12.4c2,0,3.8-0.3,5.3-0.8c1.5-0.5,2.8-1.3,3.8-2.3c1-1,1.8-2.1,2.3-3.5c0.5-1.3,0.8-2.9,0.8-4.5
							c0-3.5-1-6.3-3-8.3c-2-2-5.1-3-9.2-3H224V55z"/>
                        <path d="M292,94.8h-15.4V20.1H292V94.8z"/>
                        <path d="M351.1,62.4h-29.5v20h34.7v12.4h-50V20.1h49.9v12.5h-34.6v17.8h29.5V62.4z M339.5,15.9h-12.2L313.2,0h16.1L339.5,15.9z"/>
                        <path d="M392.9,67.5h-12.3v27.3h-15.4V20.1H393c4.3,0,8.2,0.5,11.6,1.5c3.4,1,6.3,2.4,8.7,4.3c2.4,1.9,4.2,4.2,5.4,7
							c1.3,2.8,1.9,6,1.9,9.7c0,2.7-0.3,5.2-0.9,7.4c-0.6,2.2-1.5,4.1-2.7,5.8c-1.2,1.7-2.6,3.2-4.2,4.4s-3.5,2.4-5.5,3.3l16.2,30.5v0.7
							h-16.5L392.9,67.5z M380.7,55h12.4c2,0,3.8-0.3,5.3-0.8c1.5-0.5,2.8-1.3,3.8-2.3c1-1,1.8-2.1,2.3-3.5c0.5-1.3,0.8-2.9,0.8-4.5
							c0-3.5-1-6.3-3-8.3c-2-2-5.1-3-9.2-3h-12.4V55z"/>
                        <path d="M477.2,62.4h-29.5v20h34.7v12.4h-50V20.1h49.9v12.5h-34.6v17.8h29.5V62.4z"/>
                        <path d="M530.1,75.2c0-1.3-0.2-2.5-0.6-3.5c-0.4-1-1.1-2-2.2-2.9c-1.1-0.9-2.6-1.8-4.5-2.6c-1.9-0.9-4.3-1.8-7.4-2.7
							c-3.4-1.1-6.6-2.3-9.7-3.7c-3-1.4-5.7-3-7.9-4.8c-2.3-1.9-4.1-4-5.4-6.4c-1.3-2.4-2-5.3-2-8.5c0-3.1,0.7-6,2-8.5
							c1.3-2.6,3.2-4.8,5.7-6.6c2.4-1.8,5.3-3.2,8.7-4.2c3.4-1,7.1-1.5,11.1-1.5c4.1,0,7.9,0.6,11.3,1.7c3.4,1.1,6.3,2.7,8.7,4.8
							c2.4,2.1,4.2,4.5,5.5,7.3c1.3,2.8,1.9,5.9,1.9,9.3h-15.4c0-1.6-0.2-3-0.7-4.4c-0.5-1.3-1.3-2.5-2.3-3.4c-1-1-2.3-1.7-3.8-2.2
							c-1.5-0.5-3.4-0.8-5.4-0.8c-2,0-3.8,0.2-5.3,0.7c-1.5,0.4-2.7,1.1-3.7,1.8c-1,0.8-1.7,1.7-2.2,2.8c-0.5,1.1-0.7,2.2-0.7,3.4
							c0,2.5,1.3,4.5,3.8,6.2c2.5,1.7,6.2,3.2,11,4.6c4.1,1.3,7.6,2.7,10.7,4.2c3.1,1.5,5.7,3.3,7.7,5.2c2.1,1.9,3.6,4.1,4.7,6.6
							c1.1,2.4,1.6,5.2,1.6,8.3c0,3.3-0.6,6.2-1.9,8.8c-1.3,2.6-3.1,4.7-5.5,6.5c-2.4,1.8-5.2,3.1-8.5,4c-3.3,0.9-7,1.4-11.2,1.4
							c-2.5,0-4.9-0.2-7.4-0.6s-4.8-1.1-7-2c-2.2-0.9-4.3-2-6.2-3.3c-1.9-1.3-3.6-2.9-5-4.7c-1.4-1.8-2.5-3.9-3.3-6.2
							c-0.8-2.3-1.2-4.9-1.2-7.7h15.4c0,2.3,0.4,4.2,1.1,5.7c0.7,1.6,1.7,2.8,3,3.8c1.3,1,2.8,1.7,4.6,2.1c1.8,0.4,3.8,0.7,6,0.7
							c2,0,3.7-0.2,5.2-0.6c1.5-0.4,2.7-1,3.6-1.8c1-0.8,1.7-1.6,2.1-2.7C529.8,77.5,530.1,76.4,530.1,75.2z"/>
					</g>
					</svg>
};

export default CareersSvgFr;