import './App.css';
import React, {useEffect, useState} from 'react';
import ReactGA from 'react-ga';
import {createBrowserHistory} from 'history';
import {Route, Router, Switch} from 'react-router-dom';
import Home from "../home/home";
import Directions from "../directions/directions";
import Careers from "../careers/careers";
import Contact from "../contact/contact";
import Header from "../header/header";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from "../footer/footer";
import PrivacyPolicy from "../privacy-policy/privacy-policy";
import TermsAndConditions from "../terms-and-conditions/terms-and-conditions";
import LanguageContext from "../../context/language-context";
import {EN, FR} from "../../dictionary/dictionary";
import About from "../about/about";
import WrapperNeuromathematicsAndNeurobiology from "../projects/wrapper-neuromathematics-and-neurobiology";
import WrapperBiopharma from "../projects/wrapper-biopharma";
import WrapperFintech from "../projects/wrapper-fintech";
import WrapperDataEcosystemsAndCybersecurity from "../projects/wrapper-data-ecosystems-and-cybersecurity";
import WrapperAutonomousVehicles from "../projects/wrapper-autonomous-vehicles";
import WrapperEnergyGenerationAndStorage from "../projects/wrapper-energy-generation-and-storage";
import WrapperAugmentedReality from "../projects/wrapper-augmented-reality";
import WrapperEdtech from "../projects/wrapper-edtech";
import WrapperGaming from "../projects/wrapper-gaming";
import ScrollToTop from "../scroll-to-top/scroll-to-top";
import NotFound from "../not-found/not-found";
import Esg from "../esg/Esg";
import Foundation from "../foundation/foundation";
import {useCookies} from "react-cookie";
import {isEmptyObj, stringToBoolean} from "../../util/util";
import {
    addCookie,
    allCookies,
    cookieConsent,
    cookieConsentDate,
    cookieOtherEnabled,
    cookiePreferences,
    deleteCookie
} from "../cookie/cookie-util";
import Cookie from "../cookie/Cookie";
import withSaveCookie from "../../hoc/withSaveCookie";

const history = createBrowserHistory();

const App = () => {
    const [language, setLanguage] = useState(EN);
    const [cookie, setCookie, removeCookie] = useCookies([...allCookies]);

    useEffect(() => {
        if (!isEmptyObj(cookie) && stringToBoolean(cookie[cookieConsent]) && !cookie[cookieConsentDate])
            deleteCookie(removeCookie, cookieConsent);
    }, [cookie]);

    useEffect(() => {
        if (stringToBoolean(cookie[cookieOtherEnabled]) && isEmptyObj(cookie[cookiePreferences])) {
            fetch('https://geolocation-db.com/json/')
                .then(response => response.json())
                .then(data => {
                    addCookie(setCookie, cookiePreferences, JSON.stringify({
                        ip: data.IPv4,
                        country: data.country_name,
                        latitude: data.latitude,
                        longitude: data.longitude,
                        time: new Date().getTime()
                    }));
                })
                .catch(error => console.log(error));
        }
    }, [cookie, setCookie]);

    const onLanguageChange = (language) => {
        let newLanguage = {};
        switch (language) {
            case "EN":
                newLanguage = EN;
                break;
            case "FR":
                newLanguage = FR;
                break;
            default:
                newLanguage = EN;
        }

        setLanguage(newLanguage);
    };

    useEffect(() => {
        AOS.init({duration: 1500});
        ReactGA.initialize("UA-185652223-1");
        history.listen(location => {
            ReactGA.set({page: location.pathname}); // Update the user's current page
            ReactGA.pageview(location.pathname); // Record a pageview for the given page
        });
    }, []);

    return <Router history={history}>
        <LanguageContext.Provider value={language}>
            <Header
                onLanguageChange={onLanguageChange}
            />
            <ScrollToTop>
                <Switch>
                    <Route path={["/", "/fr"]} exact>
                        <Home/>
                    </Route>
                    <Route path={["/investment", "/fr/investissement"]} exact>
                        <Directions/>
                    </Route>
                    <Route path={["/about", "/fr/a-propos"]} exact>
                        <About/>
                    </Route>
                    <Route path={["/esg", "/fr/esg"]} exact>
                        <Esg/>
                    </Route>
                    <Route path={["/foundation", "/fr/foundation"]} exact>
                        <Foundation/>
                    </Route>
                    <Route path={["/careers", "/fr/carrieres"]} exact>
                        <Careers/>
                    </Route>
                    <Route path={["/contact", "/fr/contact"]} exact>
                        <Contact/>
                    </Route>
                    <Route path={["/privacy-policy", "/fr/politique-de-confidentialite"]} exact>
                        <PrivacyPolicy/>
                    </Route>
                    <Route path={["/terms-conditions", "/fr/conditions-generales"]} exact>
                        <TermsAndConditions/>
                    </Route>
                    <Route
                        path={["/investment/neuromathematics-and-neurobiology", "/fr/investissement/neuromathematics-and-neurobiology"]}
                        exact>
                        <WrapperNeuromathematicsAndNeurobiology/>
                    </Route>
                    <Route path={["/investment/biopharma", "/fr/investissement/biopharma"]} exact>
                        <WrapperBiopharma/>
                    </Route>
                    <Route path={["/investment/fintech", "/fr/investissement/fintech"]} exact>
                        <WrapperFintech/>
                    </Route>
                    <Route
                        path={["/investment/data-ecosystems-and-cybersecurity", "/fr/investissement/data-ecosystems-and-cybersecurity"]}
                        exact>
                        <WrapperDataEcosystemsAndCybersecurity/>
                    </Route>
                    <Route path={["/investment/autonomous-vehicles", "/fr/investissement/autonomous-vehicles"]} exact>
                        <WrapperAutonomousVehicles/>
                    </Route>
                    <Route
                        path={["/investment/energy-generation-and-storage", "/fr/investissement/energy-generation-and-storage"]}
                        exact>
                        <WrapperEnergyGenerationAndStorage/>
                    </Route>
                    <Route path={["/investment/augmented-reality", "/fr/investissement/augmented-reality"]} exact>
                        <WrapperAugmentedReality/>
                    </Route>
                    <Route path={["/investment/edtech", "/fr/investissement/edtech"]} exact>
                        <WrapperEdtech/>
                    </Route>
                    <Route path={["/investment/gaming", "/fr/investissement/gaming"]} exact>
                        <WrapperGaming/>
                    </Route>
                    <Route component={NotFound}/>
                </Switch>
            </ScrollToTop>

            <Footer/>
            <Cookie
                cookie={cookie}
                setCookie={setCookie}
                removeCookie={removeCookie}
            />
        </LanguageContext.Provider>
    </Router>;
};

export default withSaveCookie(App);
