// const host = 'http://10.255.3.96:8092';
// const host = 'http://localhost:8092';
const host = '';

export default class HttpService {
    _defaultResponseAccessor = async (res) => await res;

    postRequest = async (route, body, responseAccessor = this._defaultResponseAccessor) =>
        this._request(route, 'POST', body, responseAccessor);

    _request = async (route, method, body, responseAccessor = this._defaultResponseAccessor) => {
        const res = await fetch(host + route, {method, body});

        if (!res.ok) {
            await res.json().then((e) => {
                throw new Error(e.message);
            });
        }

        return responseAccessor(res);
    };
}