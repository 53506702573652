import React, {useContext} from 'react';
import LanguageContext from "../../context/language-context";
import Project from "./project";
import withCookie from "../../hoc/withCookie";

const WrapperAutonomousVehicles = () => {
const {projects} = useContext(LanguageContext);

    return <div>
        <Project
            data={projects.vehicles}
        />
    </div>
};

export default withCookie(WrapperAutonomousVehicles);

