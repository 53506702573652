import React from 'react';

const CoreValueBlock = ({text1, text2, textList}) => {
    const createTextBlock = (bold, text, dataDelay, transition, idx) => {
        return <div key={`corevalue${idx}`}
                    className="coreListitem w33 text aos aos-init" data-aos="fade-up" data-delay={dataDelay}
                    style={{transitionDelay: transition}}>
            <b>{bold}</b>
            <p>{text}</p>
        </div>
    };

    return <div className="coreValues">
        <div className="container no_padding_top">
            <div className="text lessWidth aos aos-init" data-aos="fade-up" data-delay="0"
                 style={{transitionDelay: '0ms'}}>
                <h3>{text1}</h3>
                <p>{text2}</p>
            </div>
            <div className="coreList row aos aos-init" data-aos="fade-up" data-delay="50"
                 style={{transitionDelay: '50ms'}}>
                {textList.map((item, idx) => {
                    const {boldText, text, transition} = item;
                    return createTextBlock(boldText, text, transition, `${transition}ms`, idx)
                })}
            </div>
        </div>
    </div>;
};

export default CoreValueBlock;

