import React, {useContext, useEffect, useState} from 'react';
import LanguageContext from "../../context/language-context";

const emailPattern = RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,})+$");
const phonePattern = RegExp("^\\+[0-9]");

const ValidLabelInput = ({labelText, inputType, inputSize, inputMaxLength, value, setValue, addErrors}) => {
    const [firstClick, setFirstClick] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const {errorMessages} = useContext(LanguageContext);
    const {text, email, phoneNumber} = errorMessages;

    const uniqueName = labelText.replace(/\s/g, '');

    useEffect(() => {
        addErrors((hasErrors) => {return {...hasErrors, [uniqueName]: true}});
    }, []);

    const validation = (e) => {
        const newVal = e.target.value;

        setValue(newVal);

        if (!newVal || !newVal) {
            setErrorMessage(text);
            addErrors((hasErrors) => {return {...hasErrors, [uniqueName]: true}});
            return;
        }

        if (inputType === 'email') {
            if (!emailPattern.test(newVal)) {
                setErrorMessage(email);
                addErrors((hasErrors) => {return {...hasErrors, [uniqueName]: true}});
                return;
            }
        }

        if (inputType === 'tel') {
            if (!phonePattern.test(newVal)) {
                setErrorMessage(phoneNumber);
                addErrors((hasErrors) => {return {...hasErrors, [uniqueName]: true}});
                return;
            }
        }

        addErrors((hasErrors) => {return {...hasErrors, [uniqueName]: false}});
        setErrorMessage('');
    };

    return <label className={`with_line ${firstClick ? 'hover' : ''}`}>
        <span className={"hover_text"}>
            {labelText}
        </span>
        <span>
           <input onFocus={() => {
               if (!firstClick)
                   setFirstClick(true);
           }}
                  onChange={(e) => validation(e)}
                  type={inputType}
                  value={value}
                  size={inputSize}
                  maxLength={inputMaxLength}/>
            {
                errorMessage.length
                    ? <span role="alert">{errorMessage}</span>
                    : null
            }

         </span>
    </label>;
};

export default ValidLabelInput;

