import React, {useCallback, useContext, useEffect, useState} from "react";
import {HttpServiceContext} from "../context";
import {useCookies} from "react-cookie";
import {
    allCookies,
    cookieConsent,
    cookieConsentDate,
    cookieOtherEnabled,
    cookiePreferences,
    cookieStatistics,
    userId
} from "../component/cookie/cookie-util";
import {isEmptyObj, stringToBoolean} from "../util/util";

const withSaveCookie = (WrappedComponent) => {
    return (props) => {
        const httpService = useContext(HttpServiceContext);
        const [oldCookie, setOldCookie] = useState("");
        const [cookie] = useCookies([...allCookies]);

        const saveCookie = useCallback(() => {
            const formData = new FormData();
            formData.append('userId', cookie[userId]);
            formData.append('cookieConsent', cookie[cookieConsent]);
            formData.append('cookieConsentTime', cookie[cookieConsentDate] ? new Date(cookie[cookieConsentDate]).getTime() : 0);

            if (stringToBoolean(cookie[cookieOtherEnabled])) {
                const preference = cookie[cookiePreferences] || {};

                formData.append('preferencesIp', preference.ip);
                formData.append('preferencesCountry', preference.country);
                formData.append('preferencesLatitude', preference.latitude);
                formData.append('preferencesLongitude', preference.longitude);
                formData.append('preferencesTime', preference.time);

                formData.append('cookieStatistics', JSON.stringify(cookie[cookieStatistics] || {}));
            }

            httpService.postRequest('/cookie', formData)
                .then(() => null)
                .catch(() => {
                    console.log('ERROR WHILE saveCookie');
                });
        }, [cookie, httpService])

        useEffect(() => {
            const stringCookie = JSON.stringify(cookie);
            let interval;

            if (!isEmptyObj(cookie) && oldCookie !== stringCookie) {
                interval = setInterval(() => {
                    setOldCookie(stringCookie);
                    saveCookie();
                }, 1000);
            }

            return () => interval && clearInterval(interval);
        }, [cookie, oldCookie, saveCookie]);

        return <WrappedComponent
            saveCookie={saveCookie}
            {...props}
        />;
    };
};

export default withSaveCookie;