import React, {useContext} from 'react';
import {ReactComponent as SicticLogo} from "../../../svg/SICTIC_logo.svg";
import {ReactComponent as SecaLogo} from "../../../svg/Seca_logo.svg";
import "./members.scss";
import LanguageContext from "../../../context/language-context";

const Members = () => {
    const {directionsPageInfo} = useContext(LanguageContext);
    const {header} = directionsPageInfo.members;

    return (
        <div className="coreValues start-ups members">
            <div className="container no_padding_top start-ups__container">
                <div
                    className="text lessWidth aos aos-init"
                    style={{transitionDelay: '0ms', marginTop: "27px"}}
                    data-aos="fade-up" data-delay="0"
                >
                    <h2 style={{fontWeight: 400}}>{header}</h2>
                    <div
                        className="coreList row aos aos-init start-ups__criteria"
                        data-aos="fade-up"
                        data-delay="50"
                        style={{transitionDelay: '50ms'}}
                    >
                        <div className="members__logos">
                            <a href="https://www.seca.ch/" target="_blank" rel="noreferrer"><SecaLogo/></a>
                            <a href="https://www.sictic.ch/" target="_blank" rel="noreferrer"><SicticLogo/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Members;