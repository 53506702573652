import React, {useContext} from 'react';
import LanguageContext from "../../../context/language-context";
import './start-ups.scss';

const StartUps  = () => {
    const {foundationPage: {criteria, criteriaTitle, criteriaTitleText}} = useContext(LanguageContext);

    const createTextBlock = (title, description, dataDelay, transition, index) => {
        return (
            <div
                key={index}
                className="coreListitem w33 text aos aos-init"
                data-aos="fade-up" data-delay={dataDelay}
                style={{transitionDelay: transition}}>
                <b>{title}</b>
                <p>{description}</p>
            </div>
        );
    };

    return (
        <div className="coreValues start-ups" style={{marginTop: 60}}>
            <div className="container no_padding_top start-ups__container">
                <div
                    className="text lessWidth aos aos-init"
                    style={{transitionDelay: '0ms', marginTop: "27px"}}
                    data-aos="fade-up" data-delay="0"
                >
                    <h2>{criteriaTitle}</h2>
                    <p>{criteriaTitleText}</p>
                    <div
                        className="coreList row aos aos-init start-ups__criteria"
                        data-aos="fade-up"
                        data-delay="50"
                        style={{transitionDelay: '50ms'}}
                    >
                        {criteria.map((item, idx) => {
                            const {title, description, transition} = item;
                            return createTextBlock(title, description, transition, `${transition}ms`, idx)
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartUps;

