import React from 'react';

export default class BtnScrollToTop extends React.Component {
    state = {
        intervalId: 0
    };

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 200);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), 16.66);
        this.setState({intervalId: intervalId});
    }

    render() {
        return (
            <div onClick={() => this.scrollToTop()}
                 className="scrollTopBtn">
                <svg width="20"
                     height="20"
                     viewBox="0 0 20 20"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.775 14.5917L18.25 13.1084L10 4.8667L1.75 13.1167L3.225 14.5917L10 7.8167L16.775 14.5917Z"
                        fill="#16224E"/>
                </svg>
            </div>
        );
    }
}
