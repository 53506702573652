import React, {useContext} from 'react';
import LanguageContext from "../../../context/language-context";
import WrapperLine from "./wrapper-line";
import NewLogoWithoutText from "../../icon/new_logo_without_text";

const TimeLineVision = () => {
    const {timeLineVision} = useContext(LanguageContext);
    const {text1, text2, text3, text4, list} = timeLineVision;

return <div className="timelineVision alignTextCenter">
    <div className="container container_without_padding">
        <div className="text mediumWidth aos aos-init" data-aos="fade-up">
            <h3>{text1}<b>{text2}</b>{text3}</h3>
            <p>{text4}</p>
        </div>

        <div className="timelineListBlock">
            <NewLogoWithoutText width={68} height={68}/>
            <ul className="timelineList">
                {list.map((item, idx) => <WrapperLine
                    key={'vision' + idx}
                    idx={'vision' + idx}
                    data={item}
                    translate={(idx === 0 || idx % 2 === 0) ? 50 : -50}
                />)}
            </ul>
        </div>
    </div>
</div>
};

export default TimeLineVision;

