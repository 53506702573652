import React, {useContext} from 'react';
import Project from "./project";
import LanguageContext from "../../context/language-context";
import withCookie from "../../hoc/withCookie";

const WrapperNeuromathematicsAndNeurobiology = () => {
    const {projects} = useContext(LanguageContext);

    return <div>
        <Project
            data={projects.neuromathematics}
        />
    </div>
};

export default withCookie(WrapperNeuromathematicsAndNeurobiology);

