import React, {useState} from 'react';
import {Close} from "../icon";
import {CSSTransition} from "react-transition-group";
import {Link} from "react-router-dom";

const HeaderMobile = ({showBurger, setShowBurger, navMenu, createLangBlock}) => {
    const [showBurgerLines, setShowBurgerLines] = useState(false);

    const createMobNavButton = (item, idx) => {
        return <CSSTransition
            key={idx}
            in={showBurgerLines}
            timeout={500}
            classNames="dialog-line"
            unmountOnExit
        >
            <li key={idx}>
                <span>
                     <Link to={item.link}
                           onClick={() => setShowBurger(false)}>
                         {item.caption}
                     </Link>
                </span>
            </li>
        </CSSTransition>
    };

    return <CSSTransition
        in={showBurger}
        timeout={200}
        classNames="dialog-nav-mob"
        unmountOnExit
        onEnter={() => {
            !showBurgerLines && setShowBurgerLines(true)
        }}
        onExit={() => {
            showBurgerLines && setShowBurgerLines(false)
        }}
    >
        <div className="mobile_row">
            <Close
                setState={setShowBurger}
            />

            <div className="wrap">
                <nav>
                    <ol>
                        {navMenu.filter(item => item.showInHeader).map((item, idx) => {
                            return createMobNavButton(item, idx);
                        })}
                    </ol>
                </nav>

                <CSSTransition
                    in={showBurgerLines}
                    timeout={500}
                    classNames="dialog-lang-block"
                    unmountOnExit
                >
                    {createLangBlock()}
                </CSSTransition>

            </div>
        </div>
    </CSSTransition>
};

export default HeaderMobile;