import React, {useContext} from 'react';
import LanguageContext from "../../../context/language-context";

const EthosBlock = () => {
    const {language} = useContext(LanguageContext);

    if (language === 'FR')
        return <div className="ethosText alignTextCenter aos aos-init aos-animate" data-aos="fade-up">
            <div className="container">
                <div className="text mediumWidth">
                    <h3>ETHOS</h3>
                    <p className="p2"><span
                        className="s1">Nous sommes – <em>“p-brain for future and science”</em></span>
                    </p>
                    <p className="p2"><span className="s1">Nous vouons une passion à la recherche des réponses aux questions les plus fondamentales. Nous repoussons les limites</span><span
                        className="s2">…</span><span className="s1"> Nous patientons le temps de récupérer</span><span
                        className="s2">…</span><span
                        className="s1"> Nous nous rassemblons pour faire un nouvel essai</span><span
                        className="s2">…</span><span
                        className="s1"> Nous explorons le problème depuis un autre point de vue</span><span
                        className="s2">…</span><span className="s1"> Et enfin, nous atteignons notre objectif.</span>
                    </p>
                    <p className="p2"><span className="s1">L’ambition de PBFS est de devenir une entreprise innovante qui sache inspirer confiance. Notre vocation pour la recherche et les sciences constitue une grande force motrice pour notre ambition de remodeler le monde positivement.</span>
                    </p>
                    <p className="p3"><span className="s1">Chez PBFS nous croyons que la recherche basée sur des méthodes mathématiques ou des processus physiques et chimiques peut élargir et améliorer notre compréhension du monde. La science est pour nous comme une forme de partition musicale. Tout ce que nous entreprenons est inspiré par notre curiosité, rempli de créativité, et toujours encadré par une dose de bon sens. Est-il possible de résoudre le grand mystère de la vie simplement à travers le prisme scientifique? La réponse est </span><span
                        className="s2">peut-être</span><span className="s1"> non. Mais peut-on rendre la vie plus belle, plus palpitante, plus durable, plus profonde et satisfaisante? Absolument, oui! C’est exactement ce à quoi nous travaillons.</span>
                    </p>
                    <p className="p4"><span className="s1">Nous croyons profondément à la force de l’audace intellectuelle basée sur une approche scientifique.</span>
                    </p>
                    <p className="p4"><span className="s1">Notre recherche permanente de méthodes et de solutions nouvelles, la découverte d’opportunités non encore explorées, et l’étude de l’inconnu sont autant de sources d’inspiration pour nous. Nous émettons des idées qui peuvent sembler audacieuses. Mais soyons honnêtes: les idées risquées ou même «folles» d’hier sont devenues notre réalité d’aujourd’hui.</span>
                    </p>
                    <p className="p2"><span className="s1">Chez PBFS: nous ne cherchons pas à prédire l’avenir, mais à le rendre meilleur. </span>
                    </p>
                    <p className="p1"><span className="s1"><b>Ce que dit la raison: «C’est impossible»</b></span></p>
                    <p className="p2"><span
                        className="s1"><b>Ce que suggère l’expérience: «C’est bien trop risqué»</b></span></p>
                    <p className="p3" style={{color: '#f67c1f'}}><span className="s1"><b>Notre réponse: «</b></span><span
                        className="s2"><b>Essayons</b></span><span className="s3"><b>!</b></span><span
                        className="s1"><b>»</b></span></p>
                </div>
            </div>
        </div>;


    return <div className="ethosText alignTextCenter aos aos-init aos-animate" data-aos="fade-up">
        <div className="container">
            <div className="text mediumWidth">
                <h3>Ethos</h3>
                <p>We are PBFS – <em>“p-brain for future and science”</em></p>
                <p>WE are DREAMERS who are in love with life. We are passionate about finding the answers to<br/>
                    the most intriguing life enigmas. We push the boundaries…&nbsp; Pull back to catch our<br/>
                    breath…&nbsp; Regroup to make another try…&nbsp; Explore it from a different perspective…&nbsp; And
                    finally,<br/>
                    achieve the goal.</p>
                <p>PBFS’s ambition is to become the confidence inspiring innovative company. Our vocation<br/>
                    for research and precise sciences is powerful driving force in our aspiration to reshape the
                    world<br/>
                    for the better.</p>
                <p>In PBFS we believe that research of mathematical methods, physical and chemical<br/>
                    processes can broaden our current understanding of reality. The science for us is music in<br/>
                    numbers. Everything we do is brought to life by curiosity, spiced up with creativity, and always<br/>
                    balanced by a common sense. Is it possible to solve the mystery of life purely through the prism<br/>
                    of the science? Perhaps, the answer is no. But to make it brighter, more exciting, sustainable,<br/>
                    deeper and fulfilling? Definitely yes! This is exactly what we strive for.</p>
                <p>We are true believers in intellectual fearlessness based on scientific approach.<br/>
                    Our continuous search for new methods and solutions, the discovery of unexplored<br/>
                    opportunities and study of the unknown is something that inspires us. We generate ideas that<br/>
                    may seem bold. But let’s be honest: the reckless or even “crazy” ideas of yesterday are our<br/>
                    reality today.</p>
                <p>In PBFS: we do not seek to foresee the future – we want to make it better.</p>
                <p><b>The Reason says: “This is impossible”</b></p>
                <p><b>Experience notes: “This is reckless”</b></p>
                <p><strong>WE say: “Let’s try it”</strong></p>
            </div>
        </div>
    </div>;
};

export default EthosBlock;

