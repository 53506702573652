import React, {useContext, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import LanguageContext from "../../context/language-context";

const ProjectSlider = ({slider}) => {
    const [moveToSlide, setMoveToSlide] = useState(0);
    const [idxOfSlide, setIdxOfSlide] = useState(0);

    const sliderRef = useRef(null);

    const sliderText = useContext(LanguageContext)[slider];

    const createSliderTitle = (title) => <div className="topRow"><span>{title}</span></div>;

    const createSliderContent = (name, descriptions, count) => <div className="wrap">
        <div className="mainRow">
            <span className="count">{count < 10 ? `0${count}` : count}</span>
            <h5>{name}</h5>
        </div>
        <ul>
            {descriptions.map((text, idx) => <li key={idx}>{text}</li>)}
        </ul>
    </div>;

    const createSlide = (data, idx) => {
        const {title, name, descriptions, img, url} = data;
        const count = idx + 1;

        return <div key={idx}
                    ref={idx === 0 ? sliderRef : null}
                    className="swiper-slide projectSliderItem swiper-slide-active"
                    style={{"marginRight": "16px"}}>
            <img className="bg swiper-lazy swiper-lazy-loaded" alt="projectSliderItem"
                 src={img}/>
            {title ?
                <Link to={url} className="content">
                    {createSliderTitle(title)}
                    {createSliderContent(name, descriptions, count)}
                </Link>
                :
                <div className="content">
                    {createSliderContent(name, descriptions, count)}
                </div>
            }
        </div>
    };

    const createSlideRow = (clazz, slideToRight) => {
        return <div className={clazz}
                    onClick={() => {
                        setMoveToSlide((slideTo) => {
                            return slideTo + (sliderRef.current["clientWidth"] + 16) * (slideToRight ? -1 : 1);
                        });
                        setIdxOfSlide((idxOfSlide) => {
                            return idxOfSlide - (slideToRight ? -1 : 1);
                        })
                    }}
                    tabIndex="0"
                    role="button"
                    aria-label="Previous slide"
                    aria-disabled="true">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M-6.11959e-07 9L12.17 9L6.58 14.59L8 16L16 8L8 6.99382e-07L6.59 1.41L12.17 7L-7.86805e-07 7L-6.11959e-07 9Z"
                    fill="#16224E"/>
            </svg>
        </div>
    };

    const createCircleUnderSlider = (item, idx) => {
        const clazzName = `swiper-pagination-bullet${idx === idxOfSlide ? ' swiper-pagination-bullet-active' : ''}`;

        return <span key={`circle${idx}`} className={clazzName}/>;
    };

    return <div className="projectSlider aos aos-init" data-aos="fade-up" data-delay="200"
                style={{"transitionDelay": "200ms"}}>
        <div className="swiper-container swiper-container-horizontal" style={{"width": "1354.4px"}}>
            <div className="swiper-wrapper"
                 style={{"transitionDuration": "300ms", "transform": `translate3d(${moveToSlide}px, 0px, 0px)`}}>
                {sliderText.map((item, idx) => {
                    return createSlide(item, idx);
                })}
            </div>
            <span className="swiper-notification" aria-live="assertive" aria-atomic="true"/></div>
        <div className="controllRow">
            {idxOfSlide > 0 &&
            createSlideRow('swiper-button-prev', false)
            }

            <div className="swiper-pagination swiper-pagination-bullets">
                {sliderText.map((item, idx) => createCircleUnderSlider(item, idx))}
            </div>

            {idxOfSlide < sliderText.length - 1 &&
            createSlideRow('swiper-button-next', true)
            }
        </div>
    </div>;
};

export default ProjectSlider;