import React from 'react';

const NotFoundIcon = () => {
    return  <svg width="177" height="79" xmlns="http://www.w3.org/2000/svg"
                 x="0px" y="0px" viewBox="0 0 100.1 43.8"
                 style={{enableBackground: 'new 0 0 100.1 43.8'}} xmlSpace="preserve">
        <g>
            <path
                d="M26.4,27.2h4.8V34h-4.8v9.2h-8.5V34H0.5l-0.4-5.3L17.9,0.6h8.6V27.2z M8.5,27.2h9.4V12.1l-0.6,1L8.5,27.2z"/>
            <path d="M64.5,25.6c0,3.2-0.3,6-1,8.2c-0.7,2.3-1.7,4.2-2.9,5.7s-2.8,2.6-4.5,3.3c-1.8,0.7-3.7,1-5.9,1c-2.1,0-4.1-0.3-5.9-1
		c-1.8-0.7-3.3-1.8-4.6-3.3c-1.3-1.5-2.3-3.4-3-5.7c-0.7-2.3-1.1-5-1.1-8.2v-7.4c0-3.2,0.3-6,1-8.2c0.7-2.3,1.7-4.2,2.9-5.7
		c1.3-1.5,2.8-2.6,4.5-3.3c1.8-0.7,3.7-1,5.9-1c2.1,0,4.1,0.3,5.9,1c1.8,0.7,3.3,1.8,4.6,3.3c1.3,1.5,2.3,3.4,3,5.7
		c0.7,2.3,1.1,5,1.1,8.2V25.6z M56,17c0-1.9-0.1-3.5-0.4-4.8S55,9.9,54.5,9.1c-0.5-0.8-1.1-1.4-1.9-1.7C51.8,7,51,6.9,50.1,6.9
		S48.3,7,47.5,7.4s-1.4,0.9-1.9,1.7c-0.5,0.8-0.9,1.8-1.2,3.1s-0.4,2.9-0.4,4.8v9.7c0,1.9,0.1,3.5,0.4,4.8c0.3,1.3,0.7,2.4,1.2,3.2
		c0.5,0.8,1.1,1.4,1.9,1.7c0.7,0.4,1.6,0.5,2.5,0.5s1.8-0.2,2.5-0.5c0.7-0.4,1.4-0.9,1.9-1.7s0.9-1.9,1.1-3.2
		c0.3-1.3,0.4-2.9,0.4-4.8V17z"/>
            <path
                d="M95.3,27.2h4.8V34h-4.8v9.2h-8.5V34H69.3l-0.4-5.3L86.7,0.6h8.6V27.2z M77.4,27.2h9.4V12.1l-0.6,1L77.4,27.2z"/>
        </g>
    </svg>
};

export default NotFoundIcon;

