import React, {useCallback, useEffect, useState} from 'react';
import {Button, Switch} from "antd";
import {isEmptyObj, stringToBoolean} from "../../util/util";
import {
    addCookie,
    cookieConsent,
    cookieConsentDate,
    cookieNecessary,
    cookieOther,
    cookieOtherEnabled,
    cookiePreferences,
    cookieStatistics,
    deleteCookie,
    uniqueId,
    userId
} from "./cookie-util";
import {faMinus, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CookieSettingsModal = ({removeCookie, setShowModal, cookie, setCookie}) => {
    const [enableOtherCookie, setEnableOtherCookie] = useState(false);
    const [description, setDescription] = useState({
        cookieNecessaryDescription: {
            text: "These cookies are necessary for the Website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies. These cookies do not store any personally identifiable information.",
            show: false
        },
        cookieOtherDescription: {
            text: "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance. We also collect information about the country and internet provider you use",
            show: false
        }
    });

    const setForm = useCallback(() => {
        setEnableOtherCookie(stringToBoolean(cookie[cookieOtherEnabled]));
    }, [cookie]);

    useEffect(() => {
        document.body.style.overflowY = "hidden";
        return () => (document.body.style.overflowY = "auto");
    }, []);

    useEffect(() => {
        if (!isEmptyObj(cookie))
            setForm();
    }, [setForm]);

    const onCloseModal = () => {
        setForm();
        setShowModal(false);
    }

    const removeAllCookies = () => {
        deleteCookie(removeCookie, cookiePreferences);
        deleteCookie(removeCookie, cookieStatistics);
    }

    const onAllowAll = () => {
        if (!cookie[userId])
            addCookie(setCookie, userId, uniqueId());

        if (!stringToBoolean(cookie[cookieOtherEnabled]))
            addCookie(setCookie, cookieOtherEnabled, true);

        if (!stringToBoolean(cookie[cookieConsent])) {
            addCookie(setCookie, cookieConsent, true);
            addCookie(setCookie, cookieConsentDate, new Date());
        }

        onCloseModal();
    }

    const onDenyAll = () => {
        if (!cookie[userId])
            addCookie(setCookie, userId, uniqueId());

        if (stringToBoolean(cookie[cookieOtherEnabled])) {
            addCookie(setCookie, cookieOtherEnabled, false);
            removeAllCookies();
        }

        if (stringToBoolean(cookie[cookieConsent])) {
            addCookie(setCookie, cookieConsent, false);
            addCookie(setCookie, cookieConsentDate, new Date());
        }

        onCloseModal();
    }

    const onSubmitCookie = () => {
        if (isEmptyObj(cookie))
            addCookie(setCookie, userId, uniqueId());

        if (!enableOtherCookie) {
            removeAllCookies();
            addCookie(setCookie, cookieOtherEnabled, false);
        } else {
            addCookie(setCookie, cookieOtherEnabled, true);
        }

        if (stringToBoolean(cookie[cookieConsent]) !== enableOtherCookie) {
            addCookie(setCookie, cookieConsent, enableOtherCookie);
            addCookie(setCookie, cookieConsentDate, new Date());
        }

        setShowModal(false);
        onCloseModal();
    }

    const onDescription = (descriptionName) => {
        const selectedDescription = description[descriptionName];

        setDescription({...description, [descriptionName]: {...selectedDescription, show: !selectedDescription.show}});
    }

    return <div>
        <div className="cookie-overlay"
             onClick={() => onCloseModal()}
        />
        <div className="cookie-modal-settings">
            <div className="cookie-modal-border row-end cookie-padding">
                <FontAwesomeIcon className="cursor" icon={faTimes} onClick={() => onCloseModal()}/>
            </div>
            <div className="cookie-modal-border cookie-modal-content cookie-padding">
                <h5 className="cookie-text-header">
                    Privacy Preference Center
                </h5>
                <p>
                    When you visit our Website, it may store or retrieve information on your browser, mostly
                    in the form of cookies. This information might be about you, your preferences or your
                    device and is used to make the site work as you expect it to and give you a more personalized
                    web experience. Because we respect your right to privacy, you can choose not to allow some
                    types of cookies. Click on the different category headings to find out more and change our
                    default settings. Blocking some types of cookies may impact your experience of the site and
                    the services we are able to offer.
                </p>
                <h5 className="cookie-text-header">
                    Manage Consent Preferences
                </h5>
                <div className="cursor cookie-border" onClick={() => onDescription('cookieNecessaryDescription')}>
                    <div className="cookie-modal-content-row">
                        <FontAwesomeIcon className="cursor"
                                         icon={description.cookieNecessaryDescription.show ? faMinus : faPlus}/>
                        <span className="cookie-name">{cookieNecessary}</span>
                        <span className="row-end">Always Active</span>
                    </div>
                    {description.cookieNecessaryDescription.show &&
                        <p>
                            {description.cookieNecessaryDescription.text}
                        </p>}
                </div>
                <div className="cursor cookie-border" onClick={() => onDescription('cookieOtherDescription')}>
                    <div className="cookie-modal-content-row">
                        <FontAwesomeIcon className="cursor"
                                         icon={description.cookieOtherDescription.show ? faMinus : faPlus}/>
                        <span className="cookie-name">{cookieOther}</span>
                        <span className="row-end">
                                <Switch
                                    checked={enableOtherCookie}
                                    onClick={(value, e) => {
                                    setEnableOtherCookie(value);
                                    e.stopPropagation()
                                }}/>
                        </span>
                    </div>
                    {description.cookieOtherDescription.show &&
                        <p>
                            {description.cookieOtherDescription.text}
                        </p>}
                </div>
            </div>
            <div className="cookie-padding row-end cookie-buttons">
                <Button className="cookie-modal-button black-text cursor color-text"
                        onClick={() => onDenyAll()}
                >
                    Reject all
                </Button>
                <Button className="cookie-modal-button black-text cursor color-text"
                        onClick={() => onAllowAll()}
                >
                    Allow all
                </Button>
                <Button className="cookie-modal-button black-text cursor color-text"
                        onClick={() => onSubmitCookie()}
                        type="primary"
                >
                    Confirm my choices
                </Button>
            </div>
        </div>
    </div>
};

export default CookieSettingsModal;