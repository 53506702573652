import React, {useContext} from 'react';
import teamImg from '../../../png/pbfs_team.png'
import CareersSVG from "../../icon/text_careers";
import {Link} from "react-router-dom";
import LanguageContext from "../../../context/language-context";
import CareersSvgFr from "../../icon/text_careers_FR";
import NewSmallLogo from "../../icon/new_small_logo";

const CareersBlock = () => {
    const {language, careerBlock} = useContext(LanguageContext);
    const {text1, text2, text3} = careerBlock;


    return <div className="careesBlock whiteText fullHeight">
        <div className="bg lazy"
             style={{
                 'display': 'block',
                 'backgroundImage': `url(${teamImg})`,
                 'visibility': 'visible',
                 'opacity': 1
             }}/>
        <div className="container">
            <div className="text aos aos-init" data-aos="fade-up" data-delay="300"
                 style={{'transitionDelay': '300ms'}}>
                <h2>{text1}</h2>
                <p className="careers-block__logo">
                    <NewSmallLogo width={200} height={120}/>
                    <br/>
                    <br/>
                    {text2}
                </p>
            </div>
            <div className="titleSVG aos aos-init" data-aos="fade-up" data-delay="350"
                 style={{'"transitionDelay': '350ms'}}>
                {language === 'EN'
                    ? <CareersSVG/>
                    : <CareersSvgFr/>}
                <Link to={language === 'EN' ? '/careers' : '/fr/careers'} className="line_hover type2 arrow bigPadding">
                    {text3}
                </Link>
            </div>
        </div>
    </div>
};

export default CareersBlock;