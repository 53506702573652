import React, {useCallback, useEffect} from "react";
import {useCookies} from "react-cookie";
import {addCookie, allCookies, cookieOtherEnabled, cookieStatistics} from "../component/cookie/cookie-util";
import {stringToBoolean} from "../util/util";

const withCookie = (WrappedComponent) => {
    return (props) => {
        const [cookie, setCookie] = useCookies([...allCookies]);

        const saveSelectedPages = useCallback(() => {
            if (stringToBoolean(cookie[cookieOtherEnabled])) {
                const newStatistics = cookie[cookieStatistics] || {};

                const page = window.location.pathname;

                newStatistics[page] = +(newStatistics[page] || '0') + 1;

                addCookie(setCookie, cookieStatistics, newStatistics);
            }
        }, [cookie, setCookie])

        useEffect(() => {
            saveSelectedPages();
        }, []);

        return <WrappedComponent
            saveSelectedPages={saveSelectedPages}
            {...props}
        />;
    };
};

export default withCookie;